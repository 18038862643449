import { getSecondLastId, getLastId } from "@/utils/findPage";
import { initializeDB } from "./manageIndexDb";

const loadPreviousPage = async (store, vuexStateId = "latest") => {
  if (store === undefined) {
    return;
  }
  const db = await initializeDB();
  if (vuexStateId === "last") {
    vuexStateId = await getLastId(db);
  } else if (vuexStateId === "latest") {
    if (store.state.drawer.lastPage !== null) {
      vuexStateId = store.state.drawer.lastPage;
    } else {
      vuexStateId = await getSecondLastId(db);
    }
  }
  return new Promise((resolve, reject) => {
    const resetLoading = () => store.dispatch("resetLoading");
    const transaction = db.transaction(["states"], "readonly");
    const objectStore = transaction.objectStore("states");

    const getRequest = objectStore.get(vuexStateId);

    getRequest.onsuccess = function () {
      if (getRequest.result) {
        store.commit("UPDATE_ENTIRE_STATE", {
          payload: getRequest.result.state,
          dataToKeep: ["personalInfo"],
        });
        resetLoading();
        resolve();
      }
    };

    getRequest.onerror = function () {
      reject(getRequest.error);
    };
  });
};

const timeOfLastPage = async (store) => {
  if (store === undefined) {
    return;
  }
  const db = await initializeDB();
  const vuexStateId = await getLastId(db);
  return new Promise((resolve, reject) => {
    const transaction = db.transaction(["states"], "readonly");
    const objectStore = transaction.objectStore("states");

    const getRequest = objectStore.get(vuexStateId);

    getRequest.onsuccess = function () {
      if (getRequest.result) {
        resolve(getRequest.result.time);
      } else {
        resolve(null);
      }
    };

    getRequest.onerror = function () {
      reject(getRequest.error);
    };
  });
};

export { loadPreviousPage, timeOfLastPage };
