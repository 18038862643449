<script setup>
import { ref, nextTick, onMounted } from "vue";

const props = defineProps(["modelValue", "question", "focus"]);
const emit = defineEmits(["update:modelValue", "add-option", "exit"]);

let inputRef = ref(null);

onMounted(() => {
  nextTick(() => {
    if (props.focus) {
      inputRef.value.focus();
    }
  });
});

const checkValid = (value) => {
  if (
    !props.question.suggestedAnswers.includes(value) &&
    value.length &&
    value !== " "
  ) {
    emit("add-option", {
      value,
      question: props.question,
    });
  }
};
</script>

<template>
  <div class="relative">
    <input
      ref="inputRef"
      :value="modelValue"
      placeholder="Add your option here..."
      class="w-full px-4 py-4 mb-4 lg:text-lg text-sm border-transparent rounded cursor-pointer bg-gray-10 placeholder:text-secondary focus:bg-yellow-200 focus:outline-none lg:placeholder:text-lg placeholder:text-sm hover:bg-gray-200 text-primary"
      @input="$emit('update:modelValue', $event.target.value)"
      @keyup.enter="checkValid(props.modelValue)"
      @blur="checkValid(props.modelValue)"
    />
    <div v-if="modelValue && modelValue.length" class="absolute top-4 right-4">
      <font-awesome-icon
        class="mr-2 text-xl text-green-500 cursor-pointer p-3 -m-3"
        :icon="['fas', 'circle-check']"
        @click="checkValid(props.modelValue)"
      />
      <font-awesome-icon
        class="text-xl text-accentPrimary cursor-pointer p-3 -m-3"
        :icon="['fas', 'circle-xmark']"
        @click="
          $emit('update:modelValue', '');
          $emit('exit');
        "
      />
    </div>
    <!-- Corner cutout -->
    <div
      class="absolute top-0 right-0 w-0 h-0 border-t-[20px] border-t-white border-l-transparent border-l-[20px]"
    ></div>
  </div>
</template>
