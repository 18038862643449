<script setup>
import { computed } from "vue";
import { useStore } from "vuex";

const store = useStore();

const languageList = computed(() => store.state.language.languageList);
const activeLanguage = computed(() => store.state.language.activeLanguage);

const selectLanguage = (language) =>
  store.dispatch("language/selectLanguage", language);
</script>

<template>
  <div v-for="language in languageList" :key="language">
    <div
      v-if="language.language"
      :class="
        activeLanguage == language.language
          ? 'bg-accentSecondary'
          : 'bg-gray-10 hover:bg-gray-100'
      "
      class="flex flex-col items-center justify-center mt-4 lg:mt-0 w-24 h-24 mr-4 rounded-lg cursor-pointer"
      @click="selectLanguage(language.language)"
    >
      <p class="drop-shadow-[0_0_4px_rgba(0,0,0,1)] text-3xl">
        {{ language.emoji ? language.emoji : "" }}
      </p>
      <label
        class="mt-2 text-sm text-center cursor-pointer"
        :class="
          activeLanguage == language.language
            ? 'text-primary'
            : 'text-secondary'
        "
      >
        {{ language.language }}
      </label>
    </div>
  </div>
</template>
