<script setup>
import { ref, onMounted } from "vue";
import { useStore } from "vuex";
import { nextStep } from "@/utils/shepherd/main";

const store = useStore();
const props = defineProps({
  isSelected: {
    type: Boolean,
    default: false,
  },
  itemId: {
    type: Number,
  },
  closeable: {
    type: Boolean,
    default: false,
  },
});

const isOpen = ref(false);
const replaceQuestion = (id) => store.dispatch("questions/replaceQuestion", id);
const id = ref(null);

const toggleAccordion = () => {
  isOpen.value = !isOpen.value;
  nextStep("question-accordion");
  if (isOpen.value) {
    id.value = "shepherd-target-question-item";
  } else {
    id.value = null;
  }
};

const openFirstQuestion = () => {
  if (props.itemId === 1) {
    isOpen.value = true;
    id.value = "shepherd-target-question-item";
  }
};

onMounted(() => {
  window.addEventListener("open-first-question", openFirstQuestion);
});
</script>

<template>
  <section :id="id">
    <button
      @click="toggleAccordion()"
      class="flex text-left items-center w-full px-3 py-3 space-x-3 text-base lg:text-lg font-bold lg:col-span-2 rounded-xl text-primary"
      :class="{
        'hover:bg-gray-200': !isOpen,
        'bg-accentSecondaryLight': isSelected && !isOpen,
      }"
      :aria-expanded="isOpen"
    >
      <font-awesome-icon
        v-if="isOpen && closeable"
        class="p-3 -m-3 2 h-6 inline-block align-bottom hover:text-red-500"
        :class="{
          'rotate-180': isOpen,
          'rotate-0': !isOpen,
        }"
        :icon="['fas', 'circle-xmark']"
        @click.stop="
          isOpen = false;
          replaceQuestion({ questionId: itemId });
        "
      />
      <div class="flex justify-between items-center w-full">
        <slot name="title" />
        <font-awesome-icon
          class="ml-2 w-3 transition-all duration-200 transform"
          :class="{
            'rotate-180': isOpen,
            'rotate-0': !isOpen,
          }"
          :icon="['fas', 'chevron-down']"
        />
      </div>
    </button>

    <div v-show="isOpen">
      <slot name="content" />
    </div>
  </section>
</template>
@/utils/main
