<script setup>
import { ref, computed, watch } from "vue";
import { useStore } from "vuex";
import InputTextarea from "../../inputs/InputTextarea.vue";

const store = useStore();

const addContext = (context) => store.dispatch("context/addContext", context);
const prompt = computed(() => store.state.prompt);
const isMobile = computed(() => store.state.isMobile);

const currentContext = computed(() => store.state.context.context);

const contextInput = ref(currentContext.value);
const error = ref("");

watch(contextInput, (newVal) => {
  if (newVal.length >= 10000) {
    error.value = "Context must be less than 10,000 characters";
    return;
  }
  addContext(newVal);
});

// If re-loaded and prompt takes a second to load, show the prompt in the inputBox
watch(currentContext, (newVal, oldVal) => {
  if (oldVal === "" && contextInput.value !== currentContext.value) {
    contextInput.value = currentContext.value;
  }
  if (oldVal === contextInput.value) {
    contextInput.value = currentContext.value;
  }
});
</script>

<template>
  <div>
    <p v-if="!isMobile" class="text-sm italic text-secondary mb-6">
      If you would like to provide more detailed context about your make then
      add it in below. <br />
      As much or as little as you like, also remember to explore the other tabs
      and press generate when you are done.
    </p>
    <InputTextarea
      class="text-xl"
      title="Context"
      :forceId="'shepherd-target-context'"
      :placeholder="`Describe '${prompt}' or add some past context...`"
      :error="error"
      v-model="contextInput"
      autofocus
    />
  </div>
</template>
