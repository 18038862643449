<script setup>
import { ref, computed } from "vue";
import { useStore } from "vuex";
import pushNextPage from "@/utils/pushNextPage";
import Dropdown from "../inputs/Dropdown.vue";
import { newTour } from "@/utils/shepherd/main";

const store = useStore();

const isOpen = computed(() => store.state.drawer.isOpen);
const loggedIn = computed(() => store.state.auth.loggedIn);

const setInfo = () => store.dispatch("drawer/setInfo");
const openAuth = () => store.dispatch("modals/openAuth");

const dropdown = ref(null);

const newPage = () => {
  pushNextPage(store.state);
};

const closeDropdown = () => {
  dropdown.value.close();
};
</script>

<template>
  <section
    class="absolute md:right-16 xs:right-6 right-3 z-25 flex"
    :class="isOpen ? 'xm:top-12 md:top-14 top-5' : 'md:top-12 top-5'"
  >
    <button
      class="py-1 px-2 text-base font-bold hover:text-white border-transparent rounded-lg flex"
      :class="
        isOpen
          ? 'text-secondary hover:bg-secondary lg:text-primary lg:hover:bg-primary'
          : 'text-secondary hover:bg-secondary'
      "
      @click="newTour(true)"
    >
      <p>Help</p>
      <font-awesome-icon icon="circle-question" class="h-4 mt-1 ml-2" />
    </button>
    <button
      id="shepherd-target-profile"
      class="py-1 px-2 text-base font-bold hover:text-white border-transparent rounded-lg flex"
      :class="
        isOpen
          ? 'text-secondary hover:bg-secondary lg:text-primary lg:hover:bg-primary'
          : 'text-secondary hover:bg-secondary'
      "
      @click="loggedIn ? '' : openAuth()"
    >
      <p v-if="!loggedIn">SignUp/Login</p>
      <Dropdown ref="dropdown" v-else placement="left">
        <!-- Button content -->
        <template v-slot:button>
          <p>Profile</p>
        </template>

        <!-- Opened dropdown content -->
        <template v-slot:content>
          <button
            class="flex w-full justify-between items-center rounded px-2 py-1 my-1 hover:bg-primary text-white"
            @click="
              closeDropdown();
              setInfo();
              newPage();
            "
          >
            Personal Info
          </button>
          <button
            class="flex w-full justify-between items-center rounded px-2 py-1 my-1 hover:bg-primary text-white"
            @click="
              closeDropdown();
              store.dispatch('auth/logout');
            "
          >
            Logout
          </button>
        </template>
      </Dropdown>
      <font-awesome-icon icon="user" class="h-4 mt-1 ml-2" />
    </button>
  </section>
</template>
@/utils/main
