import _ from "lodash";
import store from "../store/index.js";
import { getNextId } from "./findPage";
import { initializeDB, prepareStateForStorage } from "./manageIndexDb";

const pushNextPage = async (state) => {
  const db = await initializeDB();
  store.dispatch("drawer/setLastPage", db);
  return new Promise((resolve, reject) => {
    getNextId(db)
      .then((id) => {
        const transaction = db.transaction(["states"], "readwrite");
        const objectStore = transaction.objectStore("states");

        const preparedState = prepareStateForStorage(state);

        const addRequest = objectStore.add({
          id,
          time: new Date().getTime(),
          state: _.cloneDeep(preparedState),
        });

        addRequest.onsuccess = function () {
          history.pushState({ vuexState: id }, null);
          resolve();
        };

        addRequest.onerror = function () {
          reject(addRequest.error);
        };
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export default pushNextPage;
