import { createRouter, createWebHistory } from "vue-router";
import { loadPreviousPage, timeOfLastPage } from "@/utils/loadPastPages";
import pushNextPage from "@/utils/pushNextPage";
import Home from "../views/Home.vue";
import store from "../store";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  const auth = to.query.auth;
  const campaignLink = to.query.lk;
  if (auth) {
    if (auth === "failed") {
      router.replace(to.path);
      await loadPreviousPage(store, "last");
      store.dispatch("auth/failedFederatedLogin");
    } else if (auth === "success") {
      router.replace(to.path);
      await loadPreviousPage(store, "last");
      store.dispatch("auth/successfulFederatedLogin");
    }
  } else if (campaignLink) {
    router.replace(to.path);
    store.dispatch("auth/setCampaignLink", campaignLink);
  } else {
    store.dispatch("auth/setUserID");
    // if timeOfLastPage is less than 5 minutes ago, load the previous page
    const time = await timeOfLastPage(store);
    if (time && Date.now() - time < 300000) {
      await loadPreviousPage(store, "last");
    } else {
      pushNextPage(store.state);
    }
  }
  next();
});

export default router;
