export default {
  name: "language",
  namespaced: true,
  state: {
    languageList: [
      { language: "English", emoji: "🇬🇧" },
      { language: "Mandarin", emoji: "🇨🇳" },
      { language: "Hindi", emoji: "🇮🇳" },
      { language: "Spanish", emoji: "🇪🇸" },
      { language: "French", emoji: "🇫🇷" },
      { language: "German", emoji: "🇩🇪" },
    ],
    activeLanguage: "",
  },
  mutations: {
    SET_LANGUAGE_LIST(state, payload) {
      state.languageList = payload;
    },
    APPEND_LANGUAGE_LIST(state, payload) {
      state.languageList.push(payload);
    },
    SET_ACTIVE_LANGUAGE(state, payload) {
      state.activeLanguage = payload;
    },
  },
  actions: {
    reset({ commit }) {
      return new Promise((resolve) => {
        commit("SET_LANGUAGE_LIST", [
          { language: "English", emoji: "🇬🇧" },
          { language: "Mandarin", emoji: "🇨🇳" },
          { language: "Hindi", emoji: "🇮🇳" },
          { language: "Spanish", emoji: "🇪🇸" },
          { language: "French", emoji: "🇫🇷" },
          { language: "German", emoji: "🇩🇪" },
        ]);
        commit("SET_ACTIVE_LANGUAGE", "");
        resolve();
      });
    },
    addLanguage({ commit }, language) {
      commit("APPEND_LANGUAGE_LIST", language);
    },
    selectLanguage({ commit, state }, language) {
      if (state.activeLanguage === language) {
        commit("SET_ACTIVE_LANGUAGE", "");
        return;
      }
      commit("SET_ACTIVE_LANGUAGE", language);
    },
  },
  getters: {},
  modules: {},
};
