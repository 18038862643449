<script setup>
import { ref, computed } from "vue";
import { useStore } from "vuex";
import { onClickOutside } from "@vueuse/core";
import MainButton from "../inputs/MainButton.vue";
import pushNextPage from "@/utils/pushNextPage";
import InlineInput from "../inputs/InlineInput.vue";

const store = useStore();

const newPromptOpen = computed(() => store.state.modals.newPromptOpen);
const result = computed(() => store.state.result.generatedText);

const closeNewPrompt = () => store.dispatch("modals/closeNewPrompt");
const changePromptReset = (prompt) =>
  store.dispatch("changePromptReset", prompt);
// const getRefinements = () => store.dispatch("getRefinements");
const addContext = (context) => store.dispatch("context/addContext", context);

const target = ref(null);
const error = ref("");
const loading = ref(false);
const button = ref(null);
const promptInput = ref("");

onClickOutside(target, () => close());

// TODO: Migrate this to use changePrompt??
const submit = () => {
  if (promptInput.value.length === 0) {
    error.value = "Please enter something to make";
    return;
  }
  if (promptInput.value.length < 2) {
    error.value = "Please enter something 2 characters or longer";
    return;
  }
  if (promptInput.value.length > 500) {
    error.value = "Please enter something shorter";
    return;
  }
  button.value.setLoading(true);
  loading.value = true;
  const tempResult = result.value;
  changePromptReset(promptInput.value);
  close();
  button.value.setLoading.value = false;
  loading.value = false;
  pushNextPage(store.state);
  // getRefinements();
  addContext(tempResult);
};

const close = () => {
  error.value = "";
  closeNewPrompt();
};
</script>

<template>
  <section
    v-if="newPromptOpen"
    class="z-50 fixed top-0 left-0 right-0 bottom-0 flex items-center w-full h-full p-4 bg-black bg-opacity-50 overflow-y-auto"
  >
    <div
      ref="target"
      class="max-w-xl w-full mx-auto pt-6 px-6 pb-8 bg-white rounded-xl"
    >
      <font-awesome-icon
        icon="circle-xmark"
        class="text-gray-800 h-6 float-right hover:text-red-600 cursor-pointer p-3 -m-3"
        @click="close"
      />
      <div class="flex items-center mb-6">
        <div
          class="flex items-center justify-center h-14 w-14 bg-gray-100 px-4 rounded-xl mr-4"
        >
          <font-awesome-icon
            icon="hand-point-right"
            class="text-accentPrimary h-8"
          />
        </div>
        <div class="w-full">
          <h4 class="text-xl text-black font-semibold">Your new Make</h4>
          <span class="text-sm text-gray-800 font-semibold"
            >What is it you would like to make with the context of your last?
          </span>
        </div>
      </div>
      <InlineInput
        v-model="promptInput"
        title="Make Prompt"
        :error="error"
        :disabled="loading"
        inputType="text"
        placeholder="Enter your new make prompt..."
        @submit="submit"
        loadFirst
      />

      <div class="w-5/6 mx-auto mt-4">
        <MainButton ref="button" @clicked="submit" class="w-full" />
      </div>
    </div>
  </section>
</template>
