export default {
  name: "modals",
  namespaced: true,
  state: {
    newPromptOpen: false,
    authOpen: false,
    newInfoCategoryOpen: false,
    magicInfoCategoriesOpen: false,
  },
  mutations: {
    SET_NEW_PROMPT_OPEN(state, payload) {
      state.newPromptOpen = payload;
    },
    SET_AUTH_OPEN(state, payload) {
      state.authOpen = payload;
    },
    SET_NEW_INFO_CATEGORY_OPEN(state, payload) {
      state.newInfoCategoryOpen = payload;
    },
    SET_MAGIC_INFO_CATEGORIES_OPEN(state, payload) {
      state.magicInfoCategoriesOpen = payload;
    },
  },
  actions: {
    reset({ commit }) {
      commit("SET_NEW_PROMPT_OPEN", false);
      commit("SET_AUTH_OPEN", false);
      commit("SET_NEW_INFO_CATEGORY_OPEN", false);
      commit("SET_MAGIC_INFO_CATEGORIES_OPEN", false);
    },
    openNewPrompt({ commit }) {
      commit("SET_NEW_PROMPT_OPEN", true);
    },
    closeNewPrompt({ commit }) {
      commit("SET_NEW_PROMPT_OPEN", false);
    },
    openAuth({ commit }) {
      commit("SET_AUTH_OPEN", true);
    },
    closeAuth({ commit }, force = false) {
      // check if store.state.auth.loading is false
      // if it is false, then close the modal
      if (!this.state.auth.loading) {
        commit("SET_AUTH_OPEN", false);
      } else if (force) {
        commit("auth/AUTH_ERROR", "Signup cancelled", { root: true });
        commit("SET_AUTH_OPEN", false);
      }
    },
    openNewInfoCategory({ commit }) {
      commit("SET_NEW_INFO_CATEGORY_OPEN", true);
    },
    closeNewInfoCategory({ commit }) {
      commit("SET_NEW_INFO_CATEGORY_OPEN", false);
    },
    openMagicInfoCategories({ commit, dispatch, rootState }) {
      if (!rootState.hasLoaded) {
        dispatch("personalInfo/fetchInfo", null, { root: true });
      }
      commit("SET_MAGIC_INFO_CATEGORIES_OPEN", true);
    },
    closeMagicInfoCategories({ commit }) {
      commit("SET_MAGIC_INFO_CATEGORIES_OPEN", false);
    },
  },
  modules: {},
  getters: {},
};
