import fetchData from "@/utils/fetchData";
import updateCurrentPage from "@/utils/updateCurrentPage";
import { reject } from "lodash";
import { v4 as uuid } from "uuid";

export default {
  name: "auth",
  namespaced: true,
  state: {
    loggedIn: false,
    loading: false,
    loadingItem: "",
    error: "",
    userId: "",
    campaignLink: "",
  },
  mutations: {
    SET_LOGGED_IN(state, payload) {
      state.loggedIn = payload;
      localStorage.setItem("loggedIn", payload);
    },
    SET_USER_ID(state, payload) {
      state.userId = payload;
    },
    SET_ERROR(state, payload) {
      state.error = payload;
    },
    AUTH_REQUEST(state, payload) {
      state.loading = true;
      state.loadingItem = payload;
      state.error = "";
    },
    AUTH_SUCCESS(state, { userId }) {
      state.loading = false;
      state.loadingItem = "";
      state.userId = userId;
      localStorage.setItem("userId", userId);
    },
    AUTH_ERROR(state, payload) {
      state.loading = false;
      state.loadingItem = "";
      state.error = payload;
    },
    SET_CAMPAIGN_LINK(state, payload) {
      state.campaignLink = payload;
    },
  },
  actions: {
    clearError({ commit }) {
      commit("SET_ERROR", "");
    },
    setCampaignLink({ commit }, payload) {
      commit("SET_CAMPAIGN_LINK", payload);
      fetchData("user/triggerCampaign", { campaign: payload }, false, false);
    },
    authSuccess({ commit, dispatch }, { userId }) {
      commit("AUTH_SUCCESS", { userId });
      commit("SET_LOGGED_IN", true);
      commit("modals/SET_AUTH_OPEN", false, { root: true });
      dispatch("personalInfo/fetchInfo", null, { root: true });
      dispatch("tours/fetchTours", null, { root: true });
      dispatch("sendUserDetails");
    },
    async sendUserDetails({ dispatch, state }) {
      let promises = [];
      let info = {};

      let countryPromise = dispatch("getCountry").then((country) => {
        return { ...info, country: country };
      });
      promises.push(countryPromise);

      let browserPromise = dispatch("getBrowserDetails").then((browser) => {
        return { ...info, browser: browser };
      });
      promises.push(browserPromise);

      return Promise.all(promises).then((results) => {
        results.forEach((result) => {
          info = { ...info, ...result };
        });
        if (state.campaignLink) {
          info = { ...info, campaignLink: state.campaignLink };
        }
        fetchData("user/setData", { info }, false, false, false);
      });
    },
    setUserID({ commit, state, dispatch }) {
      let storedId;
      try {
        storedId = JSON.parse(localStorage.getItem("userId"));
      } catch (e) {
        storedId = localStorage.getItem("userId");
      }
      if (storedId) {
        commit("SET_USER_ID", storedId);
        if (localStorage.getItem("loggedIn") === "true") {
          dispatch("personalInfo/fetchInfo", null, { root: true });
          commit("SET_LOGGED_IN", true);
        }
      } else if (!state.userId) {
        commit("SET_USER_ID", JSON.stringify(uuid()));
        localStorage.setItem("userId", state.userId);
      }
    },
    async startFederatedLogin({ commit, dispatch, state }, provider) {
      return new Promise((resolve, reject) => {
        commit("AUTH_REQUEST", provider);
        dispatch("setUserID")
          .then(() => {
            if (provider === "Google") {
              updateCurrentPage(state, dispatch).then(() => {
                resolve(
                  `${process.env.VUE_APP_APIURL}/api/v1/user/login/federated/google`
                );
              });
              // } else if (provider === "Facebook") {
              //   updateCurrentPage(state, dispatch).then(() => {
              //     resolve(
              //       `${process.env.VUE_APP_APIURL}/api/v1/user/login/federated/facebook`
              //     );
              //   });
            }
          })
          .catch(reject);
      });
    },
    async failedFederatedLogin({ commit }) {
      commit("AUTH_ERROR", "An error occurred, please try again");
    },
    async successfulFederatedLogin({ commit, dispatch }) {
      return new Promise((resolve) => {
        fetchData("user/setId", {}, false, false)
          .then(({ response }) => {
            if (response.status === 201) {
              const { userId } = response.result;
              dispatch("authSuccess", { userId });
              resolve(response);
            } else {
              commit("AUTH_ERROR", "An error occurred, please try again");
              resolve(response);
            }
          })
          .catch((err) => {
            commit("AUTH_ERROR", "An error occurred, please try again");
            resolve(err);
          });
      });
    },
    login({ commit, dispatch }, user) {
      return new Promise((resolve) => {
        commit("AUTH_REQUEST", "email");
        fetchData("user/login", user, false, false, false)
          .then(({ response }) => {
            if (response.status === 201) {
              const { userId } = response.result.user;
              dispatch("authSuccess", { userId });
              resolve(response);
            } else {
              commit("AUTH_ERROR", "Incorrect Email or Password");
              resolve(response);
            }
          })
          .catch((error) => {
            commit("AUTH_ERROR", "An error occurred, please try again");
            resolve(error);
          });
      });
    },
    async signup({ commit, dispatch }, user) {
      return new Promise((resolve) => {
        commit("AUTH_REQUEST", "email");
        fetchData("user/signup", user, false, false)
          .then(({ response }) => {
            if (response.status === 201) {
              const { userId } = response.result;
              dispatch("authSuccess", { userId });
              resolve(response);
            } else if (response.status === 409) {
              dispatch("login", user);
            } else {
              commit("AUTH_ERROR", "The information you provided is invalid");
              resolve(response);
            }
          })
          .catch((err) => {
            commit("AUTH_ERROR", "An error occurred, please try again");
            resolve(err);
          });
      });
    },
    logout({ commit, dispatch }) {
      return new Promise((resolve) => {
        fetchData("user/logout", {}, false, false, false)
          .then(() => {
            localStorage.removeItem("userId");
            commit("SET_LOGGED_IN", false);
            commit("SET_USER_ID", "");
            dispatch("setUserID");
            resolve();
          })
          .catch((error) => {
            commit("AUTH_ERROR", "An error occurred, please try again");
            reject(error);
          });
      });
    },
    getBrowserDetails() {
      const browser = {
        userAgent: navigator.userAgent,
        vendor: navigator.vendor,
        platform: navigator.platform,
        appName: navigator.appName,
        appVersion: navigator.appVersion,
        appCodeName: navigator.appCodeName,
        product: navigator.product,
        productSub: navigator.productSub,
        cookieEnabled: navigator.cookieEnabled,
        onLine: navigator.onLine,
        language: navigator.language,
        languages: navigator.languages,
        hardwareConcurrency: navigator.hardwareConcurrency,
        deviceMemory: navigator.deviceMemory,
        maxTouchPoints: navigator.maxTouchPoints,
        doNotTrack: navigator.doNotTrack,
        connection: navigator.connection,
        plugins: Array.from(navigator.plugins).map((plugin) => ({
          name: plugin.name,
          description: plugin.description,
          filename: plugin.filename,
          version: plugin.version,
        })),
      };
      return browser;
    },
    async getCountry() {
      const response = await fetch("https://ipapi.co/json/");
      const data = await response.json();
      return data;
    },
  },
  modules: {},
  getters: {
    authStatus: (state) => state.status,
  },
};
