<script setup>
import { ref, computed, watch } from "vue";
import { useStore } from "vuex";
import InlineInput from "../../inputs/InlineInput.vue";
import MainButton from "../../inputs/MainButton.vue";
import InputTextarea from "../../inputs/InputTextarea.vue";

const store = useStore();

const storedName = computed(() => store.state.personalInfo.name);
const storedGender = computed(() => store.state.personalInfo.gender);
const storedEmail = computed(() => store.state.personalInfo.email);
const storedJob = computed(() => store.state.personalInfo.job);
const storedCountry = computed(() => store.state.personalInfo.country);
const extraCategories = computed(() => store.state.personalInfo.categories);
const removedCategories = computed(
  () => store.state.personalInfo.removedCategories
);
const storedExtraInfo = computed(() => store.state.personalInfo.extraInfo);
const loading = computed(() => store.state.personalInfo.loading);
const isMobile = computed(() => store.state.isMobile);

const openNewInfoCategory = () => store.dispatch("modals/openNewInfoCategory");
const removeCategory = (index) =>
  store.dispatch("personalInfo/removeCategory", index);
const undoRemoveCategory = () =>
  store.dispatch("personalInfo/undoRemoveCategory");
const saveInfo = (info) => store.dispatch("personalInfo/saveInfo", info);

const categoryButton = ref(null);
const gender = ref(storedGender.value);
const name = ref(storedName.value);
const email = ref(storedEmail.value);
const job = ref(storedJob.value);
const country = ref(storedCountry.value);
const extraInfo = ref(storedExtraInfo.value);

const emit = defineEmits(["doneSave"]);

const save = async () => {
  await saveInfo({
    name: name.value,
    gender: gender.value,
    email: email.value,
    job: job.value,
    country: country.value,
    extraInfo: extraInfo.value,
    categories: extraCategories.value,
  });
  name.value = storedName.value;
  gender.value = storedGender.value;
  email.value = storedEmail.value;
  job.value = storedJob.value;
  country.value = storedCountry.value;
  extraInfo.value = storedExtraInfo.value;
  emit("doneSave");
};

// refresh the data in extraCategories
watch(extraCategories, (value) => {
  extraCategories.value = value;
});

watch(loading, (value) => {
  if (categoryButton.value) {
    categoryButton.value.setDisable(value);
  }
});

defineExpose({ save });
</script>

<template>
  <section :class="isMobile ? '' : 'grid-cols-3 lg:grid gap-11'" class="mt-8">
    <div
      :class="
        isMobile
          ? 'relative h-[calc(80vh-120px)] space-y-5'
          : 'col-span-2 grid grid-cols-2 gap-5'
      "
    >
      <div>
        <p class="mb-3">Enter your full name</p>
        <InlineInput
          v-model="name"
          :value="name"
          title="Full Name"
          inputType="text"
          placeholder="Jane Smith"
          :disabled="loading"
        />
      </div>
      <div>
        <p class="mb-3">Enter your gender</p>
        <InlineInput
          v-model="gender"
          :value="gender"
          title="Gender"
          inputType="text"
          placeholder="Male/Female etc."
          :disabled="loading"
        />
      </div>
      <div>
        <p class="mb-3">Enter the email your would prefer to use for makes</p>
        <InlineInput
          v-model="email"
          :value="email"
          title="Email"
          inputType="text"
          placeholder="you@email.com"
          :disabled="loading"
        />
      </div>
      <div>
        <p class="mb-3">Enter your job title</p>
        <InlineInput
          v-model="job"
          :value="job"
          title="Job Title"
          inputType="text"
          placeholder="Event Planner"
          :disabled="loading"
        />
      </div>
      <div>
        <p class="mb-3">Enter the country where you live</p>
        <InlineInput
          v-model="country"
          :value="country"
          title="Country of Residence"
          inputType="text"
          placeholder="United Kingdom"
          :disabled="loading"
        />
      </div>
      <div v-for="(category, index) in extraCategories" :key="category">
        <p class="mb-3">Enter your {{ category.name }}</p>
        <div class="flex">
          <InlineInput
            class="w-5/6"
            v-model="category.answer"
            :value="category.answer"
            :title="category.name"
            inputType="text"
            :placeholder="`Answer to your custom category ${category.name}`"
            :disabled="loading"
          />
          <font-awesome-icon
            icon="circle-xmark"
            title="Remove Category"
            class="text-gray-800 h-6 float-right hover:text-red-600 cursor-pointer w-1/6 my-auto p-3 -m-3"
            @click="removeCategory(index)"
          />
        </div>
      </div>
      <div v-if="removedCategories.length > 0" class="w-full h-full flex">
        <button
          class="text-primary hover:bg-primary py-1 px-2 font-bold text-xl hover:text-white border-transparent rounded-lg flex mx-auto my-auto"
          @click="undoRemoveCategory"
        >
          <p>Undo</p>
          <font-awesome-icon icon="rotate-left" class="h-5 mt-1 ml-2" />
        </button>
      </div>
      <div>
        <p class="mb-3">
          Create a new category for information you would like to provide
        </p>
        <MainButton
          ref="categoryButton"
          @clicked="openNewInfoCategory()"
          class="w-full"
          :setColours="'bg-primary hover:bg-secondary'"
        >
          <label class="cursor-pointer">New Category</label>
        </MainButton>
      </div>
      <div class="col-span-2">
        <p class="mb-3">
          Add any extra information you feel would be useful here
        </p>
        <InputTextarea
          class="h-1/4"
          title="Extra Information"
          placeholder="Add any information you feel does not fit into a category but would be useful for future makes"
          v-model="extraInfo"
          :value="extraInfo"
          :disabled="loading"
        />
      </div>
    </div>

    <div v-if="!isMobile" class="flex justify-center">
      <img class="h-[266px] animate-fade_in_genie" src="/genie-crossed.svg" />
    </div>
  </section>
</template>
