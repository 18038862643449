import { getLastId } from "./findPage";
import { initializeDB, prepareStateForStorage } from "./manageIndexDb";

const updateCurrentPage = async (state) => {
  const db = await initializeDB();
  return new Promise((resolve, reject) => {
    getLastId(db)
      .then((id) => {
        const transaction = db.transaction(["states"], "readwrite");
        const objectStore = transaction.objectStore("states");

        const preparedState = prepareStateForStorage(state);

        const putRequest = objectStore.put({
          id,
          time: new Date().getTime(),
          state: preparedState,
        });

        putRequest.onsuccess = function () {
          resolve();
        };

        putRequest.onerror = function () {
          reject(putRequest.error);
        };
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export default updateCurrentPage;
