import { useShepherd } from "vue-shepherd";
import store from "../../store/index.js";

var checkForEditInterval = null;
const tour = useShepherd({
  exitOnEsc: false,
  keyboardNavigation: false,
  useModalOverlay: true,
  modalContainer: document.body,
  tourName: "resultTour",
  defaultStepOptions: {
    classes: "outline-none",
    scrollTo: { behavior: "smooth", block: "center" },
  },
});

const startResultTour = () => {
  if (!store.state.tours.showResultTour) return;
  if (!store.state.drawer.isOpen) return;
  if (!store.state.result.showResult) return;
  return resultTour.bind(this)().start();
};

const resultTour = () => {
  const isMobile = store.state.isMobile;
  let action = isMobile ? "tap" : "click";
  tour.addSteps([
    {
      attachTo: {
        element: !isMobile && "#shepherd-target-make",
        on: "left",
      },
      title: `<div class="w-full flex">
                  <p class="my-auto">You made it 🎉</p>
                  <img
                    ref="genie"
                    class="w-[60px] ml-auto -my-3 -mr-2"
                    src="/genie-crossed-torso.svg"
                    alt="genie"
                  />
              </div>`,
      text: "Congratulations on your first make! This panel wil show you the make as well as some useful tools to edit and share",
      arrow: true,
      buttons: [
        {
          text: "Next",
          action: function () {
            this.next();
          },
          classes: "w-full",
        },
      ],
    },
    {
      attachTo: {
        element: `${
          isMobile
            ? "#shepherd-target-make-edit-mobile"
            : "#shepherd-target-make-edit"
        }`,
        on: "bottom",
      },
      title: `<div class="w-full flex">
                  <p class="my-auto">You made it 🎉</p>
                  <img
                    ref="genie"
                    class="w-[60px] ml-auto -my-3 -mr-2"
                    src="/genie-crossed-torso.svg"
                    alt="genie"
                  />
              </div>`,
      text: `If there are parts of the make you want to prompt me to change ${action} here then select all the sentences you want to change. <br /> A popup will appear that allows you to explain how you want me to change it`,
      arrow: true,
      beforeShowPromise: function () {
        return new Promise((resolve) => {
          checkForEditInterval = setInterval(() => {
            if (store.state.result.editMode) {
              clearInterval(checkForEditInterval);
              tour.hide();
              const checkForStopEditInterval = setInterval(() => {
                if (!store.state.result.editMode) {
                  clearInterval(checkForStopEditInterval);
                  tour.next();
                }
              }, 200);
            }
          }, 200);
          resolve();
        });
      },
      buttons: [
        {
          text: "Next",
          action: function () {
            this.next();
          },
          classes: "w-full",
        },
      ],
    },
    {
      attachTo: {
        element: `${
          isMobile
            ? "#shepherd-target-make-redo-mobile"
            : "#shepherd-target-make-redo"
        }`,
        on: "bottom",
      },
      title: `<div class="w-full flex">
                  <p class="my-auto">You made it 🎉</p>
                  <img
                    ref="genie"
                    class="w-[60px] ml-auto -my-3 -mr-2"
                    src="/genie-crossed-torso.svg"
                    alt="genie"
                  />
              </div>`,
      text: `If you want to re-create the whole make or you have changed some answers, just ${action} here`,
      canClickTarget: false,
      arrow: true,
      beforeShowPromise: function () {
        return new Promise((resolve) => {
          clearInterval(checkForEditInterval);
          resolve();
        });
      },
      buttons: [
        {
          text: "Next",
          action: function () {
            this.next();
          },
          classes: "w-full",
        },
      ],
    },
    {
      attachTo: {
        element: "#shepherd-target-make-reuse",
        on: "bottom",
      },
      title: `<div class="w-full flex">
                  <p class="my-auto">You made it 🎉</p>
                  <img
                    ref="genie"
                    class="w-[60px] ml-auto -my-3 -mr-2"
                    src="/genie-crossed-torso.svg"
                    alt="genie"
                  />
              </div>`,
      text: `If you want to use your make as the basis for a new one, just ${action} here`,
      canClickTarget: false,
      arrow: true,
      buttons: [
        {
          text: "Next",
          action: function () {
            this.next();
          },
          classes: "w-full",
        },
      ],
    },
    {
      title: `<div class="w-full flex">
                  <p class="my-auto">You made it 🎉</p>
                  <img
                    ref="genie"
                    class="w-[60px] ml-auto -my-3 -mr-2"
                    src="/genie-crossed-torso.svg"
                    alt="genie"
                  />
              </div>`,
      text: `You can use the <em>Share</em> or <em>Copy</em> buttons to share your make with others or save it for later. <br /> Remember to vote on if you found the make helpful or not, using the button at the bottom`,
      buttons: [
        {
          text: "Next",
          action: function () {
            this.next();
          },
          classes: "w-full",
        },
      ],
    },
    {
      attachTo: {
        element: "#shepherd-target-profile",
        on: "bottom",
      },
      id: "final",
      title: `<div class="w-full flex">
                  <p class="my-auto">You made it 🎉</p>
                  <img
                    ref="genie"
                    class="w-[60px] ml-auto -my-3 -mr-2"
                    src="/genie-crossed-torso.svg"
                    alt="genie"
                  />
              </div>`,
      text: `Now you have created your first make the last place to explore is your profile. <br /> Here you can see all the makes you have created and help me learn more about you to improve your future makes`,
      buttons: [
        {
          text: "Finish",
          action: function () {
            store.dispatch("tours/completeResultTour");
            this.complete();
          },
          classes: "w-full",
        },
      ],
    },
  ]);
  return tour;
};

export { startResultTour, tour };
