<script setup>
import { ref, computed, watch } from "vue";
import { useStore } from "vuex";
import MainButton from "../../inputs/MainButton.vue";
import InputBox from "../../inputs/InputBox.vue";

const store = useStore();
const emit = defineEmits(["submit", "selectAll", "deleteSelected"]);

const loading = computed(() => store.state.result.loadingFix);
const commentToChange = computed(() => store.state.result.commentToChange);

const button = ref(null);
const comment = ref(commentToChange.value);
const confirmDelete = ref(false);
const error = ref("");

const submit = () => {
  if (comment.value.length < 5) {
    error.value = "Please enter longer text";
    return;
  }
  button.value.setLoading(true);
  emit("submit", comment.value);
};

watch(loading, (newLoading) => {
  button.value.setLoading(newLoading);
});

watch(comment, (newComment) => {
  store.commit("result/SET_COMMENT_TO_CHANGE", newComment);
});
</script>

<template>
  <section class="w-full rounded-xl p-2 my-1 bg-primary">
    <div v-if="!confirmDelete">
      <div class="flex">
        <p class="lg:text-lg text-base font-bold text-white mr-auto">
          What would you like to change?
        </p>
        <div
          class="w-20 grid grid-cols-1 mr-2 my-auto text-white cursor-pointer"
          title="Select all"
          @click="$emit('selectAll')"
        >
          <font-awesome-icon
            class="h-5 mx-auto"
            :icon="['fas', 'object-group']"
          />
          <p class="text-xs text-center font-bold mt-0.5 whitespace-nowrap">
            Select All
          </p>
        </div>

        <div
          class="w-20 grid grid-cols-1 my-auto mr-2 text-white cursor-pointer"
          title="Delete selected"
          @click="confirmDelete = true"
        >
          <font-awesome-icon class="h-5 mx-auto" :icon="['fas', 'trash-can']" />
          <p class="text-xs text-center font-bold mt-0.5 whitespace-nowrap">
            Delete
          </p>
        </div>
      </div>
      <div class="w-full flex xs:px-3 mt-1">
        <div class="flex-grow">
          <InputBox
            placeholder="Reword this sentence"
            :error="error"
            v-model="comment"
            defaultClasses="text-primary bg-gray-10 text-base px-3 py-2"
            errorClasses="ml-2 -mb-1"
            @submit="submit"
            :disabled="loading"
          />
        </div>
        <MainButton
          ref="button"
          title="Submit"
          class="ml-2"
          setPadding="px-4"
          @clicked="submit"
        >
          <font-awesome-icon
            class="cursor-pointer"
            :icon="['fas', 'chevron-right']"
          />
        </MainButton>
      </div>
    </div>
    <div v-else>
      <p class="text-lg font-bold text-white">Delete all highlighted text?</p>
      <div class="w-full flex xs:px-3 mt-1">
        <MainButton
          ref="button"
          class="ml-2"
          setPadding="w-1/2 py-2"
          :setColours="'bg-secondary hover:bg-gray-500'"
          @clicked="confirmDelete = false"
        >
          <label class="cursor-pointer">No</label>
          <font-awesome-icon
            class="cursor-pointer"
            :icon="['fas', 'chevron-right']"
          />
        </MainButton>
        <MainButton
          ref="button"
          class="ml-2"
          setPadding="w-1/2 py-2"
          @clicked="$emit('deleteSelected')"
        >
          <label class="cursor-pointer">Yes</label>
          <font-awesome-icon
            class="cursor-pointer"
            :icon="['fas', 'chevron-right']"
          />
        </MainButton>
      </div>
    </div>
  </section>
</template>
