<template>
  <svg
    width="1007"
    height="1007"
    viewBox="0 0 1007 1007"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      opacity="0.35"
      cx="503.5"
      cy="503.5"
      r="368"
      transform="rotate(120 503.5 503.5)"
      stroke="#756E94"
    />
    <rect
      width="26.0682"
      height="26.2205"
      rx="6.62579"
      transform="matrix(-0.0680903 0.997679 -0.997639 -0.0686825 537.416 124.132)"
      fill="#231F42"
    />
    <path
      opacity="0.3"
      d="M527.509 140.871L528.101 132.202L532.46 132.502C534.868 132.668 536.688 134.743 536.524 137.137C536.361 139.531 534.277 141.337 531.869 141.171L527.509 140.871Z"
      fill="#FEC901"
    />
    <path
      opacity="0.3"
      d="M510.367 135.334C510.531 132.941 512.615 131.134 515.023 131.3L519.383 131.6L518.791 140.269L514.431 139.969C512.023 139.804 510.204 137.729 510.367 135.334Z"
      fill="#FEC901"
    />
    <path
      opacity="0.3"
      d="M528.108 132.196L519.388 131.596L519.684 127.261C519.847 124.867 521.932 123.061 524.339 123.227C526.747 123.392 528.567 125.468 528.403 127.862L528.108 132.196Z"
      fill="#FEC901"
    />
    <path
      opacity="0.3"
      d="M522.558 149.242C520.151 149.077 518.331 147.001 518.494 144.607L518.79 140.273L527.51 140.873L527.214 145.208C527.051 147.602 524.966 149.408 522.558 149.242Z"
      fill="#FEC901"
    />
  </svg>
</template>

<style scoped>
svg {
  animation: rotate-animation 80s infinite linear;
}
@keyframes rotate-animation {
  0% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
</style>
