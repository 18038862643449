import { watch } from "vue";
import fetchStream from "@/utils/fetchStream";
import fetchData from "@/utils/fetchData";
import pushNextPage from "@/utils/pushNextPage";
import { newTour } from "@/utils/shepherd/main";

export default {
  name: "result",
  namespaced: true,
  state: {
    stopLoading: false,
    loading: false,
    loadingFix: false,
    commentToChange: "",
    fixedTextStream: "",
    fixedText: "",
    completeFixedText: "",
    generatedText: "",
    finishedText: [],
    editMode: false,
    showResult: false,
    vote: 0,
    error: "",
  },
  mutations: {
    SET_STOP_LOADING(state, payload) {
      state.stopLoading = payload;
    },
    SET_LOADING(state, payload) {
      state.loading = payload;
    },
    SET_LOADING_FIX(state, payload) {
      state.loadingFix = payload;
    },
    SET_COMMENT_TO_CHANGE(state, payload) {
      state.commentToChange = payload;
    },
    SET_FIXED_TEXT_STREAM(state, payload) {
      state.fixedTextStream = payload;
    },
    APPEND_FIXED_TEXT_STREAM(state, payload) {
      state.fixedTextStream += payload;
    },
    SET_FIXED_TEXT(state, payload) {
      state.fixedText = payload;
    },
    APPEND_FIXED_TEXT(state, payload) {
      state.fixedText += payload;
    },
    SET_COMPLETE_FIXED_TEXT(state, payload) {
      state.completeFixedText = payload;
    },
    SET_GENERATED_TEXT(state, payload) {
      state.generatedText = payload;
    },
    SET_FINISHED_TEXT(state, payload) {
      state.finishedText = payload;
    },
    APPEND_GENERATED_TEXT(state, payload) {
      state.generatedText += payload;
    },
    SET_EDIT_MODE(state, payload) {
      state.editMode = payload;
    },
    SET_SHOW_RESULT(state, payload) {
      state.showResult = payload;
    },
    SET_VOTE(state, payload) {
      state.vote = payload;
    },
    SET_ERROR(state, payload) {
      state.error = payload;
    },
  },
  actions: {
    reset({ commit }) {
      commit("SET_GENERATED_TEXT", "");
      commit("SET_VOTE", 0);
      commit("SET_SHOW_RESULT", false);
      commit("SET_FINISHED_TEXT", "");
      commit("SET_LOADING", false);
      commit("SET_LOADING_FIX", false);
      commit("SET_COMMENT_TO_CHANGE", "");
      commit("SET_FIXED_TEXT", "");
      commit("SET_FIXED_TEXT_STREAM", "");
      commit("SET_COMPLETE_FIXED_TEXT", "");
      commit("SET_STOP_LOADING", true);
      commit("SET_EDIT_MODE", false);
      commit("SET_ERROR", "");
    },
    resetLoading({ commit, state, dispatch }) {
      if (state.loading) {
        commit("SET_LOADING", false);
        dispatch("reset");
        dispatch("getResult");
      }
    },
    hideResult({ commit, rootState }) {
      commit("SET_EDIT_MODE", false);
      commit("SET_SHOW_RESULT", false);
      pushNextPage(rootState);
    },
    waitForFinishLoading({ state }) {
      return new Promise((resolve) => {
        if (!state.loading) {
          return resolve();
        }
        const intervalId = setInterval(() => {
          if (!state.loading) {
            clearInterval(intervalId);
            resolve();
          }
        }, 100);
      });
    },
    convertToFinished({ commit }, textToConvert) {
      textToConvert = textToConvert.replace(" [[", "[[");
      textToConvert = textToConvert.replace("]] ", "]]");
      const newLines = textToConvert.split(/(\r\n|\n|\r)/g);
      let linesWithBrackets = [];
      newLines.forEach((line) => {
        let temp = line.split(/(\[\[|\]\])/g);
        linesWithBrackets.push(...temp);
      });

      let lines = [];
      // split text into sentences, breaking on periods, question marks, exclamation marks and new lines
      linesWithBrackets.forEach((line) => {
        let temp2 = line.split(/(?<=[.?!])\s+(?=[A-Z])/g);
        lines.push(...temp2);
      });
      lines = lines.map((line) => line || " ");
      // remove items in array that are " " or ""
      lines = lines.filter((line) => line !== " " && line !== "");
      let sentences = [];
      // add a space to the end of each sentence, unless it's the last sentence or it is a \n
      let changed = false;
      lines.forEach((line, index) => {
        if (line.includes("[[")) {
          changed = true;
          line = line.replace("[[", "");
          return;
        }
        if (line.includes("]]")) {
          changed = false;
          line = line.replace("]]", "");
          return;
        }
        if (index === line.length - 1) {
          sentences.push({
            text: line,
            changed,
            highlightable: !(line === " " || line === "\n"),
          });
          return;
        }
        // if the nxt line is a \n, push the line without a space
        if (lines[index + 1] === "\n") {
          sentences.push({
            text: line,
            changed,
            highlightable: !(line === " " || line === "\n"),
          });
          return;
        }
        // push a item that is " " after the sentence item
        sentences.push({
          text: line,
          changed,
          highlightable: !(line === " " || line === "\n"),
        });
        sentences.push({ text: " ", changed, highlightable: false });
      });
      commit("SET_FINISHED_TEXT", sentences);
    },
    async getResult(
      { dispatch, commit, state, getters, rootState },
      { regenerate = false, retries = 0 } = {}
    ) {
      if (!rootState.auth.loggedIn) {
        dispatch("modals/openAuth", null, { root: true });
        return;
      }
      commit("SET_STOP_LOADING", true);
      await dispatch("waitForFinishLoading");
      await dispatch("reset");
      commit("SET_LOADING", true);
      commit("SET_STOP_LOADING", false);
      commit("SET_SHOW_RESULT", true);
      pushNextPage(rootState);
      let endsWithDollar = false;
      try {
        if (!regenerate) {
          dispatch("personalInfo/incrementTotalMakes", null, { root: true });
        }
        const info = getters.getInfo;
        const { reader, status } = await fetchStream(
          "generate/final",
          {
            info,
            regenerate,
          },
          true,
          true,
          false
        );
        while (status === 200) {
          const unwatch = watch(
            () => state.stopLoading,
            async (newValue) => {
              if (newValue) {
                await reader.cancel(); // Cancel the reader
                dispatch("reset");
                unwatch(); // Stop watching when stopLoading becomes true
                return;
              }
            },
            { immediate: true }
          );
          const { value, done } = await reader.read();
          if (done) {
            if (state.generatedText === "") {
              throw new TypeError("Generated text is empty");
            }
            dispatch("convertToFinished", state.generatedText);
            commit("SET_LOADING", false);
            break;
          }
          const arr = value.split("\n");
          arr.forEach((data) => {
            if (data.length === 0) return; // ignore empty message
            const json = JSON.parse(data.substring(6));
            if (json.text === undefined) return;
            if (endsWithDollar) {
              json.text = "$" + json.text;
              endsWithDollar = false;
            }
            if (json.text.endsWith("$")) {
              json.text = json.text.substring(0, json.text.length - 1);
              endsWithDollar = true;
            }
            commit("APPEND_GENERATED_TEXT", json.text);
          });
        }
        if (status === 401) {
          commit("SET_ERROR", "Please log in to generate your make");
          commit("SET_LOADING", false);
          dispatch("modals/openAuth", null, { root: true });
        } else if (status !== 200) {
          commit("SET_ERROR", "An error has occurred. Please try again.");
          commit("SET_LOADING", false);
          throw new TypeError("Internal Server Error");
        }
      } catch (error) {
        if (error instanceof TypeError && retries < 3) {
          console.log("Retrying Rs x", retries);
          setTimeout(() => {
            commit("SET_LOADING", false);
            dispatch("getResult", {
              retries: retries + 1,
            });
          }, 500 * retries + 500);
        } else {
          console.error(error);
          commit("SET_LOADING", false);
        }
      } finally {
        if (state.generatedText.endsWith("$")) {
          commit(
            "SET_GENERATED_TEXT",
            state.generatedText.substring(0, state.generatedText.length - 1)
          );
        }
        if (state.generatedText.endsWith("$")) {
          commit(
            "SET_GENERATED_TEXT",
            state.generatedText.substring(0, state.generatedText.length - 1)
          );
        }
        newTour();
      }
    },
    addChangedText({ state, commit, dispatch }, text) {
      if (text === undefined) return;
      commit("APPEND_FIXED_TEXT_STREAM", text);
      if (state.fixedTextStream === text) {
        const first = text.indexOf("[");
        if (first === -1) {
          commit("SET_FIXED_TEXT_STREAM", "");
        } else {
          commit("SET_FIXED_TEXT_STREAM", text.substring(first));
        }
      } else if (state.fixedText !== "" || text.indexOf("{") !== -1) {
        if (text.indexOf("}") === -1) {
          commit("APPEND_FIXED_TEXT", text);
        } else {
          commit("APPEND_FIXED_TEXT", "}");
          let json = {};
          try {
            json = JSON.parse(state.fixedText);
          } catch (error) {
            try {
              let fixedTextSquareBr = state.fixedText.substring(
                0,
                state.fixedText.lastIndexOf("]") + 1
              );
              json = JSON.parse(fixedTextSquareBr);
            } catch (error) {
              try {
                let fixedTextObjBr =
                  state.fixedText.substring(0, state.fixedText.length - 1) +
                  '"}';
                json = JSON.parse(fixedTextObjBr);
              } catch (error) {
                console.error(
                  "FixedText: ",
                  state.fixedText,
                  " with error: ",
                  error
                );
              }
            }
          }
          commit("SET_FIXED_TEXT", "");
          try {
            commit(
              "SET_COMPLETE_FIXED_TEXT",
              state.completeFixedText.replace(
                json.original,
                "[[" + json.replacement + "]]"
              )
            );
            dispatch("convertToFinished", state.completeFixedText);
          } finally {
            commit("SET_FIXED_TEXT", "");
            if (text.indexOf("{") !== -1) {
              // append current question with text after and including the {
              commit("APPEND_FIXED_TEXT", text.substring(text.indexOf("{")));
            }
          }
        }
      }
    },
    async getChange(
      { dispatch, commit, state, getters, rootState },
      { originalText, textToChange, commentToChange, selectedAll }
    ) {
      await dispatch("waitForFinishLoading");
      commit("SET_LOADING_FIX", true);
      commit("SET_FIXED_TEXT", "");
      commit("SET_FIXED_TEXT_STREAM", "");
      commit("SET_COMPLETE_FIXED_TEXT", state.generatedText);
      pushNextPage(rootState);
      const info = getters.getInfo;
      const { reader, status } = await fetchStream(
        "generate/fixText",
        {
          info,
          originalText,
          textToChange,
          commentToChange,
          selectedAll,
        },
        true,
        true,
        false
      );
      while (status === 200) {
        const unwatch = watch(
          () => state.stopLoading,
          async (newValue) => {
            if (newValue) {
              await reader.cancel(); // Cancel the reader
              unwatch(); // Stop watching when stopLoading becomes true
              return;
            }
          },
          { immediate: true }
        );
        const { value, done } = await reader.read();
        if (done) {
          commit("SET_LOADING_FIX", false);
          let finishedFixedText = state.completeFixedText;
          finishedFixedText = finishedFixedText.replace(/\[\[/g, "");
          finishedFixedText = finishedFixedText.replace(/\]\]/g, "");
          commit("SET_GENERATED_TEXT", finishedFixedText);
          break;
        }
        const arr = value.split("\n");
        arr.forEach((data) => {
          if (data.length === 0) return; // ignore empty message
          const json = JSON.parse(data.substring(6));
          if (json.text === undefined) return;
          dispatch("addChangedText", json.text);
        });
      }
      if (status === 401) {
        commit("SET_ERROR", "Please log in to generate your make");
        commit("SET_LOADING_FIX", false);
        dispatch("modals/openAuth", null, { root: true });
      } else if (status !== 200) {
        commit("SET_LOADING_FIX", false);
        throw new TypeError("Internal Server Error");
      }
    },
    deleteSentence({ commit, state }, indexArray) {
      let newFinishedText = state.finishedText;
      indexArray.forEach((index) => {
        // if there is a space directly after the sentence, replace it with an empty string
        if (newFinishedText[index + 1].text === " ") {
          newFinishedText[index + 1].text = "";
        }
        // replace the sentence with an empty string
        newFinishedText[index].text = "";
        newFinishedText[index].highlightable = false;
      });
      let newlineCount = 0;
      newFinishedText.forEach((item) => {
        // if there are more than 2 new lines in a row, separated by empty strings, or spaces, remove until only 2 new lines are left
        if (item.text === "\n" && newlineCount < 2) {
          newlineCount++;
        } else if (item.text === "\n") {
          item.text = "";
        } else if (item.text !== " " && item.text !== "") {
          newlineCount = 0;
        }
      });
      commit("SET_FINISHED_TEXT", newFinishedText);
      // flatten state.finishedText.text and set the generatedText to the new text
      let newGeneratedText = state.finishedText
        .map((item) => item.text)
        .join("");
      commit("SET_GENERATED_TEXT", newGeneratedText);
    },
    toggleEditMode({ commit, state }) {
      commit("SET_EDIT_MODE", !state.editMode);
      // map state.finishedText to to set all changed to false
      state.finishedText.map((item) => (item.changed = false));
    },
    setVote({ commit, state }, vote) {
      if (state.vote === vote) {
        commit("SET_VOTE", 0);
      } else {
        commit("SET_VOTE", vote);
      }
      fetchData(
        "log/vote",
        {
          vote: state.vote,
        },
        false,
        true,
        false
      );
    },
  },
  getters: {
    getAnsweredQuestions(state, getters, rootState) {
      if (rootState.questions.chosenAnswers.length === 0) return [];
      let answered = [];
      rootState.questions.chosenAnswers.forEach((item) => {
        if (item.options.length === 0) return;
        answered.push({
          question: item.question.question,
          answers: item.options,
        });
      });
      return answered;
    },
    getAnsweredLength(state, getters, rootState) {
      switch (rootState.length.selectedLength) {
        case "0.00":
          return "short";
        case "1.00":
          return "between short and medium";
        case "2.00":
          return "medium";
        case "3.00":
          return "between medium and long";
        case "4.00":
          return "long";
      }
    },
    getInfo(state, getters, rootState) {
      const info = {
        questions: getters.getAnsweredQuestions,
      };
      if (rootState.context.context) {
        info.context = rootState.context.context;
      }
      if (rootState.tone.activeTone) {
        info.tone = rootState.tone.activeTone;
      }
      if (rootState.format.activeFormat) {
        info.format = rootState.format.activeFormat;
      }
      info.length = getters.getAnsweredLength;
      if (rootState.language.activeLanguage) {
        info.language = rootState.language.activeLanguage;
      }
      return info;
    },
  },
  modules: {},
};
