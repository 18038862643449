import { shallowRef } from "vue";

export default {
  name: "tabs",
  namespaced: true,
  state: {
    tabList: [
      { name: "Context", componentName: "Context" },
      { name: "Questions", componentName: "Questions" },
      { name: "Style", componentName: "Styles" },
    ],
    currentTab: { name: "Questions", componentName: "Questions" },
  },
  mutations: {
    SET_CURRENT_TAB_DEFAULT(state) {
      // Find the tab with name 'Questions' and set it
      const defaultTab = state.tabList.find((tab) => tab.name === "Questions");
      state.currentTab = shallowRef(defaultTab);
    },
    SET_CURRENT_TAB(state, payload) {
      state.currentTab = payload;
    },
  },
  actions: {
    reset({ commit }) {
      commit("SET_CURRENT_TAB_DEFAULT");
    },
    setTab({ commit }, tab) {
      commit("SET_CURRENT_TAB", tab);
    },
    setDefaultTab({ commit }) {
      // if state.currentTab is an empty object, set it to the question tab
      if (Object.keys(this.state.tabs.currentTab).length === 0) {
        commit("SET_CURRENT_TAB_DEFAULT");
      }
    },
  },
  modules: {},
  getters: {},
};
