import fetchData from "@/utils/fetchData";

export default {
  name: "tours",
  namespaced: true,
  state: {
    showInitialTour: true,
    showResultTour: true,
    showContextTour: true,
    showQuestionsTour: true,
    showStyleTour: true,
    completedTours: [],
  },
  mutations: {
    SET_SHOW_INITIAL_TOUR(state, payload) {
      state.showInitialTour = payload;
    },
    SET_SHOW_RESULT_TOUR(state, payload) {
      state.showResultTour = payload;
    },
    SET_SHOW_CONTEXT_TOUR(state, payload) {
      state.showContextTour = payload;
    },
    SET_SHOW_QUESTIONS_TOUR(state, payload) {
      state.showQuestionsTour = payload;
    },
    SET_SHOW_STYLE_TOUR(state, payload) {
      state.showStyleTour = payload;
    },
    APPEND_COMPLETED_TOURS(state, payload) {
      state.completedTours.push(payload);
    },
  },
  actions: {
    async fetchTours({ commit }) {
      const { response } = await fetchData(
        "user/getTours",
        {},
        false,
        false,
        false
      );
      const tours = response.result.tours;
      commit("SET_SHOW_INITIAL_TOUR", !tours.includes("initial"));
      commit("SET_SHOW_RESULT_TOUR", !tours.includes("result"));
      commit("SET_SHOW_CONTEXT_TOUR", !tours.includes("context"));
      commit("SET_SHOW_QUESTIONS_TOUR", !tours.includes("questions"));
      commit("SET_SHOW_STYLE_TOUR", !tours.includes("style"));
      commit("APPEND_COMPLETED_TOURS", tours);
    },
    async cancelTours({ commit }) {
      commit("SET_SHOW_INITIAL_TOUR", false);
      commit("SET_SHOW_RESULT_TOUR", false);
      commit("SET_SHOW_CONTEXT_TOUR", false);
      commit("SET_SHOW_QUESTIONS_TOUR", false);
      commit("SET_SHOW_STYLE_TOUR", false);
      commit("APPEND_COMPLETED_TOURS", [
        "initial",
        "result",
        "context",
        "questions",
        "style",
      ]);
      await fetchData(
        "user/setTours",
        { tours: ["initial", "result", "context", "questions", "style"] },
        false,
        false,
        false
      );
    },
    unsetInitialTour({ commit }) {
      commit("SET_SHOW_INITIAL_TOUR", true);
    },
    completeInitialTour({ commit }) {
      commit("SET_SHOW_INITIAL_TOUR", false);
      commit("APPEND_COMPLETED_TOURS", "initial");
    },
    unsetResultTour({ commit }) {
      commit("SET_SHOW_RESULT_TOUR", true);
    },
    completeResultTour({ commit }) {
      commit("SET_SHOW_RESULT_TOUR", false);
      commit("APPEND_COMPLETED_TOURS", "result");
    },
    unsetContextTour({ commit }) {
      commit("SET_SHOW_CONTEXT_TOUR", true);
    },
    completeContextTour({ commit }) {
      commit("SET_SHOW_CONTEXT_TOUR", false);
      commit("APPEND_COMPLETED_TOURS", "context");
    },
    unsetQuestionsTour({ commit }) {
      commit("SET_SHOW_QUESTIONS_TOUR", true);
    },
    completeQuestionsTour({ commit }) {
      commit("SET_SHOW_QUESTIONS_TOUR", false);
      commit("APPEND_COMPLETED_TOURS", "questions");
    },
    unsetStyleTour({ commit }) {
      commit("SET_SHOW_STYLE_TOUR", true);
    },
    completeStyleTour({ commit }) {
      commit("SET_SHOW_STYLE_TOUR", false);
      commit("APPEND_COMPLETED_TOURS", "style");
    },
  },
  modules: {},
  getters: {},
};
