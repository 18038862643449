/* eslint-disable no-unused-vars */
import fetchData from "@/utils/fetchData";

export default {
  name: "personalInfo",
  namespaced: true,
  state: {
    name: "",
    gender: "",
    email: "",
    job: "",
    country: "",
    extraInfo: "",
    categories: [],
    removedCategories: [],
    magicCategories: [],
    totalMakes: 0,
    loading: false,
    hasLoaded: false,
  },
  mutations: {
    SET_NAME(state, payload) {
      state.name = payload;
    },
    SET_GENDER(state, payload) {
      state.gender = payload;
    },
    SET_EMAIL(state, payload) {
      state.email = payload;
    },
    SET_JOB(state, payload) {
      state.job = payload;
    },
    SET_COUNTRY(state, payload) {
      state.country = payload;
    },
    SET_EXTRA_INFO(state, payload) {
      state.extraInfo = payload;
    },
    SET_CATEGORIES(state, payload) {
      state.categories = payload;
    },
    ADD_CATEGORY(state, payload) {
      state.categories.push(payload);
    },
    REMOVE_CATEGORY(state, payload) {
      state.categories.splice(payload, 1);
    },
    ADD_REMOVED_CATEGORY(state, payload) {
      state.removedCategories.push(payload);
    },
    POP_REMOVED_CATEGORY(state) {
      state.removedCategories.pop();
    },
    SET_REMOVED_CATEGORIES(state, payload) {
      state.removedCategories = payload;
    },
    SET_MAGIC_CATEGORIES(state, payload) {
      state.magicCategories = payload;
    },
    SET_TOTAL_MAKES(state, payload) {
      state.totalMakes = payload;
    },
    SET_LOADING(state, payload) {
      state.loading = payload;
    },
    SET_HAS_LOADED(state, payload) {
      state.hasLoaded = payload;
    },
  },
  actions: {
    reset({ commit }) {
      commit("SET_NAME", "");
      commit("SET_GENDER", "");
      commit("SET_EMAIL", "");
      commit("SET_JOB", "");
      commit("SET_COUNTRY", "");
      commit("SET_EXTRA_INFO", "");
      commit("SET_CATEGORIES", []);
      commit("SET_REMOVED_CATEGORIES", []);
      commit("SET_MAGIC_CATEGORIES", []);
      commit("SET_TOTAL_MAKES", 0);
      commit("SET_LOADING", false);
      commit("SET_HAS_LOADED", false);
    },
    async fetchInfo({ commit, dispatch }) {
      const { response } = await fetchData(
        "user/personalInfo/get",
        {},
        false,
        false,
        false
      );
      const info = response.result.info.result;
      if (!info) {
        return;
      }
      commit("SET_HAS_LOADED", true);
      dispatch("payloadSaver", info);
    },
    async saveInfo({ commit, dispatch }, payload) {
      commit("SET_LOADING", true);
      dispatch("payloadSaver", payload);
      await fetchData(
        "user/personalInfo/set",
        { info: payload },
        false,
        false,
        false
      );
      commit("SET_REMOVED_CATEGORIES", []);
      commit("SET_LOADING", false);
    },
    async getMagicInfo({ commit, dispatch }) {
      commit("SET_LOADING", true);
      dispatch("clearMagicCategories");
      const { response } = await fetchData(
        "user/personalInfo/learn",
        {},
        false,
        false,
        false
      );
      let json = response.result.finalText.replace(/&quot;/gi, '"');
      json = JSON.parse(json);
      commit("SET_MAGIC_CATEGORIES", json.array);
      commit("SET_LOADING", false);
      dispatch("modals/openMagicInfoCategories", null, { root: true });
      return;
    },
    setCategories({ commit }, payload) {
      commit("SET_CATEGORIES", payload);
    },
    addCategory({ state, commit }, payload) {
      // if payload is an array, then copy state.categories to temp, add each item in payload to temp, then set state.categories to temp
      if (Array.isArray(payload)) {
        // if two objects in temp share the same name, remove the duplicate
        const temp = [...payload, ...state.categories].filter(
          (v, i, a) => a.findIndex((t) => t.name === v.name) === i
        );
        commit("SET_CATEGORIES", temp);
      } else {
        commit("ADD_CATEGORY", payload);
      }
    },
    clearMagicCategories({ commit }) {
      commit("SET_MAGIC_CATEGORIES", []);
    },
    incrementTotalMakes({ commit, state }) {
      commit("SET_TOTAL_MAKES", state.totalMakes + 1);
    },
    removeCategory({ commit, state }, index) {
      commit("ADD_REMOVED_CATEGORY", state.categories[index]);
      commit("REMOVE_CATEGORY", index);
    },
    undoRemoveCategory({ commit, state }) {
      // get last element in removedCategories
      const removedCategory =
        state.removedCategories[state.removedCategories.length - 1];
      commit("POP_REMOVED_CATEGORY");
      commit("ADD_CATEGORY", removedCategory);
    },
    payloadSaver({ state, commit }, payload) {
      if (payload.name) {
        commit("SET_NAME", payload.name);
      }
      if (payload.gender) {
        commit("SET_GENDER", payload.gender);
      }
      if (payload.email) {
        commit("SET_EMAIL", payload.email);
      }
      if (payload.job) {
        commit("SET_JOB", payload.job);
      }
      if (payload.country) {
        commit("SET_COUNTRY", payload.country);
      }
      if (payload.extraInfo) {
        commit("SET_EXTRA_INFO", payload.extraInfo);
      }
      if (payload.categories) {
        // if there are categories with the same name in payload and state.categories, remove the duplicate from state.categories
        const temp = [...state.categories, ...payload.categories].filter(
          (v, i, a) => a.findIndex((t) => t.name === v.name) === i
        );
        commit("SET_CATEGORIES", temp);
      }
      if (payload.totalMakes) {
        commit("SET_TOTAL_MAKES", Number(payload.totalMakes));
      }
    },
  },
  modules: {},
  getters: {},
};
