<script setup>
// get the current year
const year = new Date().getFullYear();
</script>

<template>
  <section class="flex">
    <div class="mt-1 -mb-4 md:-mb-7 z-50 w-full flex">
      <a
        href="PrivacyPolicy.pdf"
        target="_blank"
        class="text-gray-400 hover:text-primary hover:bg-gray-200 hover:opacity-40 px-0.5 md:px-1 ml-1 md:ml-3 rounded-lg text-xs md:text-sm"
      >
        <p>Privacy policy</p>
      </a>
      <a
        href="TermsOfUse.pdf"
        target="_blank"
        class="text-gray-400 hover:text-primary hover:bg-gray-200 hover:opacity-40 px-0.5 md:px-1 ml-1 md:ml-3 rounded-lg text-xs md:text-sm"
      >
        <p>Terms of Use</p>
      </a>
      <!-- Mailto button -->
      <a
        href="mailto:support@makemea.com"
        target="_blank"
        class="text-gray-400 hover:text-primary hover:bg-gray-200 hover:opacity-40 px-0.5 md:px-1 ml-1 md:ml-3 rounded-lg text-xs md:text-sm"
      >
        <p>Contact us</p>
      </a>
      <span
        class="hidden xs:block text-gray-400 ml-auto mr-3 my-0.5 text-xs md:text-sm"
        >© {{ year }} MakeMeA Ltd.</span
      >
    </div>
  </section>
</template>
