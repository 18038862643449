import _ from "lodash";

const getNextId = (db) => {
  return new Promise((resolve, reject) => {
    const transaction = db.transaction(["states"], "readonly");
    const objectStore = transaction.objectStore("states");

    const countRequest = objectStore.count();

    countRequest.onsuccess = function () {
      resolve(countRequest.result);
    };

    countRequest.onerror = function () {
      reject(countRequest.error);
    };
  });
};

const getSecondLastId = (db) => {
  return new Promise((resolve) => {
    const transaction = db.transaction(["states"], "readonly");
    const objectStore = transaction.objectStore("states");

    const getRequest = objectStore.getAll();
    // find where the time is the second latest
    getRequest.onsuccess = function () {
      const states = getRequest.result;
      const latestState = _.maxBy(states, (state) => state.time);
      const secondLatestState = _.maxBy(
        states.filter((state) => state.id !== latestState.id),
        (state) => state.time
      );
      if (!secondLatestState) {
        resolve(0);
      } else {
        resolve(secondLatestState.id);
      }
    };
  });
};

const getLastId = (db) => {
  return new Promise((resolve) => {
    const transaction = db.transaction(["states"], "readonly");
    const objectStore = transaction.objectStore("states");

    const getRequest = objectStore.getAll();
    // find where the time is the latest
    getRequest.onsuccess = function () {
      const states = getRequest.result;
      const latestState = _.maxBy(states, (state) => state.time);
      if (!latestState) {
        resolve(0);
      } else {
        resolve(latestState.id);
      }
    };
  });
};

export { getNextId, getSecondLastId, getLastId };
