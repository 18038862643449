<script setup>
import { ref, computed } from "vue";
import { useStore } from "vuex";
import Modals from "../modals/Modals.vue";
import DescriptionText from "./DescriptionText.vue";
import MobileBaseBar from "./generate/MobileBaseBar.vue";
import ProfileButtonBar from "./ProfileButtonBar.vue";
import TabHolder from "../tabs/TabHolder.vue";
import GenerateBar from "./generate/GenerateBar.vue";
import StartBar from "./StartBar.vue";
import InfoBar from "./info/InfoBar.vue";
import InfoInputs from "./info/InfoInputs.vue";
import ObserverWrapper from "../ObserverWrapper.vue";
import GenerateButtons from "../tabs/result/GenerateButtons.vue";

const store = useStore();

const isOpen = computed(() => store.state.drawer.isOpen);
const openPurpose = computed(() => store.state.drawer.openPurpose);

const drawer = ref(null);
const infoBar = ref(null);
const infoInputs = ref(null);
const generateBar = ref(null);
const showFixedGenerateBar = ref(false);
const showGenerateButtons = ref(false);

const saveInfo = () => {
  infoInputs.value.save();
};

const doneSave = () => {
  infoBar.value.back();
};

const generateBarNewPrompt = () => {
  generateBar.value.newPrompt();
};
</script>

<template>
  <Modals />
  <ProfileButtonBar />
  <section ref="drawer">
    <div
      :class="
        isOpen
          ? 'xm:-translate-y-[29rem] lg:-translate-y-[33rem] md:-translate-y-[32rem] sm:-translate-y-[30rem] xs:-translate-y-[30rem] -translate-y-[34rem]'
          : 'translate-y-0'
      "
      class="absolute z-20 flex flex-col items-end w-full transition duration-700 ease-in-out delay-500"
    >
      <div class="relative hidden float-right lg:block">
        <img
          :class="isOpen ? '-mb-[1px]' : ''"
          class="h-14 -z-10 relative"
          src="/tags.svg"
          alt="tags"
        />
      </div>
      <div
        class="transition-all duration-[2000ms] w-full px-4 bg-white border border-transparent md:px-16 rounded-t-2xl lg:rounded-tr-none lg:rounded-tl-2xl"
        :class="
          isOpen
            ? 'min-h-[calc(100dvh)] lg:min-h-screen'
            : 'min-h-[calc(60dvh)] lg:min-h-[calc(42dvh)]'
        "
      >
        <div v-if="!isOpen">
          <DescriptionText
            class="hidden text-right lg:block -mt-8"
            location="below"
          />
          <StartBar />
        </div>
        <div v-else-if="openPurpose === 'createMake'">
          <ObserverWrapper @hidden="showFixedGenerateBar = $event">
            <GenerateBar ref="generateBar" />
          </ObserverWrapper>
          <TabHolder @showGenerateButtons="showGenerateButtons = $event" />
        </div>
        <div v-else-if="openPurpose === 'setInfo'">
          <InfoBar ref="infoBar" @triggerSave="saveInfo" />
          <InfoInputs ref="infoInputs" @doneSave="doneSave" />
        </div>
      </div>
    </div>
    <div
      v-if="isOpen && openPurpose === 'createMake' && showFixedGenerateBar"
      class="w-full bg-white fixed top-0 z-25 md:px-16 px-4 -mt-8"
    >
      <GenerateBar :hide-unnecessary="true" />
    </div>
    <div
      v-if="showGenerateButtons"
      class="w-full bg-white fixed top-0 z-25 px-4 -mt-3 border border-b-primary pr-16"
    >
      <GenerateButtons />
    </div>
  </section>
  <MobileBaseBar @newPrompt="generateBarNewPrompt" />
</template>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
