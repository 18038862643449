import { watch } from "vue";
import fetchStream from "@/utils/fetchStream";

export default {
  name: "tone",
  namespaced: true,
  state: {
    stopLoading: false,
    toneList: [],
    loading: false,
    currToneStream: "",
    currTone: "",
    activeTone: "",
  },
  mutations: {
    SET_STOP_LOADING(state, payload) {
      state.stopLoading = payload;
    },
    SET_TONE_LIST(state, payload) {
      state.toneList = payload;
    },
    APPEND_TONE_LIST(state, payload) {
      state.toneList.push(payload);
    },
    SET_LOADING(state, payload) {
      state.loading = payload;
    },
    SET_CURRENT_TONE_STREAM(state, payload) {
      state.currToneStream = payload;
    },
    APPEND_CURRENT_TONE_STREAM(state, payload) {
      state.currToneStream += payload;
    },
    SET_CURRENT_TONE(state, payload) {
      state.currTone = payload;
    },
    APPEND_CURRENT_TONE(state, payload) {
      state.currTone += payload;
    },
    SET_ACTIVE_TONE(state, payload) {
      state.activeTone = payload;
    },
  },
  actions: {
    reset({ commit }) {
      return new Promise((resolve) => {
        commit("SET_TONE_LIST", []);
        commit("SET_CURRENT_TONE_STREAM", "");
        commit("SET_CURRENT_TONE", "");
        commit("SET_ACTIVE_TONE", "");
        commit("SET_LOADING", false);
        commit("SET_STOP_LOADING", true);
        resolve();
      });
    },
    resetLoading({ commit, state, dispatch }) {
      if (state.loading) {
        commit("SET_LOADING", false);
        dispatch("getTones");
      }
    },
    addTones({ commit, state }, text) {
      if (text === undefined) return;
      commit("APPEND_CURRENT_TONE_STREAM", text);

      if (state.currToneStream === text) {
        const first = text.indexOf("[");
        if (first === -1) {
          commit("SET_CURRENT_TONE_STREAM", "");
        } else {
          commit("SET_CURRENT_TONE_STREAM", text.substring(first));
        }
      } else if (state.currTone !== "" || text.indexOf("{") !== -1) {
        if (text.indexOf("}") === -1) {
          commit("APPEND_CURRENT_TONE", text);
        } else {
          commit("APPEND_CURRENT_TONE", "}");
          let json = {};
          try {
            json = JSON.parse(state.currTone);
          } catch (error) {
            try {
              let currToneSquareBr = state.currTone.substring(
                0,
                state.currTone.lastIndexOf("]") + 1
              );
              json = JSON.parse(currToneSquareBr);
            } catch (error) {
              try {
                let currToneObjBr =
                  state.currTone.substring(0, state.currTone.length - 1) + '"}';
                json = JSON.parse(currToneObjBr);
              } catch (error) {
                console.error("error: ", error);
              }
            }
          }
          try {
            const tempList = state.toneList;
            tempList.push(json);
            JSON.stringify(tempList);
            commit("SET_TONE_LIST", tempList);
          } finally {
            commit("SET_CURRENT_TONE", "");
          }
        }
      }
    },
    waitForFinishLoading({ state }) {
      return new Promise((resolve) => {
        if (!state.loading) {
          return resolve();
        }
        const intervalId = setInterval(() => {
          if (!state.loading) {
            clearInterval(intervalId);
            resolve();
          }
        }, 50);
      });
    },
    async getTones({ dispatch, commit, state }, { retries = 0 } = {}) {
      let cached = false;
      await dispatch("waitForFinishLoading");
      await dispatch("reset");
      commit("SET_LOADING", true);
      commit("SET_STOP_LOADING", false);
      try {
        const { reader, status } = await fetchStream("generate/tones", {});
        while (status === 200) {
          const unwatch = watch(
            () => state.stopLoading,
            async (newValue) => {
              if (newValue) {
                await reader.cancel(); // Cancel the reader
                dispatch("reset");
                unwatch(); // Stop watching when stopLoading becomes true
                return;
              }
            },
            { immediate: true }
          );
          const { value, done } = await reader.read();
          if (done && cached) {
            commit(
              "SET_CURRENT_TONE_STREAM",
              state.currToneStream.substring(
                0,
                state.currToneStream.lastIndexOf("]") + 1
              )
            );
            commit("SET_TONE_LIST", JSON.parse(state.currToneStream));
            commit("SET_LOADING", false);
            break;
          } else if (done) {
            commit("SET_LOADING", false);
            break;
          }
          const arr = value.split("\n");
          arr.forEach((data) => {
            if (data.length === 0) return; // ignore empty message
            const json = JSON.parse(data.substring(6));
            if (json.cached) {
              cached = true;
              commit("APPEND_CURRENT_TONE_STREAM", json.cached);
            } else {
              if (json.text === undefined) return;
              let text = json.text;
              // remove \n from text
              text = text.replace(/(\r\n|\n|\r)/gm, "");
              dispatch("addTones", text);
            }
          });
        }
        if (status === 401) {
          dispatch("modals/openAuth", null, { root: true });
        } else if (status !== 200) {
          throw new TypeError("Internal Server Error");
        }
      } catch (error) {
        if (error instanceof TypeError && retries < 3) {
          console.log("Retrying Ts x", retries);
          setTimeout(() => {
            commit("SET_LOADING", false);
            dispatch("getTones", {
              retries: retries + 1,
            });
          }, 500 * retries + 500);
        } else {
          console.error(error);
          commit("SET_LOADING", false);
        }
      }
    },
    addTone({ commit }, tone) {
      commit("APPEND_TONE_LIST", tone);
    },
    selectTone({ commit, state }, tone) {
      if (state.activeTone === tone) {
        commit("SET_ACTIVE_TONE", "");
        return;
      }
      commit("SET_ACTIVE_TONE", tone);
    },
  },
  getters: {},
  modules: {},
};
