import { watch } from "vue";
import fetchStream from "@/utils/fetchStream";

export default {
  name: "questions",
  namespaced: true,
  state: {
    stopLoading: false,
    questionList: [],
    additionalQuestionList: [],
    contextQuestionList: [],
    loading: false,
    contextQuestionLoading: false,
    currQuestionStream: "",
    currQuestion: "",
    id: 0,
    activeEdit: "",
    chosenAnswers: [],
    extraQuestionsRequestQueue: [],
    isProcessingExtraQuestions: false,
  },
  mutations: {
    SET_STOP_LOADING(state, payload) {
      state.stopLoading = payload;
    },
    SET_QUESTION_LIST(state, payload) {
      state.questionList = payload;
    },
    APPEND_QUESTION_LIST(state, payload) {
      state.questionList.push(payload);
    },
    FORMAT_QUESTION_LIST(state) {
      state.questionList.forEach((question, index) => {
        question.id = index;
        question.option = "";
      });
    },
    APPEND_QUESTION_LIST_FROM_ADDITIONAL_QUESTIONS(state) {
      state.questionList.push(state.additionalQuestionList.pop());
    },
    SET_ADDITIONAL_QUESTION_LIST(state, payload) {
      state.additionalQuestionList = payload;
    },
    APPEND_ADDITIONAL_QUESTION_LIST(state, payload) {
      state.additionalQuestionList.push(payload);
    },
    SET_CONTEXT_QUESTION_LIST(state, payload) {
      state.contextQuestionList = payload;
    },
    APPEND_CONTEXT_QUESTION_LIST(state, payload) {
      state.contextQuestionList.push(payload);
    },
    APPEND_QUESTION_ANSWER(state, { question, value }) {
      state.questionList = state.questionList.map((item) => {
        if (item === question) {
          item.suggestedAnswers.unshift(value);
          item.option = "";
        }
        return item;
      });
    },
    APPEND_CONTEXT_QUESTION_ANSWER(state, { question, value }) {
      state.contextQuestionList = state.contextQuestionList.map((item) => {
        if (item === question) {
          item.suggestedAnswers.unshift(value);
          item.option = "";
        }
        return item;
      });
    },
    SET_QUESTION_ANSWER(state, { questionId, value }) {
      state.questionList[questionId].suggestedAnswers = state.questionList[
        questionId
      ].suggestedAnswers.map((suggestedAnswer) => {
        if (suggestedAnswer === state.activeEdit) {
          return value;
        }
        return suggestedAnswer;
      });
    },
    SET_CONTEXT_QUESTION_ANSWER(state, { questionId, value }) {
      // find index of questionId in contextQuestionList
      const index = state.contextQuestionList.findIndex(
        (question) => question.id === questionId
      );
      state.contextQuestionList[index].suggestedAnswers =
        state.contextQuestionList[index].suggestedAnswers.map(
          (suggestedAnswer) => {
            if (suggestedAnswer === state.activeEdit) {
              return value;
            }
            return suggestedAnswer;
          }
        );
    },
    SET_LOADING(state, payload) {
      state.loading = payload;
    },
    SET_CONTEXT_QUESTION_LOADING(state, payload) {
      state.contextQuestionLoading = payload;
    },
    SET_CURRENT_QUESTION_STREAM(state, payload) {
      state.currQuestionStream = payload;
    },
    APPEND_CURRENT_QUESTION_STREAM(state, payload) {
      state.currQuestionStream += payload;
    },
    SET_CURRENT_QUESTION(state, payload) {
      state.currQuestion = payload;
    },
    APPEND_CURRENT_QUESTION(state, payload) {
      state.currQuestion += payload;
    },
    INCREMENT_ID(state) {
      state.id += 1;
    },
    SET_ID(state, payload) {
      state.id = payload;
    },
    SET_ACTIVE_EDIT(state, payload) {
      state.activeEdit = payload;
    },
    SET_CHOSEN_ANSWERS(state, payload) {
      state.chosenAnswers = payload;
    },
    APPEND_CHOSEN_ANSWERS(state, payload) {
      state.chosenAnswers.push(payload);
    },
    SET_EXTRA_QUESTIONS_REQUEST_QUEUE(state, payload) {
      state.extraQuestionsRequestQueue = payload;
    },
    APPEND_EXTRA_QUESTIONS_REQUEST_QUEUE(state, payload) {
      state.extraQuestionsRequestQueue.push(payload);
    },
    SHIFT_EXTRA_QUESTIONS_REQUEST_QUEUE(state) {
      state.extraQuestionsRequestQueue.shift();
    },
    SET_IS_PROCESSING_EXTRA_QUESTIONS(state, payload) {
      state.isProcessingExtraQuestions = payload;
    },
  },
  actions: {
    reset({ commit }) {
      return new Promise((resolve) => {
        commit("SET_QUESTION_LIST", []);
        commit("SET_CURRENT_QUESTION_STREAM", "");
        commit("SET_CURRENT_QUESTION", "");
        commit("SET_ADDITIONAL_QUESTION_LIST", []);
        commit("SET_CONTEXT_QUESTION_LIST", []);
        commit("SET_ID", 0);
        commit("SET_CHOSEN_ANSWERS", []);
        commit("SET_LOADING", false);
        commit("SET_CONTEXT_QUESTION_LOADING", false);
        commit("SET_STOP_LOADING", true);
        commit("SET_EXTRA_QUESTIONS_REQUEST_QUEUE", []);
        commit("SET_IS_PROCESSING_EXTRA_QUESTIONS", false);
        resolve();
      });
    },
    resetLoading({ commit, state, dispatch }) {
      if (state.loading) {
        commit("SET_LOADING", false);
        dispatch("getQuestions");
      }
    },
    addQuestions({ commit, state }, { text, location }) {
      if (text === undefined) return;
      // replace all \" with "
      text = text.replace(/\\"/g, '"');
      commit("APPEND_CURRENT_QUESTION_STREAM", text);
      if (state.currQuestionStream === text) {
        const first = text.indexOf("[");
        if (first === -1) {
          commit("SET_CURRENT_QUESTION_STREAM", "");
        } else {
          commit("SET_CURRENT_QUESTION_STREAM", text.substring(first));
        }
      } else if (state.currQuestion !== "" || text.indexOf("{") !== -1) {
        if (text.indexOf("}") === -1) {
          commit("APPEND_CURRENT_QUESTION", text);
        } else {
          commit("APPEND_CURRENT_QUESTION", "}");
          let json = {};
          try {
            json = JSON.parse(state.currQuestion);
          } catch (error) {
            try {
              let currQuestionSquareBr = state.currQuestion.substring(
                0,
                state.currQuestion.lastIndexOf("]") + 1
              );
              json = JSON.parse(currQuestionSquareBr);
            } catch (error) {
              try {
                let currQuestionObjBr =
                  state.currQuestion.substring(
                    0,
                    state.currQuestion.length - 1
                  ) + '"}';
                json = JSON.parse(currQuestionObjBr);
              } catch (error) {
                console.error(
                  "Question: ",
                  state.currQuestion,
                  " with error: ",
                  error
                );
                return;
              }
            }
          }
          json.id = state.id;
          json.option = "";
          commit("INCREMENT_ID");
          try {
            commit(location, json);
          } finally {
            commit("SET_CURRENT_QUESTION", "");
            if (text.indexOf("{") !== -1) {
              // append current question with text after and including the {
              commit(
                "APPEND_CURRENT_QUESTION",
                text.substring(text.indexOf("{"))
              );
            }
          }
        }
      }
    },
    waitForFinishLoading({ state }) {
      return new Promise((resolve) => {
        if (!state.loading) {
          return resolve();
        }
        const intervalId = setInterval(() => {
          if (!state.loading) {
            clearInterval(intervalId);
            resolve();
          }
        }, 100);
      });
    },
    replaceQuestionsUpTo8({ state, commit, dispatch, getters }) {
      return new Promise((resolve) => {
        const intervalId = setInterval(() => {
          if (
            state.additionalQuestionList.length > 0 &&
            state.questionList.length < 8
          ) {
            commit("APPEND_QUESTION_LIST_FROM_ADDITIONAL_QUESTIONS");
          }
          if (
            state.additionalQuestionList.length === 0 &&
            state.isProcessingExtraQuestions === false
          ) {
            dispatch("getExtraQuestions", {
              originalList: getters.getQuestionListAsString,
            });
          }
          if (state.questionList.length >= 8) {
            commit("SET_LOADING", false);
            clearInterval(intervalId);
            resolve();
          }
        }, 100);
      });
    },
    async getQuestions({ dispatch, commit, state }, { retries = 0 } = {}) {
      let cached = false;
      await dispatch("waitForFinishLoading");
      await dispatch("reset");
      commit("SET_LOADING", true);
      commit("SET_STOP_LOADING", false);
      try {
        const { reader, status } = await fetchStream("generate/questions", {});
        while (status === 200) {
          const unwatch = watch(
            () => state.stopLoading,
            async (newValue) => {
              if (newValue) {
                await reader.cancel(); // Cancel the reader
                dispatch("reset");
                unwatch(); // Stop watching when stopLoading becomes true
                return;
              }
            },
            { immediate: true }
          );
          const { value, done } = await reader.read();
          if (done && cached) {
            commit(
              "SET_CURRENT_QUESTION_STREAM",
              state.currQuestionStream.substring(
                0,
                state.currQuestionStream.lastIndexOf("]") + 1
              )
            );
            commit("SET_QUESTION_LIST", JSON.parse(state.currQuestionStream));
            commit("FORMAT_QUESTION_LIST");
            commit("SET_LOADING", false);
            break;
          } else if (done) {
            commit("SET_LOADING", false);
            break;
          }
          const arr = value.split("\n");
          arr.forEach((data) => {
            if (data.length === 0) return; // ignore empty message
            const json = JSON.parse(data.substring(6));
            if (json.cached) {
              cached = true;
              commit("APPEND_CURRENT_QUESTION_STREAM", json.cached);
            } else {
              if (json.text === undefined) return;
              let text = json.text;
              // remove \n from text
              text = text.replace(/(\r\n|\n|\r)/gm, "");
              dispatch("addQuestions", {
                text,
                location: "APPEND_QUESTION_LIST",
              });
            }
          });
        }
        if (status === 401) {
          dispatch("modals/openAuth", null, { root: true });
        } else if (status !== 200) {
          throw new TypeError("Internal Server Error");
        }
      } catch (error) {
        if (error instanceof TypeError && retries < 3) {
          console.log("Retrying Qs x", retries);
          setTimeout(() => {
            commit("SET_LOADING", false);
            dispatch("getQuestions", { retries: retries + 1 });
          }, 500 * retries + 500);
        } else {
          console.error(error);
          commit("SET_LOADING", false);
        }
      }
    },
    async replaceQuestion(
      { dispatch, commit, state, getters },
      { questionId }
    ) {
      const originalList = getters.getQuestionListAsString;
      // remove question from state.questionList where id = questionId
      const tempList = state.questionList.filter(
        (question) => question.id !== questionId
      );
      commit("SET_QUESTION_LIST", tempList);
      commit(
        "SET_CHOSEN_ANSWERS",
        state.chosenAnswers.filter((item) => item.question.id !== questionId)
      );
      if (state.loading) return;
      if (state.additionalQuestionList.length > 0) {
        commit("APPEND_QUESTION_LIST_FROM_ADDITIONAL_QUESTIONS");
      } else {
        dispatch("getExtraQuestions", { originalList });
        await dispatch("replaceQuestionsUpTo8");
      }
    },
    async getExtraQuestions(
      { dispatch, commit, state, getters },
      { originalList, retries = 0 } = {}
    ) {
      // commit("APPEND_EXTRA_QUESTIONS_REQUEST_QUEUE", { originalList, retries });

      if (state.isProcessingExtraQuestions) return;
      commit("SET_IS_PROCESSING_EXTRA_QUESTIONS", true);

      // const { originalList, retries } = state.extraQuestionsRequestQueue[0];

      await dispatch("waitForFinishLoading");
      commit("SET_CURRENT_QUESTION_STREAM", "");
      commit("SET_CURRENT_QUESTION", "");
      commit("SET_LOADING", true);
      commit("SET_STOP_LOADING", false);
      commit("SET_ID", getters.getHighestId + 1);
      try {
        const { reader, status } = await fetchStream(
          "generate/questions/additional",
          {
            original: originalList,
          }
        );
        while (status === 200) {
          const unwatch = watch(
            () => state.stopLoading,
            async (newValue) => {
              if (newValue) {
                await reader.cancel(); // Cancel the reader
                unwatch(); // Stop watching when stopLoading becomes true
                return;
              }
            },
            { immediate: true }
          );
          const { value, done } = await reader.read();
          if (done) {
            break;
          }
          const arr = value.split("\n");
          arr.forEach((data) => {
            if (data.length === 0) return; // ignore empty message
            const json = JSON.parse(data.substring(6));
            if (json.text === undefined) return;
            let text = json.text;
            // remove \n from text
            text = text.replace(/(\r\n|\n|\r)/gm, "");
            dispatch("addQuestions", {
              text,
              location: "APPEND_ADDITIONAL_QUESTION_LIST",
            });
          });
        }
        if (status === 401) {
          dispatch("modals/openAuth", null, { root: true });
        } else if (status !== 200) {
          throw new TypeError("Internal Server Error");
        }
      } catch (error) {
        if (error instanceof TypeError && retries < 3) {
          console.log("Retrying EQs x", retries);
          setTimeout(() => {
            commit("SET_LOADING", false);
            dispatch("getExtraQuestions", {
              originalList,
              retries: retries + 1,
            });
          }, 500 * retries + 500);
        } else {
          console.error(error);
          commit("SET_LOADING", false);
        }

        // commit("SHIFT_EXTRA_QUESTIONS_REQUEST_QUEUE");
      }
      commit("SET_IS_PROCESSING_EXTRA_QUESTIONS", false);
    },
    async addContextQuestions(
      { dispatch, commit, state, getters, rootState },
      { replaceQuestions }
    ) {
      if (replaceQuestions) {
        // find all questions from contextQuestionList that are in state.chosenAnswers
        const answeredContextQuestions = state.contextQuestionList.filter(
          (contextQuestion) => {
            return state.chosenAnswers.some((chosenAnswer) => {
              return (
                chosenAnswer.question.question === contextQuestion.question
              );
            });
          }
        );
        commit("SET_CONTEXT_QUESTION_LIST", answeredContextQuestions);
      }
      const originalQuestionList = getters.getQuestionListAsString;
      const contextQuestionList = getters.getContextQuestionListAsString;
      let askedQuestionList = originalQuestionList + contextQuestionList;
      // replace }][{ with },{ to separate the original and context question list
      askedQuestionList = askedQuestionList.replace("}][{", "},{");
      dispatch("context/resetChangedAfterQuestionsGenerated");
      dispatch("getContextQuestions", {
        askedQuestionList,
        context: rootState.context.context,
      });
    },
    async getContextQuestions(
      { dispatch, commit, state, getters },
      { askedQuestionList, context, retries = 0 } = {}
    ) {
      await dispatch("waitForFinishLoading");
      commit("SET_CURRENT_QUESTION_STREAM", "");
      commit("SET_CURRENT_QUESTION", "");
      commit("SET_CONTEXT_QUESTION_LOADING", true);
      commit("SET_STOP_LOADING", false);
      commit("SET_ID", getters.getHighestId + 1);
      dispatch("context/resetChangedAfterQuestionsGenerated", null, {
        root: true,
      });
      try {
        const { reader, status } = await fetchStream(
          "generate/questions/context",
          {
            askedQuestions: askedQuestionList,
            context,
          }
        );
        while (status === 200) {
          const unwatch = watch(
            () => state.stopLoading,
            async (newValue) => {
              if (newValue) {
                await reader.cancel(); // Cancel the reader
                unwatch(); // Stop watching when stopLoading becomes true
                return;
              }
            },
            { immediate: true }
          );
          const { value, done } = await reader.read();
          if (done) {
            commit("SET_CONTEXT_QUESTION_LOADING", false);
            break;
          }
          const arr = value.split("\n");
          arr.forEach((data) => {
            if (data.length === 0) return; // ignore empty message
            const json = JSON.parse(data.substring(6));
            if (json.text === undefined) return;
            let text = json.text;
            // remove \n from text
            text = text.replace(/(\r\n|\n|\r)/gm, "");
            dispatch("addQuestions", {
              text,
              location: "APPEND_CONTEXT_QUESTION_LIST",
            });
          });
        }
        if (status === 401) {
          dispatch("modals/openAuth", null, { root: true });
        } else if (status !== 200) {
          throw new TypeError("Internal Server Error");
        }
      } catch (error) {
        if (error instanceof TypeError && retries < 3) {
          console.log("Retrying EQs x", retries);
          setTimeout(() => {
            commit("SET_CONTEXT_QUESTION_LOADING", false);
            dispatch("waitForFinishLoading", {
              askedQuestionList,
              context,
              retries: retries + 1,
            });
          }, 500 * retries + 500);
        } else {
          console.error(error);
          commit("SET_CONTEXT_QUESTION_LOADING", false);
        }
      }
    },
    toggleSelect({ commit, state }, { option, question }) {
      let foundQuestion = state.chosenAnswers.find(
        (item) => item.question === question
      );
      if (foundQuestion) {
        if (foundQuestion.options.includes(option)) {
          foundQuestion.options = foundQuestion.options.filter(
            (item) => item !== option
          );
        } else {
          // here push option into the foundQuestion's options
          foundQuestion.options.push(option);
        }
      } else {
        // if no match found in the chosenAnswers, create a new entry.
        commit("APPEND_CHOSEN_ANSWERS", {
          question: question,
          options: [option],
        });
      }
    },
    addAnswer({ commit, dispatch }, { value, question }) {
      commit("APPEND_QUESTION_ANSWER", { question, value });
      dispatch("toggleSelect", { option: value, question });
    },
    addContextAnswer({ commit, dispatch }, { value, question }) {
      commit("APPEND_CONTEXT_QUESTION_ANSWER", { question, value });
      dispatch("toggleSelect", { option: value, question });
    },
    changeAnswer({ commit, state, dispatch }, { value, question }) {
      if (value === "") return;
      commit("SET_QUESTION_ANSWER", { questionId: question.id, value });
      dispatch("toggleSelect", {
        option: state.activeEdit,
        question,
      });
      commit("SET_ACTIVE_EDIT", "");
      dispatch("toggleSelect", {
        option: value,
        question,
      });
    },
    changeContextAnswer({ commit, state, dispatch }, { value, question }) {
      if (value === "") return;
      commit("SET_CONTEXT_QUESTION_ANSWER", { questionId: question.id, value });
      dispatch("toggleSelect", {
        option: state.activeEdit,
        question,
      });
      commit("SET_ACTIVE_EDIT", "");
      dispatch("toggleSelect", {
        option: value,
        question,
      });
    },
  },
  getters: {
    getHighestId(state) {
      const maxIdInQuestionList = state.questionList.reduce(
        (maxId, question) => {
          return Math.max(maxId, question.id);
        },
        0
      );

      const maxIdInContextQuestionList = state.contextQuestionList.reduce(
        (maxId, question) => {
          return Math.max(maxId, question.id);
        },
        0
      );

      return Math.max(maxIdInQuestionList, maxIdInContextQuestionList);
    },
    getQuestionListAsString(state) {
      // format questionList as [{"Question": "question", "SuggestedAnswers": ["answer1", "answer2"]}]
      return JSON.stringify(
        state.questionList.map((question) => {
          return {
            question: question.question,
            suggestedAnswers: question.suggestedAnswers,
          };
        })
      );
    },
    getContextQuestionListAsString(state) {
      // format questionList as [{"Question": "question", "SuggestedAnswers": ["answer1", "answer2"]}]
      return JSON.stringify(
        state.contextQuestionList.map((question) => {
          return {
            question: question.question,
            suggestedAnswers: question.suggestedAnswers,
          };
        })
      );
    },
  },
  modules: {},
};
