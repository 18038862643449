<script setup>
import { computed } from "vue";
import { useStore } from "vuex";

const store = useStore();

const toneList = computed(() => store.state.tone.toneList);
const activeTone = computed(() => store.state.tone.activeTone);

const selectTone = (tone) => store.dispatch("tone/selectTone", tone);
</script>

<template>
  <div v-for="tone in toneList" :key="tone">
    <div
      v-if="tone.tone"
      :class="
        activeTone == tone.tone
          ? 'bg-accentSecondary'
          : 'bg-gray-10 hover:bg-gray-100'
      "
      class="flex flex-col items-center justify-center mt-4 lg:mt-0 w-24 h-24 mr-4 rounded-lg cursor-pointer"
      @click="selectTone(tone.tone)"
    >
      <p class="drop-shadow-[0_0_4px_rgba(0,0,0,1)] text-3xl">
        {{ tone.emoji ? (tone.emoji.length > 3 ? "" : tone.emoji) : "" }}
      </p>
      <label
        class="mt-2 text-sm text-center cursor-pointer"
        :class="activeTone == tone.tone ? 'text-primary' : 'text-secondary'"
      >
        {{ tone.tone }}
      </label>
    </div>
  </div>
</template>
