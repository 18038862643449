<script setup>
import { computed } from "vue";
import { useStore } from "vuex";

const store = useStore();

const formatList = computed(() => store.state.format.formatList);
const activeFormat = computed(() => store.state.format.activeFormat);

const selectFormat = (format) => store.dispatch("format/selectFormat", format);
</script>

<template>
  <div v-for="format in formatList" :key="format">
    <div
      v-if="format.format"
      :class="
        activeFormat == format.format
          ? 'bg-accentSecondary'
          : 'bg-gray-10 hover:bg-gray-100'
      "
      class="flex flex-col items-center justify-center w-24 h-24 mt-4 mr-4 border-transparent rounded-lg cursor-pointer lg:mt-0"
      @click="selectFormat(format.format)"
    >
      <p class="drop-shadow-[0_0_4px_rgba(0,0,0,1)] text-3xl">
        {{ format.emoji ? (format.emoji.length > 3 ? "" : format.emoji) : "" }}
      </p>
      <label
        class="mt-2 text-sm text-center cursor-pointer"
        :class="
          activeFormat == format.format ? 'text-primary' : 'text-secondary'
        "
      >
        {{ format.format }}
      </label>
    </div>
  </div>
</template>
