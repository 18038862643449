<script setup>
import { ref, watch, computed, onMounted, onBeforeUnmount } from "vue";
import { useStore } from "vuex";
import MainButton from "../inputs/MainButton.vue";
import DescriptionText from "./DescriptionText.vue";
import InputBox from "../inputs/InputBox.vue";
import Typewriter from "typewriter-effect/dist/core";
import { nextStep } from "@/utils/shepherd/main";

const store = useStore();

const prompt = computed(() => store.state.prompt);
const error = computed(() => store.state.promptError);

const changePrompt = (prompt) => store.dispatch("changePrompt", { prompt });
const setNewInput = (state) => store.dispatch("setNewInput", state);

const promptInput = ref(prompt.value);
const inputBox = ref(null);
var placeholder = ref("Whatever you can think of...");

// Typewriter effects
const customNodeCreator = function (character) {
  placeholder.value = placeholder.value + character;
  return null;
};

const onRemoveNode = function () {
  if (placeholder.value) {
    placeholder.value = placeholder.value.slice(0, -1);
  }
};

const typewriter = new Typewriter(null, {
  onCreateTextNode: customNodeCreator,
  onRemoveNode: onRemoveNode,
});

const startTypewriter = () => {
  placeholder.value = "";
  typewriter
    .typeString("Bedtime story for my children")
    .pauseFor(1000)
    .deleteAll(15)
    .typeString("List of dinner ideas")
    .pauseFor(1000)
    .deleteAll(15)
    .typeString("Response to my boss")
    .pauseFor(1000)
    .deleteAll(15)
    .typeString("Whatever you can think of...")
    .start();
};

onMounted(() => {
  window.addEventListener("start-typewriter", startTypewriter);
});

onBeforeUnmount(() => {
  window.removeEventListener("start-typewriter", startTypewriter);
});

// Toggle if the user is asking for a new prompt or not
watch(promptInput, () => {
  if (
    prompt.value.toLowerCase() === promptInput.value.toLowerCase() ||
    promptInput.value.length === 0
  ) {
    setNewInput(false);
  } else {
    setNewInput(true);
  }
});

// If re-loaded and prompt takes a second to load, show the prompt in the inputBox
watch(prompt, (newVal, oldVal) => {
  if (oldVal === "" && promptInput.value !== prompt.value) {
    promptInput.value = prompt.value;
  }
  if (oldVal === promptInput.value) {
    promptInput.value = prompt.value;
  }
});

const blurInput = () => {
  if (inputBox.value) {
    inputBox.value?.blur();
  }
};

const initialCheck = () => {
  nextStep("typing-complete");
  blurInput();
  changePrompt(promptInput.value);
};
</script>

<template>
  <section
    id="shepherd-target-startbar"
    class="w-full mt-6 grid lg:grid-cols-3 lg:gap-11 grid-rows-1 lg:grid-rows-none"
  >
    <InputBox
      ref="inputBox"
      id="inputBox"
      :placeholder="placeholder"
      class="px-4 py-5 lg:col-span-2"
      :error="error"
      v-model="promptInput"
      @submit="initialCheck()"
    />
    <MainButton
      id="inputBox"
      class="mt-2 lg:mt-6 mb-8 lg:mb-8 lg:-ml-2 lg:mr-3"
      hideCorner
      @clicked="initialCheck()"
    >
      <label class="uppercase cursor-pointer">Start Make</label>
      <font-awesome-icon
        class="ml-2 cursor-pointer"
        :icon="['fas', 'chevron-right']"
      />
    </MainButton>
    <DescriptionText class="block text-center lg:hidden" location="above" />
  </section>
</template>
@/utils/main
