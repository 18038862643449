<script setup>
import { ref, computed, onMounted } from "vue";
import { useStore } from "vuex";
import noUiSlider from "nouislider";

const store = useStore();

const selectedLength = computed(() => store.state.length.selectedLength);

const moveSlider = (value) => store.dispatch("length/moveSlider", value);

const range = ref();
const pipFormats = { 0: "Short", 2: "Medium", 4: "Long" };

const config = ref({
  start: selectedLength,
  connect: [true, false],
  range: {
    min: [0, 1],
    "25%": [1, 1],
    "50%": [2, 1],
    "75%": [3, 1],
    max: [4, 1],
  },
  step: 6,
  pips: {
    mode: "positions",
    values: [0, 50, 100],
    density: 24,
    format: {
      to: function (a) {
        return pipFormats[a];
      },
    },
  },
});

function noUiSliderInit() {
  const configSettings = Object.assign(config.value);
  const slider = noUiSlider.create(range.value, configSettings);
  slider.on("update", (values) => {
    moveSlider(values[0]);
  });
}

onMounted(() => {
  noUiSliderInit();
});
</script>

<template>
  <div class="mx-5">
    <div ref="range" class="range"></div>
  </div>
</template>

<style>
.range .noUi-handle {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  top: -11px;
  right: -15px;
  border: 13px solid #cf5ae2;
  cursor: pointer;
}
.noUi-horizontal {
  height: 6px;
}
.noUi-handle:before,
.noUi-handle:after {
  display: none;
}
.noUi-connect {
  background: #cf5ae2;
  cursor: pointer;
}
.noUi-connects {
  background: #f5f7fb;
  cursor: pointer;
}
.noUi-pips-horizontal {
  padding: 10px 0;
  height: 80px;
  top: -5px;
  left: 2px;
  width: 100%;
}
.noUi-value-horizontal:first-child {
  left: 2% !important;
}
.noUi-value {
  font-size: 14px;
}
@import "nouislider/dist/nouislider.css";
</style>
