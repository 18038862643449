<template>
  <svg
    width="245"
    height="238"
    viewBox="0 0 245 238"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.35"
      d="M244.5 119C244.5 184.432 189.893 237.5 122.5 237.5C55.1075 237.5 0.5 184.432 0.5 119C0.5 53.5678 55.1075 0.5 122.5 0.5C189.893 0.5 244.5 53.5678 244.5 119Z"
      stroke="#756E94"
    />
  </svg>
</template>
