export default {
  name: "context",
  namespaced: true,
  state: {
    context: "",
    changedAfterQuestionsGenerated: false,
  },
  mutations: {
    SET_CONTEXT(state, payload) {
      state.context = payload;
    },
    SET_CHANGED_AFTER_QUESTIONS_GENERATED(state, payload) {
      state.changedAfterQuestionsGenerated = payload;
    },
  },
  actions: {
    reset({ commit }) {
      commit("SET_CONTEXT", "");
      commit("SET_CHANGED_AFTER_QUESTIONS_GENERATED", false);
    },
    addContext({ commit }, context) {
      commit("SET_CHANGED_AFTER_QUESTIONS_GENERATED", true);
      commit("SET_CONTEXT", context);
    },
    resetChangedAfterQuestionsGenerated({ commit }) {
      commit("SET_CHANGED_AFTER_QUESTIONS_GENERATED", false);
    },
  },
  modules: {},
  getters: {},
};
