<script setup>
import { ref, computed, watch } from "vue";
import { useStore } from "vuex";
import stars from "@/assets/animation_stars.json";
import ObserverWrapper from "../../ObserverWrapper.vue";
import GenerateButtons from "./GenerateButtons.vue";
import ChangeResult from "./ChangeResult.vue";

const store = useStore();
defineEmits(["showGenerateButtons"]);

const generatedText = computed(() => store.state.result.generatedText);
const finishedText = computed(() => store.state.result.finishedText);
const loadingFix = computed(() => store.state.result.loadingFix);
const editMode = computed(() => store.state.result.editMode);
const loading = computed(() => store.state.result.loading);
const error = computed(() => store.state.result.error);
const vote = computed(() => store.state.result.vote);

const setVote = (vote) => store.dispatch("result/setVote", vote);
const deleteSentence = (indexArray) =>
  store.dispatch("result/deleteSentence", indexArray);
const getChange = ({
  originalText,
  textToChange,
  commentToChange,
  selectedAll,
}) =>
  store.dispatch("result/getChange", {
    originalText,
    textToChange,
    commentToChange,
    selectedAll,
  });

const textContainer = ref();
const clickedIndexes = ref([]);
const previousClickedIndexes = ref([]);

const toggleClicked = (index) => {
  if (clickedIndexes.value.includes(index)) {
    clickedIndexes.value = clickedIndexes.value.filter((i) => i !== index);
  } else {
    clickedIndexes.value = [...clickedIndexes.value, index];
  }
};

const toggleSelectAll = () => {
  if (previousClickedIndexes.value.length === 0) {
    previousClickedIndexes.value = clickedIndexes.value;
    clickedIndexes.value = [];
    finishedText.value.forEach((sentence, index) => {
      if (sentence.highlightable) {
        clickedIndexes.value.push(index);
      }
    });
  } else if (
    clickedIndexes.value.length === previousClickedIndexes.value.length
  ) {
    clickedIndexes.value = [];
  } else {
    clickedIndexes.value = previousClickedIndexes.value;
    previousClickedIndexes.value = [];
  }
};

const deleteSelected = () => {
  deleteSentence(clickedIndexes.value);
  clickedIndexes.value = [];
  previousClickedIndexes.value = [];
  store.commit("result/SET_COMMENT_TO_CHANGE", "");
};

const findLargestIndex = (arr) => {
  let largest = 0;
  arr.forEach((index) => {
    if (index > largest) {
      largest = index;
    }
  });
  return largest;
};

const mergeSelections = () => {
  let finalSentence = "";
  let merged = [];
  let justMerged = false;
  let toMerge = "";
  let possibleMerge = "";
  finishedText.value.forEach((sentence, index) => {
    if (clickedIndexes.value.includes(index)) {
      if (!justMerged) {
        finalSentence += "[[" + sentence.text;
        justMerged = true;
      } else {
        finalSentence += possibleMerge + sentence.text;
      }
      toMerge += possibleMerge + sentence.text;
      possibleMerge = "";
    } else if (!justMerged) {
      finalSentence += sentence.text;
    } else if (sentence.text === " ") {
      possibleMerge += " ";
    } else {
      merged.push(toMerge);
      finalSentence += "]]" + possibleMerge + sentence.text;
      possibleMerge = "";
      toMerge = "";
      justMerged = false;
    }
  });
  if (justMerged) {
    merged.push(toMerge);
    finalSentence += "]]";
  }
  return { originalText: finalSentence, textToChange: merged };
};

const submit = (commentToChange) => {
  if (highlightableCount.value === clickedIndexes.value.length) {
    getChange({
      originalText: generatedText.value,
      textToChange: [],
      commentToChange,
      selectedAll: true,
    });
    return;
  } else {
    const { originalText, textToChange } = mergeSelections();
    getChange({
      originalText,
      textToChange,
      commentToChange,
      selectedAll: false,
    });
  }
};

watch(loadingFix, (newLoading) => {
  if (!newLoading) {
    clickedIndexes.value = [];
    previousClickedIndexes.value = [];
    store.commit("result/SET_COMMENT_TO_CHANGE", "");
  }
});

// a computed property that returns the largest index in the clickedIndexes array
const largestIndex = computed(() => findLargestIndex(clickedIndexes.value));
// computed property that returns the number of items in finishedText that are highlightable
const highlightableCount = computed(
  () => finishedText.value.filter((sentence) => sentence.highlightable).length
);
</script>

<template>
  <section id="shepherd-target-make">
    <transition name="fade" mode="out-in" appear :duration="500">
      <ObserverWrapper
        class="mr-14"
        @hidden="$emit('showGenerateButtons', $event)"
      >
        <GenerateButtons />
      </ObserverWrapper>
    </transition>

    <div class="relative sm:mb-12 mb-28 mt-6 tabs" ref="textContainer">
      <p v-if="finishedText && editMode">
        <span v-for="(sentence, index) in finishedText" :key="sentence">
          <span
            v-if="sentence.highlightable"
            class="rounded-lg p-1 -m-1 transition-all duration-200 whitespace-pre-line cursor-pointer"
            :class="
              (!clickedIndexes.includes(index) &&
                sentence.changed &&
                loadingFix) ||
              (!clickedIndexes.includes(index) &&
                sentence.changed &&
                !loadingFix)
                ? 'bg-green-400 hover:bg-green-200 hover:bg-opacity-90'
                : clickedIndexes.includes(index)
                ? 'bg-yellow-300 hover:bg-opacity-70 bg-opacity-90'
                : 'hover:bg-yellow-300 hover:bg-opacity-90'
            "
            @click="toggleClicked(index)"
          >
            {{ sentence.text }}
          </span>
          <span v-else-if="sentence.text === ' '">{{ " " }}</span>
          <br v-else-if="sentence.text === '\n'" />
          <section v-if="clickedIndexes.length > 0 && index === largestIndex">
            <ChangeResult
              @submit="submit($event)"
              @selectAll="toggleSelectAll"
              @deleteSelected="deleteSelected"
            />
          </section>
        </span>
      </p>
      <p
        v-else-if="error"
        class="text-base text-red-500 underline text-center font-bold whitespace-pre-line"
      >
        {{ error }}
      </p>
      <p v-else class="text-base text-primary whitespace-pre-line">
        {{ generatedText }}
      </p>
      <Vue3Lottie
        v-if="loading"
        class="-mt-44 -z-50"
        :animationData="stars"
        :width="400"
        :loop="true"
        :speed="2"
        :autoPlay="true"
        direction="forward"
      />
      <div class="flex flex-col mt-2 lg:mb-[12rem] w-full" v-else-if="!error">
        <div class="flex justify-between mt-6">
          <p class="text-base font-bold text-primary">
            Was this generation good or bad?
          </p>
          <div class="flex items-center">
            <div
              class="grid grid-cols-1 mr-5 p-3 -m-3 cursor-pointer"
              :class="
                vote === 1
                  ? 'text-accentPrimary hover:text-purple-400'
                  : 'text-secondary hover:text-primary'
              "
              @click="setVote(1)"
            >
              <font-awesome-icon
                class="mx-auto text-xl"
                :icon="['fas', 'thumbs-up']"
              />
              <p class="text-xs text-center font-bold mt-0.5 whitespace-nowrap">
                Good
              </p>
            </div>
            <div
              class="grid grid-cols-1 p-3 -m-3 cursor-pointer"
              :class="
                vote === -1
                  ? 'text-accentSecondary hover:text-yellow-300'
                  : 'text-secondary hover:text-primary'
              "
              @click="setVote(-1)"
            >
              <font-awesome-icon
                class="mx-auto text-xl"
                :icon="['fas', 'thumbs-down']"
              />
              <p class="text-xs text-center font-bold mt-0.5 whitespace-nowrap">
                Bad
              </p>
            </div>
          </div>
        </div>
        <p v-if="vote === -1" class="mx-5 my-1 text-secondary italic text-sm">
          If you didn't like the result try re-generate your make or go back
          with different answers, more context or style choices
        </p>
        <p
          v-else-if="vote === 1"
          class="mx-5 my-1 text-secondary italic text-sm"
        >
          If you loved your make, use the buttons at the top to copy or share
          your make with others. You can also use it as the context for a new
          make, with the two arrow button.
        </p>
      </div>
    </div>
  </section>
</template>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
