import { createApp } from "vue";
import FontAwesomeIcon from "./fontawesome-icons";
import Vue3Lottie from "vue3-lottie";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "../src/assets/index.css";

createApp(App)
  .use(router)
  .use(store)
  .use(Vue3Lottie)
  .component("font-awesome-icon", FontAwesomeIcon)
  .mount("#app");
