<script setup>
import { ref, computed, watch, onMounted } from "vue";
import { useStore } from "vuex";
import MainButton from "../inputs/MainButton.vue";

const store = useStore();

const loading = computed(() => store.state.auth.loading);
// const loading = ref(true);
const loadingItem = computed(() => store.state.auth.loadingItem);

const googleButton = ref(null);
// const facebookButton = ref(null);
const emailButton = ref(null);

const emit = defineEmits(["goTo"]);

watch(loading, (value) => {
  setButtonLoading(value);
});

const setButtonLoading = (value) => {
  googleButton.value.setDisable(value);
  // facebookButton.value.setDisable(value);
  emailButton.value.setDisable(value);
  if (loadingItem.value === "Google") {
    googleButton.value.setLoading(value);
  }
  // if (loadingItem.value === "Facebook") {
  //   facebookButton.value.setLoading(value);
  // }
  if (value === false) {
    googleButton.value.setLoading(false);
    // facebookButton.value.setLoading(false);
  }
};

const buttonPressed = (value) => {
  if (loading.value) return;
  emit("goTo", value);
};

onMounted(() => {
  setButtonLoading(loading.value);
});
</script>

<template>
  <section>
    <div class="mt-4 grid gap-5 grid-cols-1">
      <MainButton
        ref="googleButton"
        @clicked="buttonPressed('Google')"
        class="w-full"
        :setColours="'bg-primary hover:bg-secondary'"
      >
        <!-- Google logo -->
        <div class="grid grid-cols-12 -my-0.5">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            width="30"
            height="30"
            viewBox="0 0 48 48"
            class="my-auto"
          >
            <path
              fill="#FFC107"
              d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12c0-6.627,5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24c0,11.045,8.955,20,20,20c11.045,0,20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z"
            ></path>
            <path
              fill="#FF3D00"
              d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z"
            ></path>
            <path
              fill="#4CAF50"
              d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z"
            ></path>
            <path
              fill="#1976D2"
              d="M43.611,20.083H42V20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z"
            ></path>
          </svg>
          <label
            class="text-lg my-auto col-span-10"
            :class="loading ? 'cursor-default' : 'cursor-pointer'"
            >Google Login</label
          >
        </div>
      </MainButton>
      <!-- <MainButton
        ref="facebookButton"
        @clicked="buttonPressed('Facebook')"
        class="w-full"
        :setColours="'bg-primary hover:bg-secondary'"
      >
        Facebook logo
        <div class="grid grid-cols-12 -my-1">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            width="35"
            height="35"
            viewBox="0 0 48 48"
            class="my-auto"
          >
            <path
              fill="#3f51b5"
              d="M24 4A20 20 0 1 0 24 44A20 20 0 1 0 24 4Z"
            ></path>
            <path
              fill="#fff"
              d="M29.368,24H26v12h-5V24h-3v-4h3v-2.41c0.002-3.508,1.459-5.59,5.592-5.59H30v4h-2.287 C26.104,16,26,16.6,26,17.723V20h4L29.368,24z"
            ></path>
          </svg>
          <label
            class="text-lg my-auto col-span-10"
            :class="loading ? 'cursor-default' : 'cursor-pointer'"
            >Facebook Login</label
          >
        </div>
      </MainButton> -->
      <MainButton
        ref="emailButton"
        @clicked="buttonPressed('Email')"
        class="w-full"
      >
        <div class="grid grid-cols-12">
          <font-awesome-icon
            class="ml-2 my-auto text-2xl"
            :icon="['far', 'envelope']"
          />
          <label
            class="text-lg my-auto col-span-10"
            :class="loading ? 'cursor-default' : 'cursor-pointer'"
            >Email Login</label
          >
        </div>
      </MainButton>
    </div>
  </section>
</template>
