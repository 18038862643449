import Vuex from "vuex";
import _ from "lodash";
import { v4 as uuid } from "uuid";
import pushNextPage from "@/utils/pushNextPage";
import updateCurrentPage from "@/utils/updateCurrentPage";
import fetchData from "@/utils/fetchData";
import { deleteDB } from "@/utils/manageIndexDb";
import auth from "./modules/auth";
import context from "./modules/context";
import drawer from "./modules/drawer";
import format from "./modules/format";
import language from "./modules/language";
import length from "./modules/length";
import modals from "./modules/modals";
import personalInfo from "./modules/personalInfo";
import questions from "./modules/questions";
import result from "./modules/result";
import tabs from "./modules/tabs";
import tone from "./modules/tone";
import tours from "./modules/tours";

const store = new Vuex.Store({
  modules: {
    auth,
    context,
    drawer,
    format,
    language,
    length,
    modals,
    personalInfo,
    questions,
    result,
    tabs,
    tone,
    tours,
  },
  state: {
    dbVersion: "1.2.0",
    prompt: "",
    promptError: "",
    promptHint: "",
    lastRequest: {},
    newInput: false,
    journeyId: "",
    isMobile: false,
  },
  mutations: {
    UPDATE_ENTIRE_STATE(state, { payload, dataToKeep = [] }) {
      for (let prop in payload) {
        // eslint-disable-next-line no-prototype-builtins
        if (payload.hasOwnProperty(prop)) {
          // don't replace data we want to keep
          if (!dataToKeep.includes(prop)) {
            state[prop] = payload[prop];
          }
        }
      }
    },
    SET_DB_VERSION(state, payload) {
      state.dbVersion = payload;
    },
    SET_PROMPT(state, payload) {
      // if payload starts with make me a, remove it from payload
      payload = payload.replace(
        /^(write me a|writemea|writeme|write me|write a|write)\s/i,
        ""
      );
      payload = payload.replace(
        /^(make me a|makemea|makeme|make me|make a|make)\s/i,
        ""
      );
      payload = payload.replace(
        /^(create me a|createmea|createme|create me|create a|create)\s/i,
        ""
      );
      payload = payload.replace(/^(me a)\s/i, "");
      // remove leading and trailing spaces
      payload = payload.trim();

      state.prompt = payload;
    },
    SET_PROMPT_ERROR(state, payload) {
      state.promptError = payload;
    },
    SET_PROMPT_HINT(state, payload) {
      state.promptHint = payload;
    },
    SET_LAST_REQUEST(state, payload) {
      state.lastRequest = payload;
    },
    SET_NEW_INPUT(state, payload) {
      state.newInput = payload;
    },
    SET_JOURNEY_ID(state) {
      state.journeyId = uuid();
    },
    SET_IS_MOBILE(state, payload) {
      state.isMobile = payload;
    },
  },
  actions: {
    resetLoading({ dispatch }) {
      dispatch("questions/resetLoading");
      dispatch("format/resetLoading");
      dispatch("tone/resetLoading");
      dispatch("result/resetLoading");
    },
    async checkVersion({ state }) {
      const { response, status } = await fetchData(
        "checkVersion",
        {},
        false,
        false,
        false
      );
      if (status === 201) {
        if (response.result.version !== state.dbVersion) {
          await deleteDB();
          location.reload();
        }
      }
    },
    changePromptReset({ commit, dispatch }, prompt) {
      dispatch("modals/reset");
      dispatch("context/reset");
      dispatch("questions/reset");
      dispatch("format/reset");
      dispatch("tone/reset");
      dispatch("length/reset");
      dispatch("result/reset");
      commit("SET_NEW_INPUT", false);
      commit("SET_PROMPT_ERROR", "");
      commit("SET_PROMPT_HINT", "");
      commit("SET_PROMPT", prompt);
    },
    changePrompt(
      { state, commit, dispatch, rootState },
      { prompt, force = false }
    ) {
      if (!force && prompt.length === 0) {
        commit("SET_PROMPT_ERROR", "Please enter a something to make");
        return;
      }
      if (!force && prompt.length < 2) {
        commit(
          "SET_PROMPT_ERROR",
          "Please enter something 2 characters or longer"
        );
        return;
      }
      if (!force && prompt.length > 500) {
        commit("SET_PROMPT_ERROR", "Please enter something shorter");
        return;
      }
      if (!force && prompt.toLowerCase() === state.prompt.toLowerCase()) {
        commit("SET_PROMPT_ERROR", "Please enter a different prompt");
        return;
      }
      dispatch("changePromptReset", prompt);
      pushNextPage(rootState);
      dispatch("getRefinements");
      dispatch("checkPrompt");
      dispatch("drawer/createMake");
      commit("SET_JOURNEY_ID");
    },
    pageReset({ dispatch }) {
      dispatch("changePromptReset", "");
      dispatch("tabs/reset");
      dispatch("drawer/reset");
    },
    getRefinements({ dispatch }) {
      dispatch("questions/getQuestions");
      dispatch("tone/getTones");
      dispatch("format/getFormats");
    },
    async checkPrompt({ commit }) {
      const { response } = await fetchData("generate/promptCheck", {});
      if (!response.result.possible) {
        commit("SET_PROMPT_HINT", response.result.rewording);
      }
    },
    setLastRequest({ commit }, { request, url, result, status }) {
      const payload = {
        request: {
          ...request,
          url,
          timestamp: new Date().getTime(),
        },
        response: { result, status },
      };
      commit("SET_LAST_REQUEST", payload);
      return payload;
    },
    setNewInput({ commit }, payload) {
      commit("SET_NEW_INPUT", payload);
    },
    setIsMobile({ commit, state }, payload) {
      if (state.isMobile === payload) {
        return;
      }
      commit("SET_IS_MOBILE", payload);
    },
  },
  getters: {},
});

// Create a debounced version of history.replaceState to stop too many calls
const debouncedUpdateState = _.debounce((state) => {
  updateCurrentPage(state);
}, 100);

store.subscribe((mutation, state) => {
  if (mutation.type === "UPDATE_ENTIRE_STATE") {
    return;
  }
  debouncedUpdateState(state);
});

export default store;
