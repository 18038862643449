import { useShepherd } from "vue-shepherd";
import store from "../../store/index.js";

const tour = useShepherd({
  exitOnEsc: false,
  keyboardNavigation: false,
  useModalOverlay: true,
  modalContainer: document.body,
  tourName: "styleTour",
  defaultStepOptions: {
    classes: "outline-none",
    scrollTo: { behavior: "smooth", block: "center" },
  },
});

const startStyleTour = () => {
  if (!store.state.tours.showStyleTour) return;
  if (!store.state.drawer.isOpen) return;
  if (store.state.tabs.currentTab.name !== "Style") return;
  return styleTour.bind(this)().start();
};

const styleTour = () => {
  const isMobile = store.state.isMobile;
  let action = isMobile ? "tap" : "click";
  tour.addSteps([
    {
      title: `<div class="w-full flex">
                  <p class="my-auto">Changing Styles</p>
                  <img
                    ref="genie"
                    class="w-[60px] ml-auto -my-3 -mr-2"
                    src="/genie-crossed-torso.svg"
                    alt="genie"
                  />
              </div>`,
      text: "This tab allows you to change the style, language and structure of your make.",
      buttons: [
        {
          text: "Next",
          action: function () {
            this.next();
          },
          classes: "w-full",
        },
      ],
    },
    {
      attachTo: {
        element: "#shepherd-target-tone",
        on: `${isMobile ? "bottom" : "right"}`,
      },
      title: `<div class="w-full flex">
                  <p class="my-auto">Changing Styles</p>
                  <img
                    ref="genie"
                    class="w-[60px] ml-auto -my-3 -mr-2"
                    src="/genie-crossed-torso.svg"
                    alt="genie"
                  />
              </div>`,
      text: `${
        isMobile
          ? "First tap to open a dropdown. Then, for example,"
          : "For example,"
      } you can select any tone you want your make to be written in by ${action}ing on one of these icons. <br />
      If you want to add your own, just ${action} on the <em>Add Tone</em> button`,
      arrow: true,
      buttons: [
        {
          text: "Next",
          action: function () {
            this.next();
          },
          classes: "w-full",
        },
      ],
    },
    {
      id: "final",
      title: `<div class="w-full flex">
                  <p class="my-auto">Changing Styles</p>
                  <img
                    ref="genie"
                    class="w-[60px] ml-auto -my-3 -mr-2"
                    src="/genie-crossed-torso.svg"
                    alt="genie"
                  />
              </div>`,
      text: `You don't need to specify any items you don't want, but if you need to fine-tune your make the options are here. <br />
      Don't forget, when you feel you have added all the info you want to press the Generate <svg class="svg-inline--fa fa-chevron-right h-2" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chevron-right" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path class="" fill="currentColor" d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z"></path></svg> button to see your make`,
      buttons: [
        {
          text: "Finish",
          action: function () {
            store.dispatch("tours/completeStyleTour");
            this.complete();
          },
          classes: "w-full",
        },
      ],
    },
  ]);
  return tour;
};

export { startStyleTour, tour };
