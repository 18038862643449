<script setup>
import { ref, watch, computed } from "vue";
import { useStore } from "vuex";
import MainButton from "../../inputs/MainButton.vue";
import InputBox from "../../inputs/InputBox.vue";

const store = useStore();

defineProps({
  hideUnnecessary: {
    type: Boolean,
    default: false,
  },
});

const prompt = computed(() => store.state.prompt);
const promptHint = computed(() => store.state.promptHint);
const newInput = computed(() => store.state.newInput);
const error = computed(() => store.state.promptError);
const showResult = computed(() => store.state.result.showResult);

const changePrompt = (prompt) => store.dispatch("changePrompt", { prompt });
const getResult = (regenerate) =>
  store.dispatch("result/getResult", regenerate);
const setNewInput = (state) => store.dispatch("setNewInput", state);

const promptInput = ref(prompt.value);
const inputBox = ref(null);

// Toggle if the user is asking for a new prompt or not
watch(promptInput, () => {
  if (
    prompt.value.toLowerCase() === promptInput.value.toLowerCase() ||
    promptInput.value.length === 0
  ) {
    setNewInput(false);
  } else {
    setNewInput(true);
  }
});

// If re-loaded and prompt takes a second to load, show the prompt in the inputBox
watch(prompt, (newVal, oldVal) => {
  if (oldVal === "" && promptInput.value !== prompt.value) {
    promptInput.value = prompt.value;
  }
  if (oldVal === promptInput.value) {
    promptInput.value = prompt.value;
  }
});

const blurInput = () => {
  if (inputBox.value) {
    inputBox.value?.blur();
  }
};

const newPrompt = (prompt = undefined) => {
  if (prompt) {
    promptInput.value = prompt;
  }
  blurInput();
  changePrompt(promptInput.value);
};

defineExpose({ newPrompt });
</script>

<template>
  <div class="w-full mt-6" :class="showResult ? 'lg:block hidden' : 'block'">
    <div class="grid-rows-1 grid lg:grid-cols-3">
      <InputBox
        ref="inputBox"
        class="px-4 py-5 lg:col-span-2 flex justify-between"
        placeholder="Whatever you can think of..."
        :error="error"
        v-model="promptInput"
        @submit="newPrompt"
      />

      <MainButton
        class="mt-6 mb-8 lg:block hidden"
        hideCorner
        @clicked="newPrompt"
        v-if="newInput"
      >
        <label class="uppercase cursor-pointer">re-do</label>
        <font-awesome-icon
          class="ml-2 cursor-pointer"
          :icon="['fas', 'rotate-right']"
        />
      </MainButton>
      <MainButton
        id="shepherd-target-make-redo"
        class="mt-2 lg:mt-6 mb-8 lg:mb-8 lg:block hidden"
        hideCorner
        @clicked="getResult(showResult)"
        v-else
      >
        <label class="uppercase cursor-pointer">
          {{ showResult ? "re-generate" : "generate" }}
        </label>
        <font-awesome-icon
          class="ml-2 cursor-pointer"
          :icon="
            showResult ? ['fas', 'rotate-right'] : ['fas', 'chevron-right']
          "
        />
      </MainButton>
      <p
        v-if="promptHint && !hideUnnecessary"
        class="text-sm lg:text-base -mt-4 lg:-mt-3 mb-2 lg:-mb-3 ml-6"
      >
        Or
        <b
          class="font-black text-accentPrimary rounded-md p-0.5 -m-0.5 hover:bg-gray-200 cursor-pointer"
          @click="newPrompt(promptHint)"
          >{{ promptHint }}</b
        >
        ?
      </p>
    </div>
  </div>
</template>
