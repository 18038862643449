<script setup>
import { onMounted, onUnmounted } from "vue";
import { useStore } from "vuex";
import { loadPreviousPage } from "@/utils/loadPastPages";
import { newTour } from "@/utils/shepherd/main";
import pushNextPage from "@/utils/pushNextPage";
import Hero from "../components/Hero.vue";
import MainContent from "../components/main/MainContent.vue";
import Circles from "../components/Circles.vue";

const store = useStore();

const checkVersion = () => store.dispatch("checkVersion");
const pageReset = () => store.dispatch("pageReset");
const setIsMobile = (value) => store.dispatch("setIsMobile", value);

// Start a new tour when the page is loaded, if needed
onMounted(() => {
  newTour();
});

// Check the version of the DB, if not correct, destroy the DB and reload the page
onMounted(() => {
  checkVersion();
});

// Handle browser backwards navigation
// TODO: fix forwards navigation
onMounted(() => {
  window.addEventListener("popstate", (event) => {
    if (event.state && event.state.vuexState) {
      loadPreviousPage(store, event.state.vuexState);
    } else {
      // Reset the Vuex store
      pageReset();
      pushNextPage(store.state);
    }
  });
});

let resizeHandler;

// Handle mobile view
onMounted(() => {
  resizeHandler = () => {
    setIsMobile(window.matchMedia("(max-width:1024px)").matches);
  };
  resizeHandler();
  window.addEventListener("resize", resizeHandler);
});

onUnmounted(() => {
  window.removeEventListener("resize", resizeHandler);
});
</script>

<template>
  <div class="relative">
    <p class="hidden">
      Use the box below to type simply describe what you want to Make and press
      refine for the Genie to understand and generate your idea.
    </p>
    <Circles class="z-0" />
    <Hero class="relative" @hide="pageReset" />
    <MainContent />
  </div>
</template>
@/utils/main
