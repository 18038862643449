import { watch } from "vue";
import fetchStream from "@/utils/fetchStream";

export default {
  name: "format",
  namespaced: true,
  state: {
    stopLoading: false,
    formatList: [],
    loading: false,
    currFormatStream: "",
    currFormat: "",
    activeFormat: "",
  },
  mutations: {
    SET_STOP_LOADING(state, payload) {
      state.stopLoading = payload;
    },
    SET_FORMAT_LIST(state, payload) {
      state.formatList = payload;
    },
    APPEND_FORMAT_LIST(state, payload) {
      state.formatList.push(payload);
    },
    SET_LOADING(state, payload) {
      state.loading = payload;
    },
    SET_CURRENT_FORMAT_STREAM(state, payload) {
      state.currFormatStream = payload;
    },
    APPEND_CURRENT_FORMAT_STREAM(state, payload) {
      state.currFormatStream += payload;
    },
    SET_CURRENT_FORMAT(state, payload) {
      state.currFormat = payload;
    },
    APPEND_CURRENT_FORMAT(state, payload) {
      state.currFormat += payload;
    },
    SET_ACTIVE_FORMAT(state, payload) {
      state.activeFormat = payload;
    },
  },
  actions: {
    reset({ commit }) {
      return new Promise((resolve) => {
        commit("SET_FORMAT_LIST", []);
        commit("SET_CURRENT_FORMAT_STREAM", "");
        commit("SET_CURRENT_FORMAT", "");
        commit("SET_ACTIVE_FORMAT", "");
        commit("SET_LOADING", false);
        commit("SET_STOP_LOADING", true);
        resolve();
      });
    },
    resetLoading({ commit, state, dispatch }) {
      if (state.loading) {
        commit("SET_LOADING", false);
        dispatch("getFormats");
      }
    },
    addFormats({ commit, state }, text) {
      if (text === undefined) return;
      commit("APPEND_CURRENT_FORMAT_STREAM", text);

      if (state.currFormatStream === text) {
        const first = text.indexOf("[");
        if (first === -1) {
          commit("SET_CURRENT_FORMAT_STREAM", "");
        } else {
          commit("SET_CURRENT_FORMAT_STREAM", text.substring(first));
        }
      } else if (state.currFormat !== "" || text.indexOf("{") !== -1) {
        if (text.indexOf("}") === -1) {
          commit("APPEND_CURRENT_FORMAT", text);
        } else {
          commit("APPEND_CURRENT_FORMAT", "}");
          let json = {};
          try {
            json = JSON.parse(state.currFormat);
          } catch (error) {
            try {
              let currFormatSquareBr = state.currFormat.substring(
                0,
                state.currFormat.lastIndexOf("]") + 1
              );
              json = JSON.parse(currFormatSquareBr);
            } catch (error) {
              try {
                let currFormatObjBr =
                  state.currFormat.substring(0, state.currFormat.length - 1) +
                  '"}';
                json = JSON.parse(currFormatObjBr);
              } catch (error) {
                console.error("error: ", error);
              }
            }
          }
          try {
            const tempList = state.formatList;
            tempList.push(json);
            JSON.stringify(tempList);
            commit("SET_FORMAT_LIST", tempList);
          } finally {
            commit("SET_CURRENT_FORMAT", "");
          }
        }
      }
    },
    waitForFinishLoading({ state }) {
      return new Promise((resolve) => {
        if (!state.loading) {
          return resolve();
        }
        const intervalId = setInterval(() => {
          if (!state.loading) {
            clearInterval(intervalId);
            resolve();
          }
        }, 50);
      });
    },
    async getFormats({ dispatch, commit, state }, { retries = 0 } = {}) {
      let cached = false;
      await dispatch("waitForFinishLoading");
      await dispatch("reset");
      commit("SET_LOADING", true);
      commit("SET_STOP_LOADING", false);
      try {
        const { reader, status } = await fetchStream("generate/formats", {});
        while (status === 200) {
          const unwatch = watch(
            () => state.stopLoading,
            async (newValue) => {
              if (newValue) {
                await reader.cancel(); // Cancel the reader
                dispatch("reset");
                unwatch(); // Stop watching when stopLoading becomes true
                return;
              }
            },
            { immediate: true }
          );
          const { value, done } = await reader.read();
          if (done && cached) {
            commit(
              "SET_CURRENT_FORMAT_STREAM",
              state.currFormatStream.substring(
                0,
                state.currFormatStream.lastIndexOf("]") + 1
              )
            );
            commit("SET_FORMAT_LIST", JSON.parse(state.currFormatStream));
            commit("SET_LOADING", false);
            break;
          } else if (done) {
            commit("SET_LOADING", false);
            break;
          }
          const arr = value.split("\n");
          arr.forEach((data) => {
            if (data.length === 0) return; // ignore empty message
            const json = JSON.parse(data.substring(6));
            if (json.cached) {
              cached = true;
              commit("APPEND_CURRENT_FORMAT_STREAM", json.cached);
            } else {
              if (json.text === undefined) return;
              let text = json.text;
              // remove \n from text
              text = text.replace(/(\r\n|\n|\r)/gm, "");
              dispatch("addFormats", text);
            }
          });
        }
        if (status === 401) {
          dispatch("modals/openAuth", null, { root: true });
        } else if (status !== 200) {
          throw new TypeError("Internal Server Error");
        }
      } catch (error) {
        if (error instanceof TypeError && retries < 3) {
          console.log("Retrying Fs x", retries);
          setTimeout(() => {
            commit("SET_LOADING", false);
            dispatch("getFormats", {
              retries: retries + 1,
            });
          }, 500 * retries + 500);
        } else {
          console.error(error);
          commit("SET_LOADING", false);
        }
      }
    },
    addFormat({ commit }, format) {
      commit("APPEND_FORMAT_LIST", format);
    },
    selectFormat({ commit, state }, format) {
      if (state.activeFormat === format) {
        commit("SET_ACTIVE_FORMAT", "");
        return;
      }
      commit("SET_ACTIVE_FORMAT", format);
    },
  },
  getters: {},
  modules: {},
};
