<script setup>
import { ref, watch, computed } from "vue";
import { useStore } from "vuex";
import ItemAccordion from "../ItemAccordion.vue";
import QuestionInput from "./QuestionInput.vue";
import stars from "@/assets/animation_stars.json";
import MainButton from "../../inputs/MainButton.vue";

const store = useStore();

const prompt = computed(() => store.state.prompt);
const questionList = computed(() => store.state.questions.questionList);
const loading = computed(() => store.state.questions.loading);
const contextQuestionLoading = computed(
  () => store.state.questions.contextQuestionLoading
);
const chosenAnswers = computed(() => store.state.questions.chosenAnswers);
const activeEdit = computed(() => store.state.questions.activeEdit);
const isMobile = computed(() => store.state.isMobile);
const currentContext = computed(() => store.state.context.context);
const contextQuestionList = computed(
  () => store.state.questions.contextQuestionList
);
const changedAfterQuestionsGenerated = computed(
  () => store.state.context.changedAfterQuestionsGenerated
);

const toggleSelect = (selected) =>
  store.dispatch("questions/toggleSelect", selected);
const addAnswer = (answer) => store.dispatch("questions/addAnswer", answer);
const addContextAnswer = (answer) =>
  store.dispatch("questions/addContextAnswer", answer);
const changeAnswer = (answer) =>
  store.dispatch("questions/changeAnswer", answer);
const changeContextAnswer = (answer) =>
  store.dispatch("questions/changeContextAnswer", answer);
// const pageReset = () => store.dispatch("pageReset");
const addContextQuestions = (replaceQuestions) =>
  store.dispatch("questions/addContextQuestions", replaceQuestions);
const changePrompt = (prompt) =>
  store.dispatch("changePrompt", { prompt, force: true });

const editModel = ref("");

watch(activeEdit, (value) => {
  editModel.value = value;
});
</script>

<template>
  <div class="col-span-2">
    <p v-if="!isMobile" class="text-sm italic text-secondary mb-6">
      Below are some question to help refine your make. Answer as many or as few
      as you like. You can write add multiple answers, even write your own.
      <br />
      If you don't like a question and want to replace it, simply press the x to
      the left of its name. When you are done, explore the other tabs or press
      generate.
    </p>
    <div
      v-if="loading"
      class="flex items-center w-full px-3 py-3 mb-1 bg-gray-200 text-base lg:text-lg font-bold rounded-xl text-primary"
    >
      <p v-if="loading" class="w-full text-center">
        Loading questions to help refine your make...
      </p>
    </div>
    <div
      v-else-if="questionList.length === 0"
      class="flex items-center w-full px-3 py-3 bg-red-500 text-base lg:text-lg font-bold rounded-xl text-white cursor-pointer"
      @click="changePrompt(prompt)"
    >
      <p class="w-full text-center">An error has occurred, click to restart</p>
    </div>
    <div
      v-else-if="currentContext.length > 5"
      class="border-2 rounded-lg border-accentSecondary mb-2 w-full p-2"
    >
      <div v-if="!contextQuestionLoading" class="flex justify-center">
        <MainButton
          v-if="contextQuestionList.length === 0"
          setPadding="sm:px-12 sm:py-3 px-3 py-2"
          class="m-2"
          @clicked="addContextQuestions({ replaceQuestions: false })"
        >
          <label class="uppercase cursor-pointer text-sm sm:text-base"
            >Generate questions from your context</label
          >
          <font-awesome-icon
            class="ml-2 cursor-pointer"
            :icon="['fas', 'list-check']"
          />
        </MainButton>
        <MainButton
          v-else-if="changedAfterQuestionsGenerated"
          setPadding="sm:px-12 sm:py-4 px-3 py-2"
          class="m-2"
          @clicked="addContextQuestions({ replaceQuestions: true })"
        >
          <label class="uppercase cursor-pointer text-sm sm:text-base"
            >Refresh un-answered questions from your new context</label
          >
          <font-awesome-icon
            class="ml-2 cursor-pointer"
            :icon="['fas', 'rotate-right']"
          />
        </MainButton>
      </div>
      <p
        v-if="contextQuestionList.length > 0"
        class="text-xs text-secondary italic my-1"
      >
        Questions related to your entered context:
      </p>
      <ItemAccordion
        v-for="(que, index) in contextQuestionList"
        :key="index"
        class="mb-6 z-10 relative"
        :isSelected="
          chosenAnswers.find((question) => question.question.id === que.id) &&
          chosenAnswers.find((question) => question.question.id === que.id)
            .options.length > 0
        "
        :itemId="que.id"
      >
        <template v-slot:title>
          <span>{{ que.question }}</span>
        </template>
        <template v-slot:content>
          <div class="my-5">
            <QuestionInput
              v-model="que.option"
              :question="que"
              :focus="false"
              @addOption="addContextAnswer"
            />
            <div
              v-for="(suggestedAnswer, indx) in que.suggestedAnswers"
              :key="indx"
            >
              <template v-if="activeEdit === suggestedAnswer">
                <QuestionInput
                  v-model="editModel"
                  :question="que"
                  :focus="true"
                  @addOption="changeContextAnswer"
                  @exit="editModel = ''"
                />
              </template>

              <template v-else>
                <div
                  :class="
                    chosenAnswers.find(
                      (question) => question.question.id === que.id
                    ) &&
                    chosenAnswers
                      .find((question) => question.question.id === que.id)
                      .options.includes(suggestedAnswer)
                      ? 'bg-accentSecondary'
                      : 'bg-gray-10 hover:bg-gray-200'
                  "
                  class="relative w-full px-4 py-4 mb-4 flex justify-between items-center lg:text-lg text-sm border-transparent rounded cursor-pointer text-primary"
                  @click="
                    toggleSelect({
                      option: suggestedAnswer,
                      question: que,
                    })
                  "
                >
                  {{ suggestedAnswer }}
                  <div
                    v-if="
                      chosenAnswers.find(
                        (question) => question.question.id === que.id
                      ) &&
                      chosenAnswers
                        .find((question) => question.question.id === que.id)
                        .options.includes(suggestedAnswer) &&
                      activeEdit === suggestedAnswer
                    "
                    class="flex"
                  >
                    <font-awesome-icon
                      class="mr-2 text-xl text-green-500 cursor-pointer p-3 -m-3"
                      :icon="['fas', 'circle-check']"
                    />
                    <font-awesome-icon
                      class="text-xl cursor-pointer text-accentPrimary p-3 -m-3"
                      :icon="['fas', 'circle-xmark']"
                    />
                  </div>
                  <div
                    v-else-if="
                      chosenAnswers.find(
                        (question) => question.question.id === que.id
                      ) &&
                      chosenAnswers
                        .find((question) => question.question.id === que.id)
                        .options.includes(suggestedAnswer)
                    "
                    class="flex"
                  >
                    <font-awesome-icon
                      class="text-primary text-xl cursor-pointer p-4 -m-4"
                      :icon="['fas', 'pencil']"
                      @click.stop="
                        store.commit(
                          'questions/SET_ACTIVE_EDIT',
                          suggestedAnswer
                        )
                      "
                    />
                  </div>
                  <!-- Corner cutout -->
                  <div
                    class="absolute top-0 right-0 w-0 h-0 border-t-[20px] border-t-white border-l-transparent border-l-[20px]"
                  ></div>
                </div>
              </template>
            </div>
          </div>
        </template>
      </ItemAccordion>

      <div v-if="contextQuestionLoading" class="-mt-32 -mb-5">
        <Vue3Lottie
          :animationData="stars"
          :height="300"
          :loop="true"
          :speed="2"
          :autoPlay="true"
          direction="forward"
        />
      </div>
    </div>
    <section id="shepherd-target-questions">
      <ItemAccordion
        v-for="(que, index) in questionList"
        :key="index"
        class="mb-6 z-10 relative"
        :isSelected="
          chosenAnswers.find((question) => question.question.id === que.id) &&
          chosenAnswers.find((question) => question.question.id === que.id)
            .options.length > 0
        "
        :itemId="que.id"
        :closeable="true"
      >
        <template v-slot:title>
          <span>{{ que.question }}</span>
        </template>
        <template v-slot:content>
          <div class="my-5">
            <QuestionInput
              v-model="que.option"
              :question="que"
              :focus="false"
              @addOption="addAnswer"
            />
            <div
              v-for="(suggestedAnswer, indx) in que.suggestedAnswers"
              :key="indx"
            >
              <template v-if="activeEdit === suggestedAnswer">
                <QuestionInput
                  v-model="editModel"
                  :question="que"
                  :focus="true"
                  @addOption="changeAnswer"
                  @exit="editModel = ''"
                />
              </template>

              <template v-else>
                <div
                  :class="
                    chosenAnswers.find(
                      (question) => question.question.id === que.id
                    ) &&
                    chosenAnswers
                      .find((question) => question.question.id === que.id)
                      .options.includes(suggestedAnswer)
                      ? 'bg-accentSecondary'
                      : 'bg-gray-10 hover:bg-gray-200'
                  "
                  class="relative w-full px-4 py-4 mb-4 flex justify-between items-center lg:text-lg text-sm border-transparent rounded cursor-pointer text-primary"
                  @click="
                    toggleSelect({
                      option: suggestedAnswer,
                      question: que,
                    })
                  "
                >
                  {{ suggestedAnswer }}
                  <div
                    v-if="
                      chosenAnswers.find(
                        (question) => question.question.id === que.id
                      ) &&
                      chosenAnswers
                        .find((question) => question.question.id === que.id)
                        .options.includes(suggestedAnswer) &&
                      activeEdit === suggestedAnswer
                    "
                    class="flex"
                  >
                    <font-awesome-icon
                      class="mr-2 text-xl text-green-500 cursor-pointer p-3 -m-3"
                      :icon="['fas', 'circle-check']"
                    />
                    <font-awesome-icon
                      class="text-xl cursor-pointer text-accentPrimary p-3 -m-3"
                      :icon="['fas', 'circle-xmark']"
                    />
                  </div>
                  <div
                    v-else-if="
                      chosenAnswers.find(
                        (question) => question.question.id === que.id
                      ) &&
                      chosenAnswers
                        .find((question) => question.question.id === que.id)
                        .options.includes(suggestedAnswer)
                    "
                    class="flex"
                  >
                    <font-awesome-icon
                      class="text-primary text-xl cursor-pointer p-4 -m-4"
                      :icon="['fas', 'pencil']"
                      @click.stop="
                        store.commit(
                          'questions/SET_ACTIVE_EDIT',
                          suggestedAnswer
                        )
                      "
                    />
                  </div>
                  <!-- Corner cutout -->
                  <div
                    class="absolute top-0 right-0 w-0 h-0 border-t-[20px] border-t-white border-l-transparent border-l-[20px]"
                  ></div>
                </div>
              </template>
            </div>
          </div>
        </template>
      </ItemAccordion>
    </section>
    <Vue3Lottie
      v-if="loading"
      class="-mt-24"
      :animationData="stars"
      :width="400"
      :loop="true"
      :speed="2"
      :autoPlay="true"
      direction="forward"
    />
  </div>
</template>
