<template>
  <svg
    width="479"
    height="479"
    viewBox="0 0 479 479"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.35"
      d="M478.145 239.32C478.145 371.217 371.22 478.14 239.322 478.14C107.424 478.14 0.5 371.217 0.5 239.32C0.5 107.423 107.424 0.5 239.322 0.5C371.22 0.5 478.145 107.423 478.145 239.32Z"
      stroke="#756E94"
    />
    <rect
      width="38.1272"
      height="38.3"
      rx="9.68454"
      transform="matrix(0.205577 0.978641 -0.978481 0.20634 62.4766 75)"
      fill="#231F42"
    />
    <path
      opacity="0.2"
      d="M55.2123 102.512L52.5996 90.0743L58.8457 88.7571C62.2951 88.0297 65.6767 90.2245 66.3982 93.6589C67.1196 97.0933 64.9078 100.468 61.4584 101.195L55.2123 102.512Z"
      fill="#F5732D"
    />
    <path
      opacity="0.2"
      d="M28.9216 101.558C28.2001 98.1239 30.4119 94.7496 33.8613 94.0222L40.1074 92.7051L42.7202 105.143L36.4741 106.46C33.0239 107.188 29.6432 104.994 28.9216 101.558Z"
      fill="#F5732D"
    />
    <path
      opacity="0.2"
      d="M52.5991 90.0741L40.1068 92.7084L38.8005 86.4895C38.079 83.0551 40.2908 79.6808 43.7402 78.9534C47.1896 78.226 50.5713 80.4208 51.2927 83.8552L52.5991 90.0741Z"
      fill="#F5732D"
    />
    <path
      opacity="0.2"
      d="M51.5795 116.271C48.1301 116.998 44.7485 114.803 44.027 111.369L42.7207 105.15L55.2129 102.516L56.5193 108.735C57.2409 112.17 55.0289 115.543 51.5795 116.271Z"
      fill="#F5732D"
    />
  </svg>
</template>

<style scoped>
svg {
  animation: rotate-animation 25s infinite linear;
}
@keyframes rotate-animation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
