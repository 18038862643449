<template>
  <svg
    width="1023"
    height="1023"
    viewBox="0 0 1023 1023"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.35"
      d="M1022.36 511.43C1022.36 793.609 793.607 1022.36 511.429 1022.36C229.251 1022.36 0.5 793.609 0.5 511.43C0.5 229.251 229.251 0.5 511.429 0.5C793.607 0.5 1022.36 229.251 1022.36 511.43Z"
      stroke="#756E94"
    />
    <rect
      width="29.4134"
      height="29.5772"
      rx="7.47503"
      transform="matrix(0.939884 -0.341493 0.340713 0.940167 24 300.045)"
      fill="#231F42"
    />
    <path
      opacity="0.2"
      d="M45.7912 302.619L36.576 305.968L34.8964 301.333C33.9688 298.773 35.2799 295.949 37.8244 295.024C40.369 294.1 43.1841 295.425 44.1116 297.985L45.7912 302.619Z"
      fill="#F5732D"
    />
    <path
      opacity="0.2"
      d="M47.9024 322.832C45.3578 323.756 42.5427 322.431 41.6151 319.871L39.9355 315.236L49.1508 311.888L50.8304 316.523C51.7581 319.083 50.4475 321.907 47.9024 322.832Z"
      fill="#F5732D"
    />
    <path
      opacity="0.2"
      d="M36.5688 305.967L39.9279 315.237L35.3203 316.911C32.7758 317.835 29.9607 316.51 29.0331 313.95C28.1056 311.391 29.4166 308.566 31.9611 307.641L36.5688 305.967Z"
      fill="#F5732D"
    />
    <path
      opacity="0.2"
      d="M56.6839 303.905C57.6115 306.464 56.3004 309.289 53.7559 310.213L49.1482 311.888L45.7891 302.618L50.3967 300.944C52.9418 300.019 55.7563 301.345 56.6839 303.905Z"
      fill="#F5732D"
    />
  </svg>
</template>

<style scoped>
svg {
  animation: rotate-animation 25s infinite linear;
}
@keyframes rotate-animation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
