import { useShepherd } from "vue-shepherd";
import store from "../../store/index.js";
import { newTour } from "./main.js";

const tour = useShepherd({
  exitOnEsc: false,
  keyboardNavigation: false,
  useModalOverlay: true,
  modalContainer: document.body,
  tourName: "initialTour",
  defaultStepOptions: {
    classes: "outline-none",
    scrollTo: { behavior: "smooth", block: "center" },
  },
});

const startInitialTour = () => {
  if (!store.state.tours.showInitialTour) return;
  if (store.state.drawer.isOpen) return;
  return initialTour.bind(this)().start();
};

const initialTour = () => {
  let action = store.state.isMobile ? "tap" : "click";
  tour.addSteps([
    {
      title: `<div class="w-full flex">
                  <p class="my-auto">Getting Started</p>
                  <img
                    ref="genie"
                    class="w-[60px] ml-auto -my-3 -mr-2"
                    src="/genie-crossed-torso.svg"
                    alt="genie"
                  />
              </div>`,
      text: "Welcome to to MakeMeA, do you want a quick tour?",
      buttons: [
        {
          text: "No",
          action: function () {
            store.dispatch("tours/cancelTours");
            return this.cancel();
          },
          classes: "shepherd-button-secondary w-full ",
        },
        {
          text: "Yes",
          action: function () {
            return this.next();
          },
          classes: "w-full",
        },
      ],
    },
    {
      id: "typing",
      attachTo: {
        element: "#shepherd-target-startbar",
        on: "top",
      },
      title: `<div class="w-full flex">
                  <p class="my-auto">Getting Started</p>
                  <img
                    ref="genie"
                    class="w-[60px] ml-auto -my-3 -mr-2"
                    src="/genie-crossed-torso.svg"
                    alt="genie"
                  />
              </div>`,
      text: `Add whatever you want to make in this box and simply ${action} to start`,
      arrow: true,
      beforeShowPromise: function () {
        return new Promise((resolve) => {
          window.dispatchEvent(new Event("start-typewriter"));
          resolve();
        });
      },
    },
    {
      id: "final",
      attachTo: {
        element: "#shepherd-target-tabs",
        on: "bottom-left",
      },
      title: `<div class="w-full flex">
                  <p class="my-auto">Getting Started</p>
                  <img
                    ref="genie"
                    class="w-[60px] ml-auto -my-3 -mr-2"
                    src="/genie-crossed-torso.svg"
                    alt="genie"
                  />
              </div>`,
      text: `<em class="capitalize not-italic">${action}</em> here to select different tabs, you can add information to multiple tabs for each make`,
      arrow: true,
      buttons: [
        {
          text: "Next",
          action: function () {
            store.dispatch("tours/completeInitialTour");
            this.complete();
            newTour();
          },
          classes: "w-full",
        },
      ],
    },
  ]);
  return tour;
};

export { startInitialTour, tour };
