<script setup>
import { ref, computed } from "vue";
import { useStore } from "vuex";
import Tabs from "./Tabs.vue";
import Questions from "./questions/Questions.vue";
import Styles from "./style/Styles.vue";
import Context from "./context/Context.vue";
import GeneratedResult from "./result/GeneratedResult.vue";

const store = useStore();
defineEmits(["showGenerateButtons"]);

const isMobile = computed(() => store.state.isMobile);
const isOpen = computed(() => store.state.drawer.isOpen);
const tabList = computed(() => store.state.tabs.tabList);
const currentTab = computed(() => store.state.tabs.currentTab);
const showResult = computed(() => store.state.result.showResult);

const setDefaultTab = () => store.dispatch("tabs/setDefaultTab");
const setTab = (tab) => store.dispatch("tabs/setTab", tab);

const genRes = ref(null);

setDefaultTab();
const currentTabComponent = computed(() => {
  switch (currentTab.value.componentName) {
    case "Context":
      return Context;
    case "Questions":
      return Questions;
    case "Styles":
      return Styles;
    default:
      return Questions;
  }
});
</script>

<template>
  <!--Tabs Desktop-->
  <div
    v-if="isOpen && !isMobile"
    class="hidden grid-cols-3 mt-5 lg:grid gap-11"
  >
    <div
      class="col-span-2 overflow-y-auto flex flex-col overflow-x-hidden tabs"
    >
      <Tabs
        ref="tabs"
        :tab-list="tabList"
        :current-tab="currentTab"
        @change="setTab"
      />
      <div>
        <transition name="fade" mode="out-in" appear :duration="500">
          <component class="mt-6" :is="currentTabComponent" />
        </transition>
      </div>
    </div>
    <div v-if="showResult">
      <GeneratedResult ref="genRes" />
    </div>

    <div v-else class="flex justify-center">
      <img class="h-[266px] animate-fade_in_genie" src="/genie-crossed.svg" />
    </div>
  </div>

  <!--Tabs Mobile-->
  <div v-if="isOpen && isMobile" class="block lg:hidden">
    <div v-if="showResult">
      <GeneratedResult
        ref="genRes"
        @showGenerateButtons="$emit('showGenerateButtons', $event)"
      />
    </div>
    <div v-else class="relative h-[calc(80vh-120px)] tabs">
      <component class="mb-56" :is="currentTabComponent" />
    </div>
  </div>
</template>
