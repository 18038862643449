import { initializeDB } from "../../utils/manageIndexDb";
import { getLastId } from "@/utils/findPage";
import { newTour } from "@/utils/shepherd/main";

export default {
  name: "drawer",
  namespaced: true,
  state: {
    isOpen: false,
    openPurpose: "",
    lastPage: null,
  },
  mutations: {
    SET_IS_OPEN(state, payload) {
      state.isOpen = payload;
    },
    SET_OPEN_PURPOSE(state, payload) {
      state.openPurpose = payload;
    },
    SET_LAST_PAGE(state, payload) {
      state.lastPage = payload;
    },
  },
  actions: {
    reset({ commit }) {
      commit("SET_IS_OPEN", false);
      commit("SET_OPEN_PURPOSE", "");
    },
    openDrawer({ commit }) {
      commit("SET_IS_OPEN", true);
    },
    createMake({ commit }) {
      commit("SET_IS_OPEN", true);
      commit("SET_OPEN_PURPOSE", "createMake");
    },
    setInfo({ commit }) {
      commit("SET_IS_OPEN", true);
      commit("SET_OPEN_PURPOSE", "setInfo");
      newTour();
    },
    async setLastPage({ commit }, db = null) {
      if (db === null) {
        db = initializeDB();
      }
      const val = await getLastId(db);
      commit("SET_LAST_PAGE", val);
    },
  },
  modules: {},
  getters: {},
};
