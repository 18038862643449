<script setup>
import { ref, watch, computed } from "vue";
import { useStore } from "vuex";
import pushNextPage from "@/utils/pushNextPage";

const store = useStore();

const isOpen = computed(() => store.state.drawer.isOpen);

const pageReset = () => store.dispatch("pageReset");

const letters = [
  "Email",
  "Survey",
  "Pop Song",
  "Backstory",
  "Travel Plan",
  "Kid's Game",
  "Film Idea",
  "Sales Pitch",
  "Email",
];
const genie = ref();

const beforePageReset = () => {
  pageReset();
  pushNextPage(store.state);
};

watch(isOpen, (newVal) => {
  if (newVal) {
    genie.value?.classList?.add("animate-fade_out_genie");
    setTimeout(() => {
      genie.value?.classList?.remove("animate-fade_out_genie");
    }, 10000);
  } else {
    genie.value?.classList?.add("animate-fade_in_genie");
    setTimeout(() => {
      genie.value?.classList?.remove("animate-fade_in_genie");
    }, 10000);
  }
});
</script>

<template>
  <div class="flex flex-col px-4 md:px-16">
    <div class="flex items-center justify-between py-6 md:py-14">
      <img
        src="/logo-letters.svg"
        alt=""
        class="hidden logo-overlap:block p-5 -m-5 cursor-pointer z-30 relative"
        @click="beforePageReset"
      />
      <img
        src="/logo.svg"
        alt=""
        class="logo-overlap:hidden -my-3 -mx-1 cursor-pointer z-30 relative h-14"
        @click="beforePageReset"
      />
    </div>
    <div class="flex flex-col justify-between mt-8 lg:flex-row">
      <div>
        <h2 class="text-white text-[60px] lg:text-[126px] font-medium">
          Make Me A...
        </h2>
        <div class="overflow-hidden h-[50px] lg:h-[128px] scroll-container">
          <h3
            v-for="(i, index) in letters"
            :key="index"
            :class="
              index % 2 !== 0 ? 'text-accentPrimary' : 'text-accentSecondary'
            "
            class="text-[50px] animate-spin_words lg:text-[126px] leading-none font-medium block h-full"
          >
            {{ i }}
          </h3>
        </div>
      </div>
      <div class="z-10 flex justify-center lg:block">
        <img
          ref="genie"
          class="mt-24 lg:mt-8 h-56 lg:h-[300px]"
          src="/genie-gift.svg"
          alt="genie"
        />
      </div>
    </div>
  </div>
</template>
