import store from "@/store";
const setUserID = () => store.dispatch("auth/setUserID");
const setLastRequest = ({ request, url, result, status }) =>
  store.dispatch("setLastRequest", { request, url, result, status });

const fetchStream = async (
  endpoint,
  body,
  addPrompt = true,
  addJourneyId = true,
  addUserId = true
) => {
  if (addPrompt) {
    body.prompt = store.state.prompt;
  }
  if (addJourneyId) {
    body.journeyId = store.state.journeyId;
  }
  if (addUserId) {
    await setUserID();
    body.userId = store.state.auth.userId;
  }
  const url = `${process.env.VUE_APP_APIURL}/api/v1/${endpoint}`;
  let request = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
    credentials: "include",
  };
  const response = await fetch(url, request);

  const reader = response.body
    // eslint-disable-next-line no-undef
    ?.pipeThrough(new TextDecoderStream())
    .getReader();

  setLastRequest({
    request,
    url,
    result: response.body,
    status: response.status,
  });

  return { reader, status: response.status };
};

export default fetchStream;
