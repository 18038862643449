import _ from "lodash";

const initializeDB = () => {
  return new Promise((resolve, reject) => {
    let db;
    const request = indexedDB.open("MakeMeA_DB", 1);

    request.onerror = function () {
      reject("IndexedDB access required to function properly");
    };

    request.onsuccess = function (event) {
      db = event.target.result;
      resolve(db);
    };

    request.onupgradeneeded = function (event) {
      db = event.target.result;

      if (!db.objectStoreNames.contains("states")) {
        // Create an objectStore to hold information about our states. We're
        // going to use "id" as our key path because it's guaranteed to be
        // unique.
        db.createObjectStore("states", { keyPath: "id" });
      }
    };
  });
};

const prepareStateForStorage = (state) => {
  // remove lastRequest from state
  delete state.lastRequest;
  // Create a deep copy of the state
  let stateCopy = _.cloneDeep(state);
  // if browser is firefox, return
  if (navigator.userAgent.indexOf("Firefox") !== -1) {
    return stateCopy;
  }

  stateCopy = removeNetworkInfo(stateCopy);
  function removeNetworkInfo(obj) {
    try {
      for (let key in obj) {
        if (obj[key] instanceof NetworkInformation) {
          delete obj[key];
        } else if (typeof obj[key] === "object" && obj[key] !== null) {
          removeNetworkInfo(obj[key]);
        }
      }
    } catch {
      // Do nothing
    }
    return stateCopy;
  }
  return stateCopy;
};

const deleteDB = async () => {
  return new Promise((resolve, reject) => {
    const request = indexedDB.deleteDatabase("MakeMeA_DB");

    request.onsuccess = function () {
      resolve();
    };

    request.onerror = function () {
      reject(request.error);
    };
  });
};

export { initializeDB, prepareStateForStorage, deleteDB };
