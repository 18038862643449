<script setup>
import { ref, computed } from "vue";
import { useStore } from "vuex";
import { onClickOutside } from "@vueuse/core";
import AuthEmail from "../auth/AuthEmail.vue";
import AuthButtons from "../auth/AuthButtons.vue";

const store = useStore();

const authOpen = computed(() => store.state.modals.authOpen);

const closeAuth = (force = false) => {
  goBack();
  store.dispatch("modals/closeAuth", force);
};

const target = ref(null);
const showEmailLogin = ref(false);

onClickOutside(target, () => closeAuth());

const goTo = async (provider) => {
  if (provider === "Email") {
    showEmailLogin.value = true;
  } else {
    window.location.href = await store.dispatch(
      "auth/startFederatedLogin",
      provider
    );
  }
};

const goBack = () => {
  showEmailLogin.value = false;
};
</script>

<template>
  <section
    v-if="authOpen"
    class="z-50 fixed top-0 left-0 right-0 bottom-0 flex items-center w-full h-full p-4 bg-black bg-opacity-50 overflow-y-auto"
  >
    <div ref="target" class="max-w-xl w-full mx-auto p-6 bg-white rounded-xl">
      <font-awesome-icon
        icon="circle-xmark"
        class="text-gray-800 h-6 float-right hover:text-red-600 cursor-pointer p-3 -m-3"
        @click="closeAuth(true)"
      />
      <div class="flex items-center mb-6">
        <div
          class="flex items-center justify-center h-14 w-14 bg-gray-100 px-4 rounded-xl mr-4"
        >
          <font-awesome-icon icon="user-plus" class="text-accentPrimary h-8" />
        </div>
        <div class="w-full">
          <h4 class="text-xl text-black font-semibold">
            SignUp/Login for MakeMeA
          </h4>
          <span class="text-sm text-gray-800 font-semibold"
            >Signup or login with either method below to generate your first
            make with MakeMeA.
          </span>
        </div>
      </div>
      <AuthButtons v-if="!showEmailLogin" @goTo="goTo($event)" />
      <div v-else>
        <div
          class="flex text-accentPrimary hover:underline cursor-pointer mb-5"
          @click="goBack"
        >
          <font-awesome-icon
            class="mr-1 cursor-pointer rotate-180 my-auto"
            :icon="['fas', 'chevron-right']"
          />
          <p class="font-bold my-auto">Back</p>
        </div>
        <AuthEmail />
      </div>
    </div>
  </section>
</template>
