<script setup>
import { ref, computed } from "vue";
import { useStore } from "vuex";
import { onClickOutside } from "@vueuse/core";
import MainButton from "../inputs/MainButton.vue";
import InlineInput from "../inputs/InlineInput.vue";

const store = useStore();

const newInfoCategoryOpen = computed(
  () => store.state.modals.newInfoCategoryOpen
);

const closeNewInfoCategory = () =>
  store.dispatch("modals/closeNewInfoCategory");
const addCategory = (category) =>
  store.dispatch("personalInfo/addCategory", category);

const target = ref(null);
const error = ref("");
const loading = ref(false);
const button = ref(null);
const name = ref("");
const answer = ref("");

onClickOutside(target, () => close());

const submit = () => {
  if (name.value.length === 0) {
    error.value = "Please enter something to make";
    return;
  }
  if (name.value.length < 4) {
    error.value = "Please enter something longer";
    return;
  }
  if (name.value.length > 60) {
    error.value = "Please enter something shorter";
    return;
  }
  button.value.setLoading(true);
  loading.value = true;
  addCategory({ name: name.value, answer: answer.value });
  close();
  button.value.setLoading.value = false;
  loading.value = false;
};

const close = () => {
  error.value = "";
  closeNewInfoCategory();
};
</script>

<template>
  <section
    v-if="newInfoCategoryOpen"
    class="z-50 fixed top-0 left-0 right-0 bottom-0 flex items-center w-full h-full p-4 bg-black bg-opacity-50 overflow-y-auto"
  >
    <div
      ref="target"
      class="max-w-xl w-full mx-auto pt-6 px-6 pb-8 bg-white rounded-xl"
    >
      <font-awesome-icon
        icon="circle-xmark"
        class="text-gray-800 h-6 float-right hover:text-red-600 cursor-pointer p-3 -m-3"
        @click="close"
      />
      <div class="flex items-center mb-6">
        <div
          class="flex items-center justify-center h-14 w-14 bg-gray-100 px-4 rounded-xl mr-4"
        >
          <font-awesome-icon
            icon="square-plus"
            class="text-accentPrimary h-8"
          />
        </div>
        <div class="w-full">
          <h4 class="text-xl text-black font-semibold">New Category</h4>
          <span class="text-sm text-gray-800 font-semibold"
            >Create a new category to better help MakeMeA understand you
          </span>
        </div>
      </div>
      <InlineInput
        v-model="name"
        title="Category Name"
        :error="error"
        :disabled="loading"
        inputType="text"
        placeholder="Name of my pets"
        loadFirst
      />
      <InlineInput
        class="mt-5"
        v-model="answer"
        :title="`Answer for ${name || 'your category'}`"
        :disabled="loading"
        inputType="text"
        :placeholder="
          name ? `Your answer name to ${name}` : 'Fluffy, Smudge and Dash'
        "
        @submit="submit"
      />

      <div class="w-5/6 mx-auto mt-4">
        <MainButton ref="button" @clicked="submit" class="w-full" />
      </div>
    </div>
  </section>
</template>
