<script setup>
import { computed } from "vue";
import { useStore } from "vuex";
import LanguageItem from "./LanguageItem.vue";
import CustomItem from "../CustomItem.vue";
import ItemAccordion from "../../ItemAccordion.vue";
import stars from "@/assets/animation_stars.json";

const store = useStore();

const addLanguage = (language) =>
  store.dispatch("language/addLanguage", language);

const loading = computed(() => store.state.language.loading);
const activeLanguage = computed(() => store.state.language.activeLanguage);
</script>

<template>
  <section>
    <div class="flex-col hidden lg:flex mb-8">
      <p class="mb-4 text-base font-bold text-primary">Language</p>
      <div class="flex flex-wrap lg:flex-nowrap">
        <LanguageItem />
        <div
          v-if="loading"
          class="bg-gray-10 flex flex-col items-center justify-center mt-4 lg:mt-0 w-24 h-24 mr-4 rounded-lg cursor-pointer"
        >
          <Vue3Lottie
            :animationData="stars"
            :width="96"
            :loop="true"
            :speed="2"
            :autoPlay="true"
            direction="forward"
          />
        </div>
        <CustomItem
          v-else
          :itemType="'language'"
          @submit="addLanguage({ language: $event, emoji: '📝' })"
        />
      </div>
    </div>
    <ItemAccordion
      :isSelected="activeLanguage !== ''"
      :itemId="100"
      class="block mt-3 lg:hidden"
    >
      <template v-slot:title>
        <span>Language</span>
      </template>
      <template v-slot:content>
        <div class="flex flex-wrap lg:flex-nowrap">
          <LanguageItem />
          <div
            v-if="loading"
            class="bg-gray-10 flex flex-col items-center justify-center mt-4 lg:mt-0 w-24 h-24 mr-4 border-transparent rounded-lg cursor-pointer"
          >
            <Vue3Lottie
              :animationData="stars"
              :width="96"
              :loop="true"
              :speed="2"
              :autoPlay="true"
              direction="forward"
            />
          </div>
          <CustomItem
            v-else
            :itemType="'language'"
            @submit="addLanguage({ language: $event, emoji: '📝' })"
          />
        </div>
      </template>
    </ItemAccordion>
  </section>
</template>
