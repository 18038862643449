<script setup>
import { computed } from "vue";
import { useStore } from "vuex";
import Tone from "./tone/Tone.vue";
import Format from "./format/Format.vue";
import Length from "./length/Length.vue";
import Language from "./language/Language.vue";

const store = useStore();

const isMobile = computed(() => store.state.isMobile);
</script>

<template>
  <div>
    <p v-if="!isMobile" class="text-sm italic text-secondary mb-6">
      Here are styling and formatting options for your make. Choose as many or
      as few as you like. Even add your own. <br />
      When you are done, explore the other tabs or press generate.
    </p>
    <Tone />
    <Length />
    <Format />
    <div class="mb-44">
      <Language />
    </div>
  </div>
</template>
