<script setup>
import { ref, onMounted } from "vue";
import { v4 as uuid } from "uuid";

const props = defineProps({
  error: {
    type: String,
    default: "",
  },
  inputType: {
    type: String,
    required: true,
  },
  title: {
    type: String,
    required: true,
  },
  loadFirst: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["submit", "update:modelValue"]);

const input = ref(null);
const id = uuid();

const submit = () => {
  emit("submit");
};

onMounted(() => {
  if (props.loadFirst) {
    input.value.focus();
  }
});
</script>

<template>
  <div class="relative w-full">
    <label
      :for="id"
      class="truncate overflow-x-hidden max-w-11/12 absolute bottom-full left-0 ml-3 -mb-1 transform translate-y-0.5 text-xs font-semibold text-gray-700 px-1 bg-white"
      >{{ props.title }}
    </label>
    <p
      v-if="props.error"
      class="text-sm px-1 text-red-500 bg-white font-medium ml-5 mt-11 absolute"
    >
      {{ props.error }}
    </p>
    <input
      ref="input"
      class="block w-full outline-none bg-transparent text-sm text-gray-900 font-medium border focus-within:border-accentPrimary rounded-lg px-3 py-4"
      :class="props.error ? 'border-red-500' : 'border-gray-600'"
      @keyup.enter="submit"
      v-bind="$attrs"
      @input="$emit('update:modelValue', $event.target.value)"
      :id="id"
      :type="props.inputType"
    />
  </div>
</template>
