/* import the fontawesome core */
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faArrowLeft,
  faArrowsSplitUpAndLeft,
  faAt,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faCircleCheck,
  faCircleQuestion,
  faCircleXmark,
  faCopy,
  faFileLines,
  faGripLines,
  faHandPointRight,
  faListCheck,
  faListUl,
  faObjectGroup,
  faPencil,
  faPlus,
  faRotateLeft,
  faRotateRight,
  faScroll,
  faShareNodes,
  faSquarePlus,
  faThumbsDown,
  faThumbsUp,
  faTrashCan,
  faUser,
  faUserPlus,
  faWandMagicSparkles,
} from "@fortawesome/free-solid-svg-icons";

import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(
  faArrowLeft,
  faArrowsSplitUpAndLeft,
  faAt,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faCircleCheck,
  faCircleQuestion,
  faCircleXmark,
  faCopy,
  faEnvelope,
  faFileLines,
  faGripLines,
  faHandPointRight,
  faListCheck,
  faListUl,
  faObjectGroup,
  faPencil,
  faPlus,
  faRotateLeft,
  faRotateRight,
  faScroll,
  faShareNodes,
  faSquarePlus,
  faThumbsDown,
  faThumbsUp,
  faTrashCan,
  faUser,
  faUserPlus,
  faWandMagicSparkles
);

export default FontAwesomeIcon;
