<script setup>
import { ref, watch, computed } from "vue";
import { useStore } from "vuex";
import { loadPreviousPage } from "@/utils/loadPastPages";
import MainButton from "../../inputs/MainButton.vue";
import pushNextPage from "@/utils/pushNextPage";

const store = useStore();

const prompt = computed(() => store.state.prompt);
const loading = computed(() => store.state.personalInfo.loading);
const totalMakes = computed(() => store.state.personalInfo.totalMakes);

const setNewInput = (state) => store.dispatch("setNewInput", state);
const getMagicInfo = () => store.dispatch("personalInfo/getMagicInfo");

const emit = defineEmits(["triggerSave"]);

const saveButton = ref(null);
const learnButton = ref(null);
const promptInput = ref(prompt.value);

const back = async () => {
  await loadPreviousPage(store);
  // wait 500ms then, push the next page
  setTimeout(() => {
    pushNextPage(store.state);
  }, 500);
};

const save = () => {
  saveButton.value.setLoading(true);
  if (learnButton.value) {
    learnButton.value.setDisable(true);
  }
  emit("triggerSave");
};

const learn = () => {
  if (learnButton.value) {
    learnButton.value.setLoading(true);
  }
  saveButton.value.setDisable(true);
  getMagicInfo();
};

// Toggle if the user is asking for a new prompt or not
watch(promptInput, () => {
  if (
    prompt.value.toLowerCase() === promptInput.value.toLowerCase() ||
    promptInput.value.length === 0
  ) {
    setNewInput(false);
  } else {
    setNewInput(true);
  }
});

// If re-loaded and prompt takes a second to load, show the prompt in the inputBox
watch(prompt, (newVal, oldVal) => {
  if (oldVal === "" && promptInput.value !== prompt.value) {
    promptInput.value = prompt.value;
  }
  if (oldVal === promptInput.value) {
    promptInput.value = prompt.value;
  }
});

watch(loading, (value) => {
  if (!value) {
    saveButton.value.setLoading(false);
    saveButton.value.setDisable(false);
    if (learnButton.value) {
      learnButton.value.setLoading(false);
      learnButton.value.setDisable(false);
    }
  }
});

defineExpose({ back });
</script>

<template>
  <section class="w-full mt-6 grid grid-cols-2 lg:grid-cols-4 gap-5 lg:gap-11">
    <MainButton
      ref="saveButton"
      class="mt-2 lg:mt-6 lg:mb-8"
      setPadding="sm:px-12 px-6 py-4"
      hideCorner
      @clicked="save()"
    >
      <font-awesome-icon
        class="mr-2 cursor-pointer h-4"
        :icon="['fas', 'chevron-left']"
      />
      <label class="uppercase cursor-pointer"> Save </label>
    </MainButton>
    <span
      class="w-full max-w-lg lg:max-w-2xl text-base text-primary col-span-2 text-center flex-wrap my-auto mx-auto row-start-2 lg:row-start-1 lg:col-start-2 lg:flex hidden"
    >
      <p class="w-full">
        Here you can add
        <label class="text-accentPrimary">additional information</label> so all
        your future makes will have more relevance to you Without needing to
        re-provide the information.
      </p>
      <p class="w-full">
        Enter as much or as little as you want
        <label class="text-accentPrimary">below</label>:
      </p>
    </span>
    <MainButton
      v-if="totalMakes >= 5"
      ref="learnButton"
      class="mt-2 lg:mt-6 lg:mb-8"
      setColours="bg-primary hover:bg-secondary"
      setPadding="sm:px-12 px-6 py-4"
      @clicked="learn"
    >
      <div class="flex justify-center">
        <font-awesome-icon
          class="mr-2 cursor-pointer my-auto h-6"
          :icon="['fas', 'wand-magic-sparkles']"
        />
        <label class="uppercase cursor-pointer sm:text-base text-sm my-auto">
          Learn from makes
        </label>
      </div>
    </MainButton>
  </section>
</template>
