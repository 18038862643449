export default {
  name: "length",
  namespaced: true,
  state: {
    selectedLength: 2,
    hasMoved: false,
  },
  mutations: {
    SET_SELECTED_LENGTH(state, payload) {
      state.selectedLength = payload;
    },
    SET_HAS_MOVED(state, payload) {
      state.hasMoved = payload;
    },
  },
  actions: {
    reset({ commit }) {
      commit("SET_SELECTED_LENGTH", 2);
      commit("SET_HAS_MOVED", false);
    },
    moveSlider({ state, commit }, payload) {
      if (state.hasMoved === false && payload === "2.00") return;
      if (state.hasMoved === false) {
        commit("SET_HAS_MOVED", true);
      }
      commit("SET_SELECTED_LENGTH", payload);
    },
  },
  modules: {},
  getters: {},
};
