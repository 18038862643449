<script setup>
import { ref, onMounted } from "vue";
import { newTour } from "@/utils/shepherd/main";

const props = defineProps({
  tabList: {
    type: Array,
    default: () => [],
  },
  currentTab: {
    type: Object,
    default: () => {},
  },
});

const emit = defineEmits(["change"]);

const listRef = ref(null);

const toggle = (val, index) => {
  emit("change", val);
  newTour();
  scrollToTab(index);
};

const scrollToTab = (index) => {
  // Get the button element
  const button = listRef.value.children[index];

  // Calculate the scroll position
  const scrollPosition =
    button.offsetLeft - window.innerWidth / 2 + button.offsetWidth / 2;

  // Scroll to the calculated position
  listRef.value.scrollTo({
    left: scrollPosition,
    behavior: "smooth",
  });
};

onMounted(() => {
  // Find the index of the current tab
  const currentIndex = props.tabList.findIndex(
    (tab) => tab.name === props.currentTab.name
  );

  // Call the scrollToTab method with the current tab index
  scrollToTab(currentIndex);
});
</script>

<template>
  <ul
    id="shepherd-target-tabs"
    ref="listRef"
    class="flex text-sm font-medium text-center lg:justify-normal overflow-x-auto"
  >
    <li v-for="(tab, index) in props.tabList" :key="index">
      <button
        :class="
          (props.tabList.length === 1
            ? 'rounded-lg '
            : index === 0
            ? 'rounded-l-lg ml-3 lg:ml-0 '
            : index === props.tabList.length - 1
            ? 'rounded-r-lg mr-3 lg:mr-0 '
            : '') +
          (currentTab.name === tab.name
            ? 'font-bold bg-accentSecondary'
            : 'font-normal bg-gray-10')
        "
        class="inline-block px-6 py-3 text-base w-44 lg:w-36 text-primary"
        @click="toggle(tab, index)"
      >
        {{ tab.name }}
      </button>
    </li>
  </ul>
</template>
@/utils/main
