<script setup>
import { computed, ref, onMounted } from "vue";
import { v4 as uuid } from "uuid";

const props = defineProps({
  modelValue: {
    type: String,
    required: true,
  },
  error: {
    type: String,
    default: "",
  },
  title: {
    type: String,
    required: false,
  },
  autofocus: {
    type: Boolean,
    default: false,
  },
  forceId: {
    type: String,
    default: uuid(),
  },
});
defineEmits(["update:modelValue"]);

const input = ref(null);

// Set classes for the input box based on the error prop
const errorClasses = computed(() => {
  return {
    "border-red-500 text-red-500 font-bold": props.error,
    "focus-within:border-accentPrimary text-gray-900 font-medium": !props.error,
  };
});

const handleFocus = () => {
  input.value.scrollIntoView({ behavior: "smooth", block: "center" });
};

onMounted(() => {
  input.value.addEventListener("focus", handleFocus);
});
</script>

<template>
  <section>
    <div class="relative mb-10 w-full h-screen">
      <label
        v-if="title"
        :for="forceId"
        class="absolute bottom-full left-0 ml-3 -mb-1 transform translate-y-0.5 text-xs font-semibold text-gray-700 px-1 bg-white"
        >{{ title }}</label
      >
      <textarea
        ref="input"
        :id="forceId"
        :value="modelValue"
        class="p-5 w-full h-1/2 outline-none resize-none border rounded-lg border-gray-600"
        :class="errorClasses"
        v-bind="$attrs"
        @input="$emit('update:modelValue', $event.target.value)"
        :autofocus="autofocus"
      />
      <p v-if="error" class="text-sm text-red-font-medium ml-5 mt-1 -mb-6">
        {{ error }}
      </p>
    </div>
  </section>
</template>
