import store from "../../store/index.js";
import { startInitialTour, tour as initialTour } from "./initialTour.js";
import { startContextTour, tour as contextTour } from "./contextTour.js";
import { startResultTour, tour as resultTour } from "./resultTour.js";
import { startQuestionsTour, tour as questionsTour } from "./questionsTour.js";
import { startStyleTour, tour as styleTour } from "./styleTour.js";

var tour = null;

const nextStep = (step = null) => {
  if (!tour?.isActive()) return;
  if (
    tour.id.includes("initialTour") &&
    tour.getCurrentStep().id === "typing" &&
    step === "typing-complete"
  ) {
    tour.hide();
    // Check if drawer is open
    const checkDrawerOpen = setInterval(() => {
      if (store.state.drawer.isOpen) {
        clearInterval(checkDrawerOpen);
        setTimeout(() => {
          return tour.next();
        }, 600);
      }
    }, 200);
  } else if (
    tour.id.includes("questionsTour") &&
    tour.getCurrentStep().id === "start" &&
    step === "question-accordion"
  ) {
    tour.hide();
    // Check if drawer is open
    const checkDrawerOpen = setInterval(() => {
      if (store.state.drawer.isOpen) {
        clearInterval(checkDrawerOpen);
        setTimeout(() => {
          return tour.next();
        }, 50);
      }
    }, 200);
  } else if (step !== null) return tour.show(step);
  else return tour.next();
};

const newTour = (force_start = false) => {
  if (tour?.isActive()) {
    if (tour.id.includes("initialTour")) {
      store.dispatch("tours/completeInitialTour");
    } else if (tour.id.includes("resultTour")) {
      store.dispatch("tours/completeResultTour");
    } else if (tour.id.includes("contextTour")) {
      store.dispatch("tours/completeContextTour");
    } else if (tour.id.includes("questionsTour")) {
      store.dispatch("tours/completeQuestionsTour");
    } else if (tour.id.includes("styleTour")) {
      store.dispatch("tours/completeStyleTour");
    }
    tour.complete();
  }
  if (!store.state.drawer.isOpen) {
    if (force_start) {
      store.dispatch("tours/unsetInitialTour");
    }
    tour = initialTour;
    startInitialTour();
  } else if (store.state.result.showResult) {
    if (force_start) {
      store.dispatch("tours/unsetResultTour");
    }
    tour = resultTour;
    setTimeout(() => {
      startResultTour();
    }, 600);
  } else if (store.state.tabs.currentTab.name === "Context") {
    if (force_start) {
      store.dispatch("tours/unsetContextTour");
    }
    tour = contextTour;
    setTimeout(() => {
      startContextTour();
    }, 600);
  } else if (store.state.tabs.currentTab.name === "Questions") {
    if (force_start) {
      store.dispatch("tours/unsetQuestionsTour");
    }
    tour = questionsTour;
    setTimeout(() => {
      startQuestionsTour();
    }, 600);
  } else if (store.state.tabs.currentTab.name === "Style") {
    if (force_start) {
      store.dispatch("tours/unsetStyleTour");
    }
    tour = styleTour;
    setTimeout(() => {
      startStyleTour();
    }, 600);
  }
};

export { nextStep, newTour };
