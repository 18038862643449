import store from "@/store";
const setUserID = () => store.dispatch("auth/setUserID");
const setLastRequest = ({ request, url, result, status }) =>
  store.dispatch("setLastRequest", { request, url, result, status });

const fetchData = async (
  endpoint,
  body,
  addPrompt = true,
  addJourneyId = true,
  addUserId = true
) => {
  const headers = new Headers({
    "Content-Type": "application/json",
  });
  if (addPrompt) {
    body.prompt = store.state.prompt;
  }
  if (addJourneyId) {
    body.journeyId = store.state.journeyId;
  }
  if (addUserId) {
    await setUserID();
    body.userId = store.state.auth.userId;
  }
  const url = `${process.env.VUE_APP_APIURL}/api/v1/${endpoint}`;
  let request = {
    method: "POST",
    headers,
    body: JSON.stringify(body),
    credentials: "include",
  };
  const response = await fetch(url, request);
  let responseBody = await response.text();
  try {
    responseBody = JSON.parse(responseBody);
  } catch {
    responseBody = { result: responseBody };
  }
  return setLastRequest({
    request,
    url,
    result: responseBody.result ? responseBody.result : responseBody,
    status: response.status,
  });
};

export default fetchData;
