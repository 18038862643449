<script setup>
import { ref } from "vue";
const props = defineProps({
  hideCorner: {
    type: Boolean,
    default: false,
  },
  cornerColour: {
    type: String,
    default: "border-t-white",
  },
  curve: {
    type: Boolean,
    default: true,
  },
  setColours: {
    type: String,
    default: "bg-accentPrimary hover:bg-accentPrimaryLight",
  },
  setPadding: {
    type: String,
    default: "xs:px-12 px-6 py-4",
  },
});

const emit = defineEmits(["clicked"]);

const loading = ref(false);
const disable = ref(false);

const setLoading = (value) => {
  loading.value = value;
};

const setDisable = (value) => {
  disable.value = value;
};

const triggerClicked = () => {
  if (loading.value || disable.value) return;
  emit("clicked");
};

defineExpose({ setLoading, setDisable });
</script>

<template>
  <button
    :class="
      (props.curve ? 'rounded-lg ' : '') +
      (props.hideCorner ? 'rounded-tr-[25px] ' : '') +
      (loading || disable ? 'bg-secondary cursor-default ' : setColours + ' ') +
      props.setPadding +
      ' '
    "
    class="relative text-base font-bold text-white border-transparent transition-colors duration-300"
    @click="triggerClicked"
  >
    <div
      v-if="props.hideCorner"
      class="absolute top-0 right-0 w-0 h-0 border-t-[20px] border-l-transparent border-l-[20px]"
      :class="props.cornerColour"
    ></div>
    <div :class="loading ? 'p-3' : ''">
      <div
        v-if="loading"
        class="absolute inset-0 flex items-center justify-center"
      >
        <!-- loading spinner -->
        <svg
          class="animate-spin -ml-1 h-5 w-5 text-accentSecondary"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            class="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            stroke-width="4"
          ></circle>
          <path
            class="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
          ></path>
        </svg>
      </div>
      <slot v-else>
        <p class="cursor-pointer">Submit</p>
      </slot>
    </div>
  </button>
</template>
