<script setup>
import { useStore } from "vuex";
import { computed, ref, onMounted } from "vue";

const store = useStore();

const props = defineProps({
  modelValue: {
    type: String,
    required: true,
  },
  error: {
    type: String,
    default: "",
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  defaultClasses: {
    type: String,
    default: "text-gray-10 bg-primary text-xl border-2",
  },
  errorClasses: {
    type: String,
    default: "ml-5 mt-1 -mb-6",
  },
});
const emit = defineEmits(["submit", "update:modelValue"]);

const input = ref(null);
const isMobile = computed(() => store.state.isMobile);

// Set classes for the input box based on the error prop
const inputClasses = computed(() => {
  return {
    "border-red-500 text-red-300 font-bold": props.error,
    "focus:border-accentPrimary border-transparent text-white": !props.error,
  };
});

const submit = () => {
  emit("submit");
};

const handleFocus = () => {
  if (isMobile.value) {
    input.value.scrollIntoView({ behavior: "smooth", block: "center" });
  }
};

const blur = () => {
  input.value.blur();
};

onMounted(() => {
  input.value.addEventListener("focus", handleFocus);
});

defineExpose({
  blur,
});
</script>

<template>
  <section>
    <input
      ref="input"
      :value="modelValue"
      class="w-full outline-none rounded-xl"
      :class="inputClasses + ' ' + props.defaultClasses"
      @keyup.enter="submit"
      v-bind="$attrs"
      @input="$emit('update:modelValue', $event.target.value)"
      :disabled="props.disabled"
    />
    <p
      v-if="props.error"
      class="text-sm text-red-500 font-medium"
      :class="props.errorClasses"
    >
      {{ props.error }}
    </p>
  </section>
</template>
