<script setup>
import { ref, computed, watch } from "vue";
import { useStore } from "vuex";
import { onClickOutside } from "@vueuse/core";
import MainButton from "../inputs/MainButton.vue";
import InlineInput from "../inputs/InlineInput.vue";

const store = useStore();

const magicInfoCategoriesOpen = computed(
  () => store.state.modals.magicInfoCategoriesOpen
);
const magicCategories = computed(
  () => store.state.personalInfo.magicCategories
);
const categories = computed(() => store.state.personalInfo.categories);

const closeMagicInfoCategories = () =>
  store.dispatch("modals/closeMagicInfoCategories");
const addCategory = (category) =>
  store.dispatch("personalInfo/addCategory", category);
const saveInfo = (info) => store.dispatch("personalInfo/saveInfo", info);
const getMagicInfo = (info) =>
  store.dispatch("personalInfo/getMagicInfo", info);
const clearMagicCategories = () =>
  store.dispatch("personalInfo/clearMagicCategories");

const target = ref(null);
const loading = ref(false);
const button = ref(null);
const removedCategories = ref([]);
const newCategories = ref([]);

onClickOutside(target, () => close());

const submit = async () => {
  button.value.setLoading(true);
  loading.value = true;
  addCategory(newCategories.value);
  await saveInfo({ categories: categories.value });
  clearMagicCategories();
  close();
  button.value.setLoading(false);
  loading.value = false;
};

const redo = async () => {
  removedCategories.value = [];
  button.value.setLoading(true);
  await getMagicInfo({ categories: categories.value });
  button.value.setLoading(false);
};

const close = () => {
  closeMagicInfoCategories();
};

const removeCategory = (index) => {
  removedCategories.value.push(newCategories.value[index]);
  newCategories.value.splice(index, 1);
};

const undoRemoveCategory = () => {
  newCategories.value.push(removedCategories.value.pop());
};

// watch for changes in the magicCategories
watch(magicCategories, (newVal) => {
  if (magicInfoCategoriesOpen.value) {
    // map array to newCategories, where each object in array.type becomes newCategories.name and array.description becomes newCategories.answer
    newCategories.value = newVal.map((item) => {
      return {
        name: item.type,
        answer: item.description,
      };
    });
  }
});
</script>

<template>
  <section
    v-if="magicInfoCategoriesOpen"
    class="z-50 fixed top-0 left-0 right-0 bottom-0 flex items-center w-full h-full p-4 bg-black bg-opacity-50 overflow-y-auto"
  >
    <div
      ref="target"
      class="max-w-xl w-full mx-auto pt-6 px-6 pb-8 bg-white rounded-xl"
    >
      <font-awesome-icon
        icon="circle-xmark"
        class="text-gray-800 h-6 float-right hover:text-red-600 cursor-pointer p-3 -m-3"
        @click="close"
      />
      <div class="flex items-center mb-5">
        <div
          class="flex items-center justify-center h-14 w-14 bg-gray-100 px-4 rounded-xl mr-4"
        >
          <font-awesome-icon
            icon="wand-magic-sparkles"
            class="text-accentPrimary h-8"
          />
        </div>
        <div class="w-full">
          <h4 class="text-xl text-black font-semibold">New Categories</h4>
          <span class="text-sm text-gray-800 font-semibold"
            >Here are the categories and answers we think may be relevant to
            you. Edit or remove any that don't apply.
          </span>
        </div>
      </div>
      <div v-for="(category, index) in newCategories" :key="category">
        <div class="flex">
          <div class="sm:w-11/12 w-5/6">
            <InlineInput
              :value="category.name"
              v-model="category.name"
              title="Category Name"
              inputType="text"
            />
            <InlineInput
              class="mt-5"
              :value="category.answer"
              v-model="category.answer"
              :title="`Answer for ${category.name}`"
              inputType="text"
            />
          </div>
          <font-awesome-icon
            icon="circle-xmark"
            class="text-gray-800 h-6 float-right hover:text-red-600 cursor-pointer sm:w-1/12 w-1/6 my-auto p-3 -m-3"
            @click="removeCategory(index)"
          />
        </div>
        <!-- thin horizontal line, unless this is the last category -->
        <div
          v-if="index !== newCategories.length - 1"
          class="border-b border-gray-300 w-11/12 mx-auto mt-3 mb-4"
        />
      </div>
      <div v-if="removedCategories.length > 0" class="mt-3 -mb-1">
        <div
          v-if="index !== newCategories.length - 1"
          class="border-b border-gray-300 w-11/12 mx-auto my-3"
        />
        <button
          class="text-primary hover:bg-primary py-1 px-2 font-bold hover:text-white border-transparent rounded-lg flex mx-auto"
          @click="undoRemoveCategory"
        >
          <p>Undo</p>
          <font-awesome-icon icon="rotate-left" class="h-4 mt-1 ml-2" />
        </button>
      </div>
      <div v-else-if="newCategories.length === 0" class="mt-3 -mb-1">
        <p class="text-gray-800 text-center">
          We could not learn any new info from your past makes. <br />
          Either, return and make more or try to retry below.
        </p>
      </div>

      <div class="w-5/6 mx-auto mt-4">
        <MainButton
          ref="button"
          @clicked="newCategories.length > 0 ? submit() : redo()"
          class="w-full"
        >
          <label v-if="newCategories.length > 0" class="cursor-pointer"
            >Save</label
          >
          <label v-else class="cursor-pointer">Retry</label>
          <font-awesome-icon
            v-if="newCategories.length > 0"
            icon="pencil"
            class="h-4 mt-1 ml-2"
          />
          <font-awesome-icon v-else icon="rotate-right" class="h-4 mt-1 ml-2" />
        </MainButton>
      </div>
    </div>
  </section>
</template>
