<script setup>
import { ref, onMounted, onUnmounted } from "vue";

const emit = defineEmits(["hidden"]);

const elementToObserve = ref(null);
let observer;

onMounted(() => {
  observer = new IntersectionObserver(
    ([entry]) => {
      if (entry.intersectionRatio < 0.9) {
        emit("hidden", true);
      } else {
        emit("hidden", false);
      }
    },
    {
      threshold: 0.9,
    }
  );

  if (elementToObserve.value) {
    observer.observe(elementToObserve.value);
  }
});

onUnmounted(() => {
  emit("hidden", false);
  if (observer) {
    observer.disconnect();
  }
});
</script>

<template>
  <div ref="elementToObserve">
    <slot></slot>
  </div>
</template>
