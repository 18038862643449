import { useShepherd } from "vue-shepherd";
import store from "../../store/index.js";

const tour = useShepherd({
  exitOnEsc: false,
  keyboardNavigation: false,
  useModalOverlay: true,
  modalContainer: document.body,
  tourName: "contextTour",
  defaultStepOptions: {
    classes: "outline-none",
    scrollTo: { behavior: "smooth", block: "center" },
  },
});

const startContextTour = () => {
  if (!store.state.tours.showContextTour) return;
  if (!store.state.drawer.isOpen) return;
  if (store.state.tabs.currentTab.name !== "Context") return;
  return contextTour.bind(this)().start();
};

const contextTour = () => {
  const isMobile = store.state.isMobile;
  let action = isMobile ? "tap" : "click";
  tour.addSteps([
    {
      attachTo: {
        element: "#shepherd-target-context",
        on: `${isMobile ? "bottom" : "right"}`,
      },
      title: `<div class="w-full flex">
                  <p class="my-auto">Adding Context</p>
                  <img
                    ref="genie"
                    class="w-[60px] ml-auto -my-3 -mr-2"
                    src="/genie-crossed-torso.svg"
                    alt="genie"
                  />
              </div>`,
      text: "Sometimes you have some context that you want to paste in to help me, like previous email correspondence or the start of a story you want to finish off. This is the box that you can do that in.",
      arrow: true,
      buttons: [
        {
          text: "Next",
          action: function () {
            this.next();
          },
          classes: "w-full",
        },
      ],
    },
    {
      id: "final",
      title: `<div class="w-full flex">
                  <p class="my-auto">Adding Context</p>
                  <img
                    ref="genie"
                    class="w-[60px] ml-auto -my-3 -mr-2"
                    src="/genie-crossed-torso.svg"
                    alt="genie"
                  />
              </div>`,
      text: `When you are done, you can ${action} back on the <em>Questions</em> tab to see new questions about the context you have just added`,
      buttons: [
        {
          text: "Finish",
          action: function () {
            store.dispatch("tours/completeContextTour");
            this.complete();
          },
          classes: "w-full",
        },
      ],
    },
  ]);
  return tour;
};

export { startContextTour, tour };
