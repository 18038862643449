<script setup>
import { ref, computed, watch } from "vue";
import { useStore } from "vuex";
import MainButton from "../inputs/MainButton.vue";
import InlineInput from "../inputs/InlineInput.vue";

const store = useStore();

const loading = computed(() => store.state.auth.loading);
const error = computed(() => store.state.auth.error);

const closeAuth = () => store.dispatch("modals/closeAuth");
const signup = (user) => store.dispatch("auth/signup", user);
const login = (user) => store.dispatch("auth/login", user);
const clearError = () => store.dispatch("auth/clearError");

const email = ref("");
const password = ref("");
const loginButton = ref(null);
const signupButton = ref(null);
const emailError = ref(null);
const passwordError = ref(null);

// watch for changes in loading and copy them to signupButton.value.setLoading
watch(loading, (value) => {
  if (!value) {
    signupButton.value.setLoading(false);
    loginButton.value.setLoading(false);
    signupButton.value.setDisable(false);
    loginButton.value.setDisable(false);
  }
});

const checkEmail = () => {
  if (error.value) {
    clearError();
  }
  // remove whitespace
  email.value = email.value.trim();
  if (email.value.length === 0) {
    return "Please enter an email";
  }
  // check valid email
  const emailRegex = /\S+@\S+\.\S+/;
  if (!emailRegex.test(email.value)) {
    return "Please enter a valid email";
  }
  return null;
};

const checkPassword = () => {
  if (error.value) {
    clearError();
  }
  // remove whitespace
  password.value = password.value.trim();
  if (password.value.length === 0) {
    return "Please enter a password";
  }
  if (password.value.length < 6) {
    return "Please enter a password longer than 6 characters";
  }
  return null;
};

const signupSubmit = () => {
  emailError.value = checkEmail();
  passwordError.value = checkPassword();
  if (emailError.value || passwordError.value) {
    return;
  }
  if (loading.value) {
    return;
  }
  signupButton.value.setLoading(true);
  loginButton.value.setDisable(true);
  signup({ email: email.value, password: password.value });
  watch(loading, (value) => {
    if (value === false && error.value === "") {
      closeAuth();
    }
  });
};

const loginSubmit = () => {
  emailError.value = checkEmail();
  passwordError.value = checkPassword();
  if (emailError.value || passwordError.value) {
    return;
  }
  if (loading.value) {
    return;
  }
  loginButton.value.setLoading(true);
  signupButton.value.setDisable(true);
  login({ email: email.value, password: password.value });
  watch(loading, (value) => {
    if (value === false && error.value === "") {
      closeAuth();
    }
  });
};
</script>

<template>
  <section>
    <InlineInput
      v-model="email"
      title="Email"
      :error="emailError"
      :disabled="loading"
      inputType="email"
      placeholder="Enter your email..."
      @submit="() => {}"
      @input="() => (emailError = checkEmail())"
      loadFirst
    />
    <InlineInput
      class="mt-5"
      v-model="password"
      title="Password"
      :error="passwordError || error"
      :disabled="loading"
      inputType="password"
      placeholder="Enter your password..."
      @submit="signupSubmit"
      @input="() => (passwordError = checkPassword())"
    />

    <div class="mt-4 grid gap-5 grid-cols-2">
      <MainButton
        ref="loginButton"
        @clicked="loginSubmit"
        class="w-full"
        :setColours="'bg-primary hover:bg-secondary'"
      >
        <label class="cursor-pointer">Login</label>
      </MainButton>
      <MainButton ref="signupButton" @clicked="signupSubmit" class="w-full">
        <label class="cursor-pointer">Signup</label>
      </MainButton>
    </div>
  </section>
</template>
