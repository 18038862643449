<script setup>
import { ref } from "vue";

defineProps({
  itemType: String,
});
const emit = defineEmits(["submit"]);

const showInput = ref(false);
const newItem = ref("");

const submit = () => {
  showInput.value = false;
  emit("submit", newItem.value);
  newItem.value = "";
};
</script>

<template>
  <div
    v-if="!showInput"
    class="text-secondary hover:text-primary bg-gray-10 hover:bg-gray-100 flex flex-col justify-center mt-4 lg:mt-0 w-24 h-24 mr-4 rounded-lg cursor-pointer"
    @click="showInput = true"
  >
    <font-awesome-icon :icon="['fas', 'plus']" class="h-9 align-middle" />
    <label class="mt-2 text-sm text-center cursor-pointer capitalize">
      Add {{ itemType }}
    </label>
  </div>
  <div v-else class="mt-4 lg:mt-0 w-24 h-24 mr-4">
    <input
      type="text"
      v-model="newItem"
      class="w-full h-full text-primary bg-gray-100 flex flex-col text-center rounded-lg"
      :placeholder="`Enter ${itemType}`"
      @blur="submit"
      @keyup.enter="submit"
      autofocus
    />
  </div>
</template>
