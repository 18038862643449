<script setup>
import { computed } from "vue";
import { useStore } from "vuex";
import RangeSlider from "../../../inputs/RangeSlider.vue";
import ItemAccordion from "../../ItemAccordion.vue";

const store = useStore();

const hasMoved = computed(() => store.state.length.hasMoved);
</script>

<template>
  <section>
    <div class="flex-col hidden lg:flex mb-4">
      <p class="mb-4 text-base font-bold text-primary">Length</p>
      <RangeSlider class="mb-14" />
    </div>
    <ItemAccordion
      :isSelected="hasMoved === true"
      :itemId="100"
      class="block mt-3 lg:hidden"
    >
      <template v-slot:title>
        <span>Length</span>
      </template>
      <template v-slot:content>
        <RangeSlider class="mb-14" />
      </template>
    </ItemAccordion>
  </section>
</template>
