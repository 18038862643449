import { useShepherd } from "vue-shepherd";
import store from "../../store/index.js";

const tour = useShepherd({
  exitOnEsc: false,
  keyboardNavigation: false,
  useModalOverlay: true,
  modalContainer: document.body,
  tourName: "questionsTour",
  defaultStepOptions: {
    classes: "outline-none",
    scrollTo: { behavior: "smooth", block: "center" },
  },
});

const startQuestionsTour = () => {
  if (!store.state.tours.showQuestionsTour) return;
  if (!store.state.drawer.isOpen) return;
  if (store.state.tabs.currentTab.name !== "Questions") return;
  // Check if questions are loaded
  const checkFinishedLoading = setInterval(() => {
    if (
      !store.state.questions.loading &&
      store.state.questions.questionList.length > 0
    ) {
      clearInterval(checkFinishedLoading);

      return questionsTour.bind(this)().start();
    }
  }, 200);
};

const questionsTour = () => {
  const isMobile = store.state.isMobile;
  let action = isMobile ? "tap" : "click";
  tour.addSteps([
    {
      id: "start",
      attachTo: {
        element: "#shepherd-target-questions",
        on: `${isMobile ? "top" : "right-start"}`,
      },
      title: `<div class="w-full flex">
                  <p class="my-auto">Answering Questions</p>
                  <img
                    ref="genie"
                    class="w-[60px] ml-auto -my-3 -mr-2"
                    src="/genie-crossed-torso.svg"
                    alt="genie"
                  />
              </div>`,
      text: `Answer as many questions as you want by ${action}ing to expand them`,
      arrow: true,
      buttons: [
        {
          text: "Next",
          action: function () {
            window.dispatchEvent(new Event("open-first-question"));
            this.hide();
            setTimeout(() => {
              return this.next();
            }, 500);
          },
          classes: "w-full",
        },
      ],
    },
    {
      attachTo: {
        element: "#shepherd-target-question-item",
        on: `${isMobile ? "bottom" : "right-start"}`,
      },
      title: `<div class="w-full flex">
                  <p class="my-auto">Answering Questions</p>
                  <img
                    ref="genie"
                    class="w-[60px] ml-auto -my-3 -mr-2"
                    src="/genie-crossed-torso.svg"
                    alt="genie"
                  />
              </div>`,
      text: `
        <p><em class="capitalize not-italic">${action}</em> on an answer to select it, you can add as many as you want. <br />
        <em class="capitalize not-italic">${action}</em> <em>Add your option here...</em> to add your own answers.<br />
        <em class="capitalize not-italic">${action}</em> on the <svg class="svg-inline--fa fa-pencil text-primary h-3" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="pencil" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path class="" fill="currentColor" d="M410.3 231l11.3-11.3-33.9-33.9-62.1-62.1L291.7 89.8l-11.3 11.3-22.6 22.6L58.6 322.9c-10.4 10.4-18 23.3-22.2 37.4L1 480.7c-2.5 8.4-.2 17.5 6.1 23.7s15.3 8.5 23.7 6.1l120.3-35.4c14.1-4.2 27-11.8 37.4-22.2L387.7 253.7 410.3 231zM160 399.4l-9.1 22.7c-4 3.1-8.5 5.4-13.3 6.9L59.4 452l23-78.1c1.4-4.9 3.8-9.4 6.9-13.3l22.7-9.1v32c0 8.8 7.2 16 16 16h32zM362.7 18.7L348.3 33.2 325.7 55.8 314.3 67.1l33.9 33.9 62.1 62.1 33.9 33.9 11.3-11.3 22.6-22.6 14.5-14.5c25-25 25-65.5 0-90.5L453.3 18.7c-25-25-65.5-25-90.5 0zm-47.4 168l-144 144c-6.2 6.2-16.4 6.2-22.6 0s-6.2-16.4 0-22.6l144-144c6.2-6.2 16.4-6.2 22.6 0s6.2 16.4 0 22.6z"></path></svg> to edit an answer. <br />
        <em class="capitalize not-italic">${action}</em> on <svg class="svg-inline--fa fa-circle-xmark text-primary h-3" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="circle-xmark" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path class="" fill="currentColor" d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM175 175c9.4-9.4 24.6-9.4 33.9 0l47 47 47-47c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-47 47 47 47c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-47-47-47 47c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l47-47-47-47c-9.4-9.4-9.4-24.6 0-33.9z"></path></svg> to replace the question with a new one.</p>
      `,
      arrow: true,
      buttons: [
        {
          text: "Next",
          action: function () {
            return this.next();
          },
          classes: "w-full",
        },
      ],
    },
    {
      title: `<div class="w-full flex">
                  <p class="my-auto">Answering Questions</p>
                  <img
                    ref="genie"
                    class="w-[60px] ml-auto -my-3 -mr-2"
                    src="/genie-crossed-torso.svg"
                    alt="genie"
                  />
              </div>`,
      text: `When you have answered some questions, you can explore the other tabs for more options and when you are ready ${action} on the Generate <svg class="svg-inline--fa fa-chevron-right h-2" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chevron-right" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path class="" fill="currentColor" d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z"></path></svg> button to see your make`,
      buttons: [
        {
          text: "Finish",
          action: function () {
            store.dispatch("tours/completeQuestionsTour");
            this.complete();
          },
          classes: "w-full",
        },
      ],
    },
  ]);
  return tour;
};

export { startQuestionsTour, tour };
