<script setup>
import { computed } from "vue";
import { useStore } from "vuex";
import { useShare } from "@vueuse/core";

const { share, isSupported } = useShare();
const store = useStore();

const prompt = computed(() => store.state.prompt);
const generatedText = computed(() => store.state.result.generatedText);
const editMode = computed(() => store.state.result.editMode);
const isMobile = computed(() => store.state.isMobile);
const finishedText = computed(() => store.state.result.finishedText);

const hideResult = () => store.dispatch("result/hideResult");
const toggleEditMode = () => store.dispatch("result/toggleEditMode");
const openNewPrompt = () => store.dispatch("modals/openNewPrompt");

const copyToClipboard = () => {
  navigator.clipboard.writeText(generatedText.value);
};

const startShare = () => {
  share({
    title: prompt.value,
    text: generatedText.value,
  });
};
</script>

<template>
  <section>
    <button
      class="flex items-center mt-5 text-sm font-bold uppercase text-accentPrimary lg:hidden"
      @click="hideResult"
    >
      <font-awesome-icon :icon="['fas', 'arrow-left']" class="mr-2" />
      Back to questions & styles
    </button>
    <span
      class="w-full items-center justify-between mt-3 mb-1 text-2xl lg:mt-0 text-primary"
      :class="
        finishedText
          ? 'grid xl:grid-cols-3 md:grid-cols-4 sm:grid-cols-4 xs:grid-cols-3 lg:grid-cols-2 grid-cols-2'
          : ''
      "
    >
      <h3
        class="w-full items-center justify-between lg:mt-0 truncate"
        :class="
          finishedText
            ? 'xl:col-span-2 xs:col-span-2 sm:col-span-3 md:col-span-3 lg:col-span-1 col-span-1'
            : ''
        "
      >
        {{ prompt }}
      </h3>
      <span
        v-if="finishedText"
        class="grid max-w-sm gap-12"
        :class="isMobile ? ' grid-cols-3' : 'grid-cols-4'"
      >
        <div
          id="shepherd-target-make-edit"
          v-if="!isMobile"
          class="w-20 grid grid-cols-1 p-3 -m-3 ml-auto cursor-pointer"
          :class="editMode ? 'text-accentPrimary' : 'text-secondary'"
          title="Edit your make"
          @click="toggleEditMode"
        >
          <font-awesome-icon class="mx-auto" :icon="['fas', 'pencil']" />
          <p class="text-xs text-center font-bold mt-0.5">Edit</p>
        </div>
        <div
          id="shepherd-target-make-reuse"
          class="w-20 grid grid-cols-1 text-secondary p-3 -m-3 ml-auto cursor-pointer"
          title="Start a new Make with this as the context"
          @click="openNewPrompt"
        >
          <font-awesome-icon
            class="mx-auto"
            :icon="['fas', 'arrows-split-up-and-left']"
          />
          <p class="text-xs text-center font-bold mt-0.5 whitespace-nowrap">
            Re-use
          </p>
        </div>
        <div
          v-if="isSupported"
          class="w-20 grid grid-cols-1 text-secondary p-3 -m-3 ml-auto cursor-pointer"
          title="Share your Make"
          @click="startShare"
        >
          <font-awesome-icon class="mx-auto" :icon="['fas', 'share-nodes']" />
          <p class="text-xs text-center font-bold mt-0.5">Share</p>
        </div>
        <div
          class="w-20 grid grid-cols-1 text-secondary p-3 -m-3 ml-auto cursor-pointer"
          title="Copy your Make to the clipboard"
          @click="copyToClipboard"
        >
          <font-awesome-icon class="mx-auto" :icon="['fas', 'copy']" />
          <p class="text-xs text-center font-bold mt-0.5">Copy</p>
        </div>
      </span>
    </span>
  </section>
</template>
