<script setup>
import { computed } from "vue";
import { useStore } from "vuex";
import MainButton from "../../inputs/MainButton.vue";
import Tabs from "../../tabs/Tabs.vue";

const store = useStore();

const emit = defineEmits(["newPrompt"]);

const openPurpose = computed(() => store.state.drawer.openPurpose);
const tabList = computed(() => store.state.tabs.tabList);
const currentTab = computed(() => store.state.tabs.currentTab);
const showResult = computed(() => store.state.result.showResult);
const editMode = computed(() => store.state.result.editMode);
const loadingResult = computed(() => store.state.result.loading);
const newInput = computed(() => store.state.newInput);
const finishedText = computed(() => store.state.result.finishedText);

const getResult = (regenerate) =>
  store.dispatch("result/getResult", regenerate);
const toggleEditMode = () => store.dispatch("result/toggleEditMode");
const setTab = (tab) => store.dispatch("tabs/setTab", tab);
</script>

<template>
  <section
    v-if="openPurpose === 'createMake'"
    class="fixed bottom-0 z-25 flex-col w-full bg-white lg:hidden flex"
  >
    <Tabs
      class="w-full sm:justify-center"
      v-if="!showResult"
      ref="tabs"
      :tab-list="tabList"
      :current-tab="currentTab"
      @change="setTab"
    />
    <MainButton
      class="w-full mt-4 lg:mt-0"
      :curve="false"
      @clicked="emit('newPrompt')"
      v-if="newInput"
    >
      <label class="uppercase cursor-pointer">re-do</label>
      <font-awesome-icon
        class="ml-2 cursor-pointer"
        :icon="['fas', 'rotate-right']"
      />
    </MainButton>
    <div v-else-if="!showResult || !loadingResult" class="w-full flex">
      <MainButton
        id="shepherd-target-make-edit-mobile"
        v-if="showResult && finishedText"
        title="Edit the prompt"
        class="w-1/6 mt-4 lg:mt-0"
        :setColours="editMode ? 'bg-accentSecondary' : 'bg-primary'"
        :curve="false"
        @clicked="toggleEditMode"
      >
        <div class="w-6 -ml-2 grid grid-cols-1 cursor-pointer">
          <font-awesome-icon class="mx-auto" :icon="['fas', 'pencil']" />
          <p class="text-xs text-center font-bold mt-0.5 whitespace-nowrap">
            Edit
          </p>
        </div>
      </MainButton>
      <MainButton
        id="shepherd-target-make-redo-mobile"
        class="mt-4 lg:mt-0"
        :class="showResult && finishedText ? 'w-5/6' : 'w-full'"
        :curve="false"
        @clicked="getResult(showResult)"
      >
        <label class="uppercase cursor-pointer">
          {{ showResult ? "re-generate" : "generate" }}
        </label>
        <font-awesome-icon
          class="ml-2 cursor-pointer"
          :icon="
            showResult ? ['fas', 'rotate-right'] : ['fas', 'chevron-right']
          "
        />
      </MainButton>
    </div>
  </section>
</template>
